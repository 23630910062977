import { AssignmentPhaseEnum } from 'src/app/core/enums/assignment-phase.enum';
import { AssignmentStatusEnum } from 'src/app/core/enums/assignment-status.enum';
import { SearchFilterI } from 'src/app/numbers/search/models/search-filter.interface';

export class AssignmentSearchRequest {
  query: string;
  externalProjectId: string;
  name: string;
  address: string;
  statuses: AssignmentStatusEnum[];
  phase: AssignmentPhaseEnum;
  mainContractor: string;
  dateGreaterThan: string;
  dateLesserThan: string;
  totalGreaterThan: number;
  totalLesserThan: number;
  radius: number;
  location: string;
  latitude: number;
  longitude: number;
  sortAsc: string;
  sortDesc: string;
  offset: number;
  limit: number;
  filterOnUser: boolean;

  constructor(assignmentSearchRequestBuilder: AssignmentSearchRequestBuilder) {
    this.query = assignmentSearchRequestBuilder.query;
    this.externalProjectId = assignmentSearchRequestBuilder.externalProjectId;
    this.name = assignmentSearchRequestBuilder.name;
    this.address = assignmentSearchRequestBuilder.address;
    this.statuses = assignmentSearchRequestBuilder.statuses;
    this.phase = assignmentSearchRequestBuilder.phase;
    this.mainContractor = assignmentSearchRequestBuilder.mainContractor;
    this.dateGreaterThan = assignmentSearchRequestBuilder.dateGreaterThan;
    this.dateLesserThan = assignmentSearchRequestBuilder.dateLesserThan;
    this.totalGreaterThan = assignmentSearchRequestBuilder.totalGreaterThan;
    this.totalLesserThan = assignmentSearchRequestBuilder.totalLesserThan;
    this.radius = assignmentSearchRequestBuilder.radius;
    this.location = assignmentSearchRequestBuilder.location;
    this.latitude = assignmentSearchRequestBuilder.latitude;
    this.longitude = assignmentSearchRequestBuilder.longitude;
    this.sortAsc = assignmentSearchRequestBuilder.sortAsc;
    this.sortDesc = assignmentSearchRequestBuilder.sortDesc;
    this.offset = assignmentSearchRequestBuilder.offset;
    this.limit = assignmentSearchRequestBuilder.limit;
    this.filterOnUser = assignmentSearchRequestBuilder.filterOnUser;
  }
}

export class AssignmentSearchRequestBuilder {
  private _query: string;
  private _externalProjectId: string;
  private _name: string;
  private _address: string;
  private _statuses: AssignmentStatusEnum[];
  private _phase: AssignmentPhaseEnum;
  private _mainContractor: string;
  private _dateGreaterThan: string;
  private _dateLesserThan: string;
  private _totalGreaterThan: number;
  private _totalLesserThan: number;
  private _radius: number;
  private _location: string;
  private _latitude: number;
  private _longitude: number;
  private _sortAsc: string;
  private _sortDesc: string;
  private _offset: number;
  private _limit: number;
  private _filterOnUser: boolean;

  get query(): string {
    return this._query;
  }

  setQuery(value: string) {
    this._query = value;
    return this;
  }

  get externalProjectId(): string {
    return this._externalProjectId;
  }

  setExternalProjectId(value: string) {
    this._externalProjectId = value;
    return this;
  }

  get name(): string {
    return this._name;
  }

  setName(value: string) {
    this._name = value;
    return this;
  }

  get address(): string {
    return this._address;
  }

  setAddress(value: string) {
    this._address = value;
    return this;
  }

  get statuses(): AssignmentStatusEnum[] {
    return this._statuses;
  }

  setStatuses(value: AssignmentStatusEnum[]) {
    this._statuses = value;
    return this;
  }

  get phase(): AssignmentPhaseEnum {
    return this._phase;
  }

  setPhase(value: AssignmentPhaseEnum) {
    this._phase = value;
    return this;
  }

  get mainContractor(): string {
    return this._mainContractor;
  }

  setMainContractor(value: string) {
    this._mainContractor = value;
    return this;
  }

  get dateGreaterThan(): string {
    return this._dateGreaterThan;
  }

  setDateGreaterThan(value: string) {
    this._dateGreaterThan = value;
    return this;
  }

  get dateLesserThan(): string {
    return this._dateLesserThan;
  }

  setDateLesserThan(value: string) {
    this._dateLesserThan = value;
    return this;
  }

  get totalGreaterThan(): number {
    return this._totalGreaterThan;
  }

  setTotalGreaterThan(value: number) {
    this._totalGreaterThan = value;
    return this;
  }

  get totalLesserThan(): number {
    return this._totalLesserThan;
  }

  setTotalLesserThan(value: number) {
    this._totalLesserThan = value;
    return this;
  }

  get radius(): number {
    return this._radius;
  }

  setRadius(value: number) {
    this._radius = value;
    return this;
  }

  get location(): string {
    return this._location;
  }

  setLocation(value: string) {
    this._location = value;
    return this;
  }

  get latitude(): number {
    return this._latitude;
  }

  setLatitude(value: number) {
    this._latitude = value;
    return this;
  }

  get longitude(): number {
    return this._longitude;
  }

  setLongitude(value: number) {
    this._longitude = value;
    return this;
  }

  get sortAsc(): string {
    return this._sortAsc;
  }

  setSortAsc(value: string) {
    this._sortAsc = value;
    return this;
  }

  get sortDesc(): string {
    return this._sortDesc;
  }

  setSortDesc(value: string) {
    this._sortDesc = value;
    return this;
  }

  get offset(): number {
    return this._offset;
  }

  setOffset(value: number) {
    this._offset = value;
    return this;
  }

  get limit(): number {
    return this._limit;
  }

  setLimit(value: number) {
    this._limit = value;
    return this;
  }

  get filterOnUser(): boolean {
    return this._filterOnUser;
  }

  setFilterOnUser(value: boolean) {
    this._filterOnUser = value;
    return this;
  }

  setPhaseType(tableTypeName: string) {
    const tableType: AssignmentPhaseEnum = tableTypeName as AssignmentPhaseEnum;

    return this.setPhase(tableType);
  }

  setOrdering(order: string, field: string) {
    return this.setSortAsc(order === 'asc' ? field : undefined).setSortDesc(
      order === 'desc' ? field : undefined
    );
  }

  setPagination(limit: number, page: number) {
    return this.setOffset(limit * (page - 1)).setLimit(limit);
  }

  setNumbersFilters(filters: SearchFilterI) {
    this.setFilterOnUser(filters.filterOnUser || false);

    if (filters.anyCalculation) {
      this.setTotalGreaterThan(0);
    }
    if (filters.noCalculation) {
      this.setTotalLesserThan(0.01);
    }

    return this.setPhase(filters.phase);
  }

  setAssignmentsFilters(
    assignmentName: string,
    address: string,
    contractor: string,
    createdAfter: string,
    createdBefore: string
  ) {
    return this.setName(assignmentName)
      .setAddress(address)
      .setMainContractor(contractor)
      .setDateGreaterThan(createdAfter)
      .setDateLesserThan(createdBefore);
  }

  build() {
    return new AssignmentSearchRequest(this);
  }
}
