import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseTemplateComponent } from 'src/app/core/modal-templates/base-template/base-template.component';
import { AssignmentLittleI } from 'src/app/core/models/assignment/assignment-little.interface';
import { GoToMeps } from 'src/app/core/store/actions/external-redirect.action';
import { getAssignmentResourceForGivenUrlIdSelector } from 'src/app/core/store/selectors/assignment.selectors';

@Component({
  selector: 'app-assignment-not-from-meps-deleted-template',
  templateUrl: './assignment-not-from-meps-deleted-template.component.html',
  styleUrls: ['./assignment-not-from-meps-deleted-template.component.scss'],
})
export class AssignmentNotFromMepsDeletedTemplateComponent
  extends BaseTemplateComponent
  implements OnInit {
  assignmentResource$: Observable<AssignmentLittleI>;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.checkIfShouldCloseModal(event);
  }

  constructor(store: Store<any>, eRef: ElementRef) {
    super(store, eRef);
  }

  ngOnInit() {
    this.assignmentResource$ = this.store.pipe(
      select(getAssignmentResourceForGivenUrlIdSelector)
    );
  }

  goToMeps() {
    this.assignmentResource$
      .pipe(take(1))
      .subscribe((assignmentResource: AssignmentLittleI) => {
        const action = new GoToMeps(assignmentResource.details.mepsId);
        this.dispatchActionAndCloseModal(action);
      });
  }
}
