import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessagesStatus } from 'src/app/core/enums/alert-messages-status.enum';
import { AssignmentStatusEnum } from 'src/app/core/enums/assignment-status.enum';
import { AnalysisUrlEnum } from 'src/app/core/enums/url-paths.enum';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';
import {
  AlertMessagesActions,
  HideMessage,
} from 'src/app/core/store/actions/alert-messages.action';
import { RouterGo } from 'src/app/core/store/actions/router.action';

@Component({
  selector: 'app-alert-messages',
  templateUrl: './alert-messages.component.html',
  styleUrls: ['./alert-messages.component.scss'],
})
export class AlertMessagesComponent implements OnInit {
  @Input()
  alerts: AlertMessage[];

  @Input()
  assignmentDeletedMessage: string;

  @Output()
  dismissed: EventEmitter<AlertMessagesActions> = new EventEmitter<
    AlertMessagesActions
  >();

  @Output()
  assignmentDetails: EventEmitter<RouterGo> = new EventEmitter<RouterGo>();

  alertMessageStatus = AlertMessagesStatus;

  constructor() {}

  ngOnInit() {}

  unreadMessagesExists(): boolean {
    return this.alerts.some(
      (alert) => alert.status === AlertMessagesStatus.UNREADED
    );
  }

  onDismissed(alertMessage: AlertMessage) {
    this.dismissed.emit(new HideMessage(alertMessage));
  }

  goToDetails(id: number, status: string) {
    status === AssignmentStatusEnum.REMOVED
      ? alert(this.assignmentDeletedMessage)
      : this.assignmentDetails.emit(
          new RouterGo({
            path: [AnalysisUrlEnum.getAnalysisDetailsUrl(id.toString())],
          })
        );
  }
}
