import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { ChartResultI } from 'src/app/core/models/log/chart-result.interface';
import { AssignmentAppApiService } from 'src/app/core/services/api/assignment-app-api/assignment-app-api.service';
import {
  AssignmentVolumeChartActionTypes,
  CleanAssignmentVolumeChartState,
  LoadedAssignmentVolumeChartsFail,
  LoadedAssignmentVolumeChartsSuccess,
} from 'src/app/core/store/actions/assignment-volume-chart.action';

@Injectable()
export class AssignmentVolumeChartEffects {
  constructor(
    private actions$: Actions,
    private assignmentAppService: AssignmentAppApiService
  ) {}

  
  chart$ = createEffect(() => this.actions$.pipe(
    ofType(AssignmentVolumeChartActionTypes.LoadAssignmentVolumeChart),
    switchMap((any) =>
      this.assignmentAppService.getVolumeChart().pipe(
        map(
          (count: ChartResultI[]) =>
            new LoadedAssignmentVolumeChartsSuccess(count)
        ),
        catchError((error: ErrorResponseWithIdI) =>
          of(new LoadedAssignmentVolumeChartsFail(error))
        )
      )
    )
  ));

  
  handleLoadedVolumeChartFail$ = createEffect(() => this.actions$.pipe(
    ofType(AssignmentVolumeChartActionTypes.LoadedAssignmentVolumeChartFail),
    map(() => new CleanAssignmentVolumeChartState())
  ));
}
