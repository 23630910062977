<div class="container">
  <div class="row pt-4 pb-3 align-items-center">
    <div class="col-3"></div>
    <div class="col-6 col-sm-6 text-center">
      <span class="h2" [translate]="'go.messagesPanel.title'"></span>
    </div>
    <div class="col-3">
      <div class="row justify-content-end">
        <button
          type="button"
          class="btn btn-outline-secondary"
          (click)="onClearAll()"
          [translate]="'go.messagesPanel.buttons.clearAll'"
        ></button>
      </div>
    </div>
  </div>
</div>
