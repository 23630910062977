import { Action } from '@ngrx/store';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum AuthActionTypes {
  LoggedIn = '[Auth] LoggedIn',

  Logout = '[Auth] Logout',
  LoggedOut = '[Auth] LoggedOut',

  InvalidToken = '[Auth] Token Invalid',
  Unauthorized = '[Auth] Unauthorized',
  Blocked = '[Auth] Blocked',

  RefreshToken = '[Auth] Refresh Token',

  ShowLoginPanel = '[Auth] Show Login Panel',
  HideLoginPanel = '[Auth] Hide Login Panel',

  SetOriginUrl = '[AuthInfo] Set Origin Url',
}

export class ShowLoginPanel implements Action {
  readonly type = AuthActionTypes.ShowLoginPanel;

  constructor(public payload?: any) {}
}

export class HideLoginPanel implements Action {
  readonly type = AuthActionTypes.HideLoginPanel;

  constructor(public payload?: any) {}
}

export class LoggedIn implements Action {
  readonly type = AuthActionTypes.LoggedIn;

  constructor(public payload?: any) {}
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;

  constructor(public payload?: any) {}
}

export class LoggedOut implements Action {
  readonly type = AuthActionTypes.LoggedOut;

  constructor(public payload?: any) {}
}

export class InvalidToken implements Action {
  readonly type = AuthActionTypes.InvalidToken;

  constructor(public payload?: any) {}
}

export class RefreshToken implements Action {
  readonly type = AuthActionTypes.RefreshToken;

  constructor(public payload?: any) {}
}

export class Unauthorized implements Action {
  readonly type = AuthActionTypes.Unauthorized;

  constructor(public payload?: ErrorResponseWithIdI) {}
}

export class Blocked implements Action {
  readonly type = AuthActionTypes.Blocked;

  constructor(public payload?) {}
}

export class SetOriginUrl implements Action {
  readonly type = AuthActionTypes.SetOriginUrl;

  constructor(public payload: string) {}
}

export type AuthActions =
  | LoggedIn
  | Logout
  | LoggedOut
  | InvalidToken
  | RefreshToken
  | Unauthorized
  | Blocked
  | SetOriginUrl;
