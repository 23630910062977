import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

export enum SpaceTypesActionTypes {
  LoadSpaceTypes = '[SpaceTypes] Load Space Types',
  LoadedSpaceTypesSuccess = '[SpaceTypes] Loaded Space Types',
  LoadedSpaceTypesFail = '[SpaceTypes] Fail To Load Space Types',

  CleanSpaceTypesState = '[SpaceTypes] Clean',
}

@ShowLoader()
export class LoadSpaceTypes implements Action {
  readonly type = SpaceTypesActionTypes.LoadSpaceTypes;

  constructor(public payload?: any) {}
}

@HideLoader(SpaceTypesActionTypes.LoadSpaceTypes)
export class LoadedSpaceTypesSuccess implements Action {
  readonly type = SpaceTypesActionTypes.LoadedSpaceTypesSuccess;

  constructor(public payload: DictionaryI[]) {}
}

@HideLoader(SpaceTypesActionTypes.LoadSpaceTypes)
export class LoadedSpaceTypesFail implements Action {
  readonly type = SpaceTypesActionTypes.LoadedSpaceTypesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanSpaceTypesState implements Action {
  readonly type = SpaceTypesActionTypes.CleanSpaceTypesState;

  constructor(public payload?: string) {}
}

export type SpaceTypesAction =
  | LoadSpaceTypes
  | LoadedSpaceTypesSuccess
  | LoadedSpaceTypesFail
  | CleanSpaceTypesState;
