import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { AlertMessagesTypes } from 'src/app/core/enums/alert-messages-types.enum';
import { AssignmentStatusEnum } from 'src/app/core/enums/assignment-status.enum';
import { AnalysisUrlEnum } from 'src/app/core/enums/url-paths.enum';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';
import {
  AlertMessagesActions,
  ClearMessage,
} from 'src/app/core/store/actions/alert-messages.action';
import { RouterGo } from 'src/app/core/store/actions/router.action';

@Component({
  selector: 'app-message-panel-list',
  templateUrl: './message-panel-list.component.html',
  styleUrls: ['./message-panel-list.component.scss'],
})
export class MessagePanelListComponent implements OnInit {
  assignmentDetailsUrl = AnalysisUrlEnum.getAnalysisUrl(AnalysisUrlEnum.START);

  @Input('alertMessages')
  set alertMessagesSetter(alertMessages: AlertMessage[]) {
    this.alertMessages = alertMessages || [];
    this.paginatedAlertMessages = [];
    this.initialPagination(alertMessages);
  }

  @Input()
  assignmentDeletedMessage: string;

  @Output()
  clearMessage: EventEmitter<AlertMessagesActions> = new EventEmitter<
    AlertMessagesActions
  >();

  @Output()
  assignmentDetails: EventEmitter<RouterGo> = new EventEmitter<RouterGo>();

  currentPage = 1;
  maxElementOnListPage = 5;

  alertMessages: AlertMessage[];
  paginatedAlertMessages: AlertMessage[];

  alertMessagesTypes = AlertMessagesTypes;

  constructor() {}

  ngOnInit() {}

  initialPagination(alertMessages: AlertMessage[]) {
    this.alertMessages.length <= this.maxElementOnListPage
      ? (this.paginatedAlertMessages = alertMessages)
      : this.setInitialPagination();
  }

  private setInitialPagination() {
    this.currentPage === 1 ? this.showFirstPage() : this.showCurrentPage();
  }

  private showFirstPage() {
    this.setPaginatedAlertMessagesList(0, this.maxElementOnListPage);
  }

  private showCurrentPage() {
    const startItem = (this.currentPage - 1) * this.maxElementOnListPage;
    const endItem = this.currentPage * this.maxElementOnListPage;

    this.setPaginatedAlertMessagesList(startItem, endItem);
  }

  onPaginationClick(pageChangedEvent: PageChangedEvent) {
    const startItem =
      (pageChangedEvent.page - 1) * pageChangedEvent.itemsPerPage;
    const endItem = pageChangedEvent.page * pageChangedEvent.itemsPerPage;

    this.setPaginatedAlertMessagesList(startItem, endItem);
  }

  private setPaginatedAlertMessagesList(
    startItem: number,
    endItem: number
  ): void {
    this.paginatedAlertMessages = this.alertMessages.slice(startItem, endItem);
  }

  goToDetails(id: number, status: string) {
    status === AssignmentStatusEnum.REMOVED
      ? alert(this.assignmentDeletedMessage)
      : this.assignmentDetails.emit(
          new RouterGo({ path: [this.assignmentDetailsUrl + id] })
        );
  }

  onClearOne(message: AlertMessage) {
    this.clearMessage.emit(new ClearMessage(message));
  }
}
