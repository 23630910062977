import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import {
  PullingQueueActions,
  PullQueueActionTypes,
} from 'src/app/core/store/actions/pulling-queue.actions';

const selectId = (assignmentId: number) => assignmentId;
export const adapter: EntityAdapter<number> = createEntityAdapter<number>({
  selectId,
});

export interface PullingQueueReducerState extends EntityState<number> {
  pullingStarted: boolean;
  error: ErrorResponseWithIdI;
}

export const initialPullingQueueReducerState: PullingQueueReducerState = adapter.getInitialState(
  {
    pullingStarted: false,
    error: null,
  }
);

export function pullingQueueReducer(
  state = initialPullingQueueReducerState,
  action: PullingQueueActions
): PullingQueueReducerState {
  switch (action.type) {
    case PullQueueActionTypes.AddAssignmentForPulling: {
      return adapter.addOne(action.payload, {
        ...state,
        pullingStarted: true,
      });
    }

    case PullQueueActionTypes.LoadPendingAssignmentIdsListSuccess: {
      const pullingQueueNewReducerState = adapter.addMany(
        action.payload,
        state
      );
      const shouldStartPulling = state.pullingStarted
        ? state.pullingStarted
        : pullingQueueNewReducerState.ids.length > 0;

      return {
        ...pullingQueueNewReducerState,
        pullingStarted: shouldStartPulling,
        error: !state.pullingStarted && shouldStartPulling ? null : state.error,
      };
    }

    case PullQueueActionTypes.SynchronizePullingQueueSuccess: {
      const pulledIds = action.payload.map(response => response.assignmentId);
      const pullingQueueNewReducerState = adapter.removeMany(pulledIds, state);

      const shouldStopPulling = !state.pullingStarted
        ? !state.pullingStarted
        : pullingQueueNewReducerState.ids.length === 0;

      return {
        ...pullingQueueNewReducerState,
        pullingStarted: !shouldStopPulling,
      };
    }

    case PullQueueActionTypes.LoadPendingAssignmentIdsListFail:
    case PullQueueActionTypes.SynchronizePullingQueueFail: {
      return {
        ...initialPullingQueueReducerState,
        error: action.payload,
      };
    }

    default:
      return state;
  }
}

export const getAssignmentIdsFromPullingQueue = adapter.getSelectors()
  .selectIds;
export const getAssignmentIdsFromPullingQueueEntities = adapter.getSelectors()
  .selectEntities;
export const getAllAssignmentIdsFromPullingQueue = adapter.getSelectors()
  .selectAll;
export const getAssignmentIdsFromPullingQueueTotal = adapter.getSelectors()
  .selectTotal;

export const getPullingStarted = (state: PullingQueueReducerState) =>
  state.pullingStarted;
export const getPullingError = (state: PullingQueueReducerState) => state.error;
