import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { AssignmentLittleI } from 'src/app/core/models/assignment/assignment-little.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AssignmentSearchRequest } from 'src/app/core/models/search/assignment-search-request.interface';
import { AssignmentSearchResponse } from 'src/app/core/models/search/assignment-search-response.interface';
import { QueryUrlParamsI } from 'src/app/numbers/models/query-url-params.interface';
import { SearchSortEnum } from 'src/app/numbers/search/enums/search-sort.enum';
import { View } from 'src/app/numbers/search/enums/view.enum';
import { SearchFilterI } from 'src/app/numbers/search/models/search-filter.interface';

export enum AssignmentSearchActionTypes {
  SearchAssignment = '[Assignment Search] Search Assignment',
  SearchAssignmentSuccess = '[Assignment Search] Search Assignment Success',
  SearchAssignmentFail = '[Assignment Search] Search Assignment Fail',

  SetQueryParametersFromUrl = '[Assignment Search] Set Query Parameters From Url',
  UpdateSelectedSearchAssignments = '[Assignment Search] Update Selected Search Assignments',

  ChangeAssignmentsSearchFilters = '[Assignment Search] Change Assignments Search Filters',
  ChangeAssignmentsSearchSorting = '[Assignment Search] Change Assignments Search Sorting',
  ChangeAssignmentsSearchPagination = '[Assignment Search] Change Assignments Search Pagination',
  ChangeAssignmentsSearchQuery = '[Assignment Search] Change Assignments Search Query',
  ChangeAssignmentsSearchExternalProjectId = '[Assignment Search] Change Assignments Search External Project Id',
  ChangeAssignmentsSearchQueryAndFiltersFromUrl = '[Assignment Search] Change Assignments Search Query And Filters From Url',

  ChangeAssignmentsSearchView = '[Assignment Search] Change Assignments Search View',

  CleanAssignmentsSearchState = '[Assignment Search] Clean Assignments Search State',
  CleanAssignmentsSearchFilters = '[Assignment Search] Clean Assignments Search Filters',
  CleanAssignmentsSearchSorting = '[Assignment Search] Clean Assignments Search Sorting',
}

// ------ Load Assignment ------

@ShowLoader()
export class SearchAssignment implements Action {
  readonly type = AssignmentSearchActionTypes.SearchAssignment;

  constructor(public payload: AssignmentSearchRequest) {}
}

@HideLoader(AssignmentSearchActionTypes.SearchAssignment)
export class SearchAssignmentSuccess implements Action {
  readonly type = AssignmentSearchActionTypes.SearchAssignmentSuccess;

  constructor(public payload: AssignmentSearchResponse) {}
}

@HideLoader(AssignmentSearchActionTypes.SearchAssignment)
export class SearchAssignmentFail implements Action {
  readonly type = AssignmentSearchActionTypes.SearchAssignmentFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class ChangeAssignmentsSearchFilters implements Action {
  readonly type = AssignmentSearchActionTypes.ChangeAssignmentsSearchFilters;

  constructor(public payload: SearchFilterI) {}
}

export class ChangeAssignmentsSearchPagination implements Action {
  readonly type = AssignmentSearchActionTypes.ChangeAssignmentsSearchPagination;

  constructor(public payload: number) {}
}

export class ChangeAssignmentsSearchSorting implements Action {
  readonly type = AssignmentSearchActionTypes.ChangeAssignmentsSearchSorting;

  constructor(public payload: SearchSortEnum) {}
}

export class ChangeAssignmentsSearchQuery implements Action {
  readonly type = AssignmentSearchActionTypes.ChangeAssignmentsSearchQuery;

  constructor(public payload: string) {}
}

export class ChangeAssignmentsSearchExternalProjectId implements Action {
  readonly type = AssignmentSearchActionTypes.ChangeAssignmentsSearchExternalProjectId;

  constructor(public payload: string) {}
}

export class ChangeAssignmentsSearchView implements Action {
  readonly type = AssignmentSearchActionTypes.ChangeAssignmentsSearchView;

  constructor(public payload: View) {}
}

export class ChangeAssignmentsSearchQueryAndFiltersFromUrl implements Action {
  readonly type =
    AssignmentSearchActionTypes.ChangeAssignmentsSearchQueryAndFiltersFromUrl;

  constructor(public payload: QueryUrlParamsI) {}
}

export class UpdateSelectedSearchAssignments implements Action {
  readonly type = AssignmentSearchActionTypes.UpdateSelectedSearchAssignments;

  constructor(public payload: AssignmentLittleI[]) {}
}

export class SetQueryParametersFromUrl implements Action {
  readonly type = AssignmentSearchActionTypes.SetQueryParametersFromUrl;

  constructor(public payload: QueryUrlParamsI) {}
}

// Clean

export class CleanAssignmentsSearchState implements Action {
  readonly type = AssignmentSearchActionTypes.CleanAssignmentsSearchState;
}

export class CleanAssignmentsSearchFilters implements Action {
  readonly type = AssignmentSearchActionTypes.CleanAssignmentsSearchFilters;

  constructor(public payload: string) {}
}

export class CleanAssignmentsSearchSorting implements Action {
  readonly type = AssignmentSearchActionTypes.CleanAssignmentsSearchSorting;
}

export type AssignmentSearchActions =
  | SearchAssignment
  | SearchAssignmentSuccess
  | SearchAssignmentFail
  | ChangeAssignmentsSearchPagination
  | ChangeAssignmentsSearchQuery
  | ChangeAssignmentsSearchExternalProjectId
  | ChangeAssignmentsSearchView
  | ChangeAssignmentsSearchSorting
  | ChangeAssignmentsSearchFilters
  | ChangeAssignmentsSearchQueryAndFiltersFromUrl
  | SetQueryParametersFromUrl
  | UpdateSelectedSearchAssignments
  | CleanAssignmentsSearchState
  | CleanAssignmentsSearchFilters
  | CleanAssignmentsSearchSorting;
