<div class="modal-header">
  <h4
    class="modal-title pull-left"
    [translate]="'removeAssignmentTemplate.title'"
  ></h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="mt-4 mb-4">
    <p [translate]="">
      {{ 'removeAssignmentTemplate.info' | translate }}
      <b>{{ 'removeAssignmentTemplate.message' | translate }}</b>
    </p>
  </div>

  <button
    type="button"
    class="btn btn-primary pull-right mt-3"
    (click)="goToMeps()"
  >
    {{ 'removeAssignmentTemplate.button' | translate }}
  </button>
</div>
