import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AssignmentsService } from 'src/app/core/services/api/assignments/assignments.service';
import { Base64Service } from 'src/app/core/services/base64/base64.service';
import {
  DownloadCostPDF,
  DownloadCostPDFError,
  DownloadCostPDFSuccess,
  PdfActionsTypes,
} from 'src/app/core/store/actions/pdf.action';
import { getErrorMessage } from 'src/app/core/utils/core-utils';

@Injectable()
export class PDFEffects {
  constructor(
    private actions$: Actions,
    private assignmentService: AssignmentsService,
    private base64Service: Base64Service
  ) {}

  public downloadCostPDF$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PdfActionsTypes.DownloadCostPDF),
      switchMap((action: DownloadCostPDF) =>
        this.assignmentService.printPdf(action.payload).pipe(
          tap((pdf) => this.base64Service.openInNewWindow(pdf)),
          map((_) => new DownloadCostPDFSuccess()),
          catchError((e) => of(new DownloadCostPDFError(getErrorMessage(e))))
        )
      )
    )
  );
}
