import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { SelectI } from 'src/app/core/models/select.interface';
import { getSelectValueFromSelectArrayById } from 'src/app/core/utils/dictionary-utils';
import { DictionariesReducerState } from 'src/app/dictionaries/store';
import { getErrorExtraTypesEntitiesForSelectedLanguageSelector } from 'src/app/dictionaries/store/selectors/error-extra-types.selectors';

@Pipe({
  name: 'extraTranslate',
})
export class ExtraTranslatePipe implements PipeTransform {
  // TODO: Use new State Selectors in NgRX 7 during update
  constructor(private store: Store<DictionariesReducerState>) {}

  transform(extra: any) {
    return this.store.pipe(
      select(getErrorExtraTypesEntitiesForSelectedLanguageSelector),
      map(
        (extraDictionary: SelectI[]) =>
          getSelectValueFromSelectArrayById(extra, extraDictionary) || extra
      )
    );
  }
}
