import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AssignmentTypesGuard } from 'src/app/dictionaries/guards/assignment-types.guard';
import { ClientAssignmentTypesGuard } from 'src/app/dictionaries/guards/client-assignment-types.guard';
import { DecisionTypesGuard } from 'src/app/dictionaries/guards/decision-types.guard';
import { EstablishmentTypesGuard } from 'src/app/dictionaries/guards/establishment-types.guard';
import { EventTypesGuard } from 'src/app/dictionaries/guards/event-types.guard';
import { OperationWorkTypesGuard } from 'src/app/dictionaries/guards/operation-work-types.guard';
import { RealEstateTypesGuard } from 'src/app/dictionaries/guards/real-estate-types.guard';
import { SpaceTypesGuard } from 'src/app/dictionaries/guards/space-types.guard';
import { TradeTypesGuard } from 'src/app/dictionaries/guards/trade-types.guard';
import { ErrorTranslationService } from 'src/app/dictionaries/services/error-translations.service';
import { dictionariesReducer } from 'src/app/dictionaries/store';
import { AssignmentTypesEffects } from 'src/app/dictionaries/store/effects/assignment-types.effects';
import { ClientAssignmentTypesEffects } from 'src/app/dictionaries/store/effects/client-assignment-types.effects';
import { DecisionTypesEffects } from 'src/app/dictionaries/store/effects/decision-types.effects';
import { ErrorExtraTypesEffects } from 'src/app/dictionaries/store/effects/error-extra-types.effects';
import { ErrorReasonTypesEffects } from 'src/app/dictionaries/store/effects/error-reason-types.effects';
import { EstablishmentTypesEffects } from 'src/app/dictionaries/store/effects/establishment-types.effects';
import { EventTypesEffects } from 'src/app/dictionaries/store/effects/event-types.effects';
import { OperationWorkTypesEffects } from 'src/app/dictionaries/store/effects/operation-work-types.effects';
import { RealEstateTypesEffects } from 'src/app/dictionaries/store/effects/real-estate-types.effects';
import { SpaceTypesEffects } from 'src/app/dictionaries/store/effects/space-types.effects';
import { TradeTypesEffects } from 'src/app/dictionaries/store/effects/trade-types.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('dict', dictionariesReducer),
    EffectsModule.forFeature([
      SpaceTypesEffects,
      RealEstateTypesEffects,
      AssignmentTypesEffects,
      EventTypesEffects,
      DecisionTypesEffects,
      ErrorReasonTypesEffects,
      ErrorExtraTypesEffects,
      OperationWorkTypesEffects,
      ClientAssignmentTypesEffects,
      EstablishmentTypesEffects,
      TradeTypesEffects,
    ]),
  ],
})
export class DictionariesModule {
  static forRoot(): ModuleWithProviders<DictionariesModule> {
    return {
      ngModule: DictionariesModule,
      providers: [
        AssignmentTypesGuard,
        RealEstateTypesGuard,
        SpaceTypesGuard,
        EventTypesGuard,
        DecisionTypesGuard,
        OperationWorkTypesGuard,
        ErrorTranslationService,
        ClientAssignmentTypesGuard,
        EstablishmentTypesGuard,
        TradeTypesGuard,
      ],
    };
  }
}
