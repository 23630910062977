import { LanguageEnum } from 'src/app/core/enums/language.enum';
import { SelectI } from 'src/app/core/models/select.interface';
import { createSelectObject } from 'src/app/core/utils/select-utils';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

export const mapDictionaryToSelectForGivenLanguage = (
  dictionaryElements: DictionaryI[],
  language: String
) => {
  switch (language) {
    case LanguageEnum.EN: {
      return dictionaryElements.map(element => {
        return createSelectObject(element.name, element.enGB);
      });
    }
    case LanguageEnum.SE: {
      return dictionaryElements.map(element => {
        return createSelectObject(element.name, element.svSE);
      });
    }
  }
};

export const getSelectValueFromSelectArrayById = (
  id: string,
  selectArray: SelectI[]
): string | number => {
  selectArray = selectArray || [];

  const [value] = selectArray
    .filter(rd => rd.id === id)
    .map(rd => (!!rd ? rd.value : ''));

  return !!value ? value : '';
};

export const mapSelectArrayToSelectMap = (
  selectArray: SelectI[]
): Map<string | number, string | number> => {
  const x = new Map<string | number, string | number>();

  selectArray.forEach(y => x.set(y.id, y.value));
  return x;
};
