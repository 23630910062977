import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';

import { AgreementI } from 'src/app/core/models/agreements.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum AgreementsActionTypes {
  CreateAgreement = '[Agreements] Create Agreement',
  CreateAgreementSuccess = '[Agreements] Created Agreement',
  CreateAgreementFail = '[Agreements] Fail To Create Agreement',

  EditAgreement = '[Agreements] Edit Agreement',
  EditAgreementSuccess = '[Agreements] Edited Agreement',
  EditAgreementFail = '[Agreements] Fail To Edit Agreement',

  LoadAgreements = '[Agreements] Load Agreements',
  LoadedAgreementsSuccess = '[Agreements] Loaded Agreements',
  LoadedAgreementsFail = '[Agreements] Fail To Load Agreements',

  CleanAgreementsState = '[Agreements] Clean',
}

@ShowLoader()
export class CreateAgreement implements Action {
  readonly type = AgreementsActionTypes.CreateAgreement;

  constructor(public payload: AgreementI) {}
}

@HideLoader(AgreementsActionTypes.CreateAgreement)
export class CreateAgreementSuccess implements Action {
  readonly type = AgreementsActionTypes.CreateAgreementSuccess;

  constructor(public payload: number) {}
}

@HideLoader(AgreementsActionTypes.CreateAgreement)
export class CreateAgreementFail implements Action {
  readonly type = AgreementsActionTypes.CreateAgreementFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

@ShowLoader()
export class EditAgreement implements Action {
  readonly type = AgreementsActionTypes.EditAgreement;

  constructor(public payload: AgreementI) {}
}

@HideLoader(AgreementsActionTypes.EditAgreement)
export class EditAgreementSuccess implements Action {
  readonly type = AgreementsActionTypes.EditAgreementSuccess;

  constructor(public payload: number) {}
}

@HideLoader(AgreementsActionTypes.EditAgreement)
export class EditAgreementFail implements Action {
  readonly type = AgreementsActionTypes.EditAgreementFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

@ShowLoader()
export class LoadAgreements implements Action {
  readonly type = AgreementsActionTypes.LoadAgreements;

  constructor(public payload?: any) {}
}

@HideLoader(AgreementsActionTypes.LoadAgreements)
export class LoadedAgreementsSuccess implements Action {
  readonly type = AgreementsActionTypes.LoadedAgreementsSuccess;

  constructor(public payload: AgreementI[]) {}
}

@HideLoader(AgreementsActionTypes.LoadAgreements)
export class LoadedAgreementsFail implements Action {
  readonly type = AgreementsActionTypes.LoadedAgreementsFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanAgreementsState implements Action {
  readonly type = AgreementsActionTypes.CleanAgreementsState;

  constructor(public payload?: string) {}
}

export type AgreementsActions =
  | LoadAgreements
  | LoadedAgreementsSuccess
  | LoadedAgreementsFail
  | CleanAgreementsState;
