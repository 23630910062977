<app-message-panel-header
  [backUrl]="backUrl$ | async"
  [currentFilter]="alertMessagesFilter$ | async"
  (clearAll)="onClearAll()"
>
</app-message-panel-header>

<app-message-panel-list
  [alertMessages]="alertMessages$ | async"
  [assignmentDeletedMessage]="assignmentDeletedMessage$ | async"
  (assignmentDetails)="dispatchAction($event)"
  (clearMessage)="dispatchAction($event)"
>
</app-message-panel-list>
