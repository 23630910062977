import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { getOperationWorkTypesStateSelector } from 'src/app/dictionaries/store';
import { LoadOperationWorkTypes } from 'src/app/dictionaries/store/actions/operation-work-types.action';
import { OperationWorkTypesReducerState } from 'src/app/dictionaries/store/reducers/operation-work-types.reducer';

@Injectable()
export class OperationWorkTypesGuard implements CanActivate {
  constructor(private store: Store<OperationWorkTypesReducerState>) {}

  canActivate() {
    return this.waitForOperationWorkTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForOperationWorkTypesToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getOperationWorkTypesStateSelector),
      tap((operationWorkTypesState: OperationWorkTypesReducerState) => {
        if (
          !operationWorkTypesState.loaded &&
          !operationWorkTypesState.loading &&
          !operationWorkTypesState.error
        ) {
          this.store.dispatch(new LoadOperationWorkTypes());
        }
      }),
      filter(
        (operationWorkTypesState: OperationWorkTypesReducerState) =>
          operationWorkTypesState.loaded || !!operationWorkTypesState.error
      ),
      map((operationWorkTypesState: OperationWorkTypesReducerState) => {
        if (!!operationWorkTypesState.error) {
          throw new Error(operationWorkTypesState.error.error.message);
        } else {
          return operationWorkTypesState.loaded;
        }
      }),
      take(1)
    );
  }
}
