import {
  SupportActions,
  SupportActionTypes,
} from 'src/app/core/store/actions/support.action';

export interface SupportReducerState {
  isLoaded: boolean;
  isLauncherVisible: boolean;
}

export const initialSupportReducerState: SupportReducerState = {
  isLauncherVisible: false,
  isLoaded: false,
};

export function supportReducer(
  state: SupportReducerState = initialSupportReducerState,
  action: SupportActions
): SupportReducerState {
  switch (action.type) {
    case SupportActionTypes.LoadZenDeskSuccess: {
      return {
        ...state,
        isLoaded: true,
        isLauncherVisible: true,
      };
    }

    case SupportActionTypes.ShowLauncher: {
      return {
        ...state,
        isLauncherVisible: true,
      };
    }

    case SupportActionTypes.HideLauncher: {
      return {
        ...state,
        isLauncherVisible: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const isZenDeskLoaded = (state: SupportReducerState) => state.isLoaded;
export const isLauncherVisible = (state: SupportReducerState) =>
  state.isLauncherVisible;
