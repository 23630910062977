import { registerLocaleData } from '@angular/common';

import localeSv from '@angular/common/locales/sv';
import { NgModule } from '@angular/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { enGbLocale, svLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

// This is required to set local for Angular app itself
registerLocaleData(localeSv);
// This is required to set languages for ngx Bootstrap
defineLocale(enGbLocale.abbr, enGbLocale);
defineLocale(svLocale.abbr, svLocale);

@NgModule({
  imports: [
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    PaginationModule.forRoot(),
    TimepickerModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    PopoverModule.forRoot(),
  ],
  exports: [
    BsDropdownModule,
    TooltipModule,
    ModalModule,
    AlertModule,
    BsDatepickerModule,
    CollapseModule,
    PaginationModule,
    TimepickerModule,
    TabsModule,
    AccordionModule,
  ],
})
export class BootstrapModule {}
