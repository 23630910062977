import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { UnregisteredUser } from 'src/app/auth/store/actions/user.action';
import { UserState } from 'src/app/auth/store/reducers/user.reducer';
import { getUserPublicSelector } from 'src/app/auth/store/selectors/user.selectors';

import { UserPublicI } from 'src/app/core/models/user/user-resource.interface';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';

@Injectable()
export class ConfirmGuard extends StoreDispatcher<any> implements CanActivate {
  constructor(store: Store<UserState>) {
    super(store);
  }

  canActivate() {
    return this.store.pipe(
      select(getUserPublicSelector),
      filter((user: UserPublicI) => !!user),
      map((user: UserPublicI) => {
        if (user.confirmedProfile) {
          return true;
        } else {
          this.dispatchAction(new UnregisteredUser());
          return false;
        }
      })
    );
  }
}
