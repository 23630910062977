import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserResourceI } from 'src/app/core/models/user/user-resource.interface';

import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import {
  CompanyUsersActions,
  CompanyUsersActionTypes,
} from 'src/app/core/store/actions/company-users.action';

function sortByName(a: UserResourceI, b: UserResourceI): number {
  return a.name.localeCompare(b.name);
}
const selectId = (userPublic: UserResourceI) => userPublic.userId;

const adapter: EntityAdapter<UserResourceI> = createEntityAdapter<
  UserResourceI
>({
  selectId,
  sortComparer: sortByName,
});

export interface CompanyUsersReducerState extends EntityState<UserResourceI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialCompanyUsersReducerState: CompanyUsersReducerState = adapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function companyUsersReducer(
  state: CompanyUsersReducerState = initialCompanyUsersReducerState,
  action: CompanyUsersActions
): CompanyUsersReducerState {
  switch (action.type) {
    case CompanyUsersActionTypes.LoadCompanyUsers: {
      return {
        ...state,
        loading: true,
      };
    }

    case CompanyUsersActionTypes.LoadedCompanyUsersSuccess: {
      const usersWithName = action.payload.filter(user => !!user.name);

      return adapter.addMany(usersWithName, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case CompanyUsersActionTypes.LoadedCompanyUsersFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    }

    case CompanyUsersActionTypes.CleanCompanyUsersState: {
      return {
        ...initialCompanyUsersReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getCompanyUsersIds = adapter.getSelectors().selectIds;
export const getCompanyUsersEntities = adapter.getSelectors().selectEntities;
export const getAllCompanyUsers = adapter.getSelectors().selectAll;
export const getCompanyUsersTotal = adapter.getSelectors().selectTotal;

export const getCompanyUsersLoading = (state: CompanyUsersReducerState) =>
  state.loading;
export const getCompanyUsersLoaded = (state: CompanyUsersReducerState) =>
  state.loaded;
export const getCompanyUsersError = (state: CompanyUsersReducerState) =>
  state.error;
