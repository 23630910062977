import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

export enum EventTypesActionTypes {
  LoadEventTypes = '[EventTypes] Load Event Types',
  LoadedEventTypesSuccess = '[EventTypes] Loaded Event Types',
  LoadedEventTypesFail = '[EventTypes] Fail To Load Event Types',

  CleanEventTypesState = '[EventTypes] Clean',
}

@ShowLoader()
export class LoadEventTypes implements Action {
  readonly type = EventTypesActionTypes.LoadEventTypes;

  constructor(public payload?: any) {}
}

@HideLoader(EventTypesActionTypes.LoadEventTypes)
export class LoadedEventTypesSuccess implements Action {
  readonly type = EventTypesActionTypes.LoadedEventTypesSuccess;

  constructor(public payload: DictionaryI[]) {}
}

@HideLoader(EventTypesActionTypes.LoadEventTypes)
export class LoadedEventTypesFail implements Action {
  readonly type = EventTypesActionTypes.LoadedEventTypesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanEventTypesState implements Action {
  readonly type = EventTypesActionTypes.CleanEventTypesState;

  constructor(public payload?: string) {}
}

export type EventTypesAction =
  | LoadEventTypes
  | LoadedEventTypesSuccess
  | LoadedEventTypesFail
  | CleanEventTypesState;
