import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OutputElementEnum } from 'src/app/core/enums/output-element.enum';
import {
  ExternalRedirectActions,
  GoToEmail,
  GoToPhoneNumber,
} from 'src/app/core/store/actions/external-redirect.action';

@Component({
  selector: 'app-info-item',
  templateUrl: './info-item.component.html',
  styleUrls: ['./info-item.component.scss'],
})
export class InfoItemComponent implements OnInit {
  @Input()
  value: any;

  @Input()
  label: string;

  @Input()
  additionalInfo: any[];

  @Input()
  outputType: OutputElementEnum;

  @Output()
  clicked: EventEmitter<ExternalRedirectActions> = new EventEmitter<
    ExternalRedirectActions
  >();

  constructor() {}

  ngOnInit(): void {}

  onClick(output: OutputElementEnum, value: any) {
    switch (output) {
      case OutputElementEnum.EMAIL: {
        this.clicked.emit(new GoToEmail(value));
        break;
      }
      case OutputElementEnum.PHONE_NUMBER: {
        this.clicked.emit(new GoToPhoneNumber(value));
        break;
      }
    }
  }
}
