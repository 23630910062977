import { createSelector } from '@ngrx/store';
import { SelectI } from 'src/app/core/models/select.interface';
import { getSelectedLanguageSelector } from 'src/app/core/store/selectors/language.selectors';
import { mapDictionaryToSelectForGivenLanguage } from 'src/app/core/utils/dictionary-utils';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import { getErrorExtraTypesStateSelector } from 'src/app/dictionaries/store/index';
import {
  getAllErrorExtraTypes,
  getErrorExtraTypesEntities,
  getErrorExtraTypesError,
  getErrorExtraTypesIds,
  getErrorExtraTypesLoaded,
  getErrorExtraTypesLoading,
  getErrorExtraTypesTotal,
} from 'src/app/dictionaries/store/reducers/error-extra-types.reducer';

export const getErrorExtraTypesIdsSelector = createSelector(
  getErrorExtraTypesStateSelector,
  getErrorExtraTypesIds
);

export const getErrorExtraTypesEntitiesSelector = createSelector(
  getErrorExtraTypesStateSelector,
  getErrorExtraTypesEntities
);

export const getAllErrorExtraTypesTypesSelector = createSelector(
  getErrorExtraTypesStateSelector,
  getAllErrorExtraTypes
);

export const getErrorExtraTypesTotalSelector = createSelector(
  getErrorExtraTypesStateSelector,
  getErrorExtraTypesTotal
);

export const getErrorExtraTypesLoadingSelector = createSelector(
  getErrorExtraTypesStateSelector,
  getErrorExtraTypesLoading
);

export const getErrorExtraTypesLoadedSelector = createSelector(
  getErrorExtraTypesStateSelector,
  getErrorExtraTypesLoaded
);

export const getErrorExtraTypesErrorSelector = createSelector(
  getErrorExtraTypesStateSelector,
  getErrorExtraTypesError
);

export const getErrorExtraTypesEntitiesForSelectedLanguageSelector = createSelector(
  getAllErrorExtraTypesTypesSelector,
  getSelectedLanguageSelector,
  (errorExtraTypes: DictionaryI[], selectedLanguage: string): SelectI[] =>
    mapDictionaryToSelectForGivenLanguage(errorExtraTypes, selectedLanguage)
);
