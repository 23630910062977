import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AssignmentAgendaEnum } from 'src/app/core/enums/assignment-agenda.enum';
import { mapAssignmentSortEnumToAssignmentFilter } from 'src/app/core/enums/assignment-sort.enum';
import { AssignmentLittleI } from 'src/app/core/models/assignment/assignment-little.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AssignmentSearchRequestBuilder } from 'src/app/core/models/search/assignment-search-request.interface';
import { AssignmentSearchResponse } from 'src/app/core/models/search/assignment-search-response.interface';
import { RetrieveAssignmentsFacadeService } from 'src/app/core/services/api/assignments/retrieve-assignments-facade.service';
import {
  AssignmentsActionTypes,
  CleanAssignmentsState,
  LoadAssignment,
  LoadAssignmentsList,
  LoadedAssignmentFail,
  LoadedAssignmentsListFail,
  LoadedAssignmentsListSuccess,
  LoadedAssignmentSuccess,
  LoadedMakeDecisionFail,
  LoadedMakeDecisionSuccess,
  LoadedWatchListFail,
  LoadedWatchListSuccess,
  LoadMakeDecision,
  LoadWatchList,
} from 'src/app/core/store/actions/assignments.action';
import { getAssignmentsStateSelector } from 'src/app/core/store/index';
import { AssignmentsReducerState } from 'src/app/core/store/reducers/assignments.reducer';

@Injectable()
export class AssignmentsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AssignmentsReducerState>,
    private assignmentsFacadeService: RetrieveAssignmentsFacadeService
  ) {}

  
  public tableChanged$ = createEffect(() => this.actions$.pipe(
    ofType(
      AssignmentsActionTypes.ChangeAssignmentsSorting,
      AssignmentsActionTypes.ChangeAssignmentsPagination,
      AssignmentsActionTypes.ChangeAssignmentsTableType,
      AssignmentsActionTypes.CleanAssignmentsSorting,
      AssignmentsActionTypes.RefreshAssignmentTable,
      AssignmentsActionTypes.ChangeAssignmentsQuery
    ),
    withLatestFrom(this.store.pipe(select(getAssignmentsStateSelector))),
    map(([action, state]) => {
      const searchRequest = new AssignmentSearchRequestBuilder()
        .setQuery(state.query)
        .setFilterOnUser(true)
        .setPagination(state.pagination.limit, state.pagination.page);

      if (!!state.sorting) {
        const sortingOption = mapAssignmentSortEnumToAssignmentFilter(
          state.sorting
        );
        searchRequest.setOrdering(sortingOption.order, sortingOption.field);
      }

      switch (state.tableType) {
        case AssignmentAgendaEnum.MAKE_DECISION:
          return new LoadMakeDecision(searchRequest.build());
        case AssignmentAgendaEnum.WATCHLIST:
          return new LoadWatchList(searchRequest.build());
        default:
          searchRequest.setPhaseType(state.tableType);
          return new LoadAssignmentsList(searchRequest.build());
      }
    })
  ));

  
  public loadAssignment$ = createEffect(() => this.actions$.pipe(
    ofType(AssignmentsActionTypes.LoadAssignment),
    switchMap((action: LoadAssignment) =>
      this.assignmentsFacadeService.getAssignment(action.payload).pipe(
        map(
          (assignment: AssignmentLittleI) =>
            new LoadedAssignmentSuccess(assignment)
        ),
        catchError((error: ErrorResponseWithIdI) =>
          of(new LoadedAssignmentFail(error))
        )
      )
    )
  ));

  
  public loadAssignmentList$ = createEffect(() => this.actions$.pipe(
    ofType(AssignmentsActionTypes.LoadAssignmentsList),
    switchMap((action: LoadAssignmentsList) =>
      this.assignmentsFacadeService.searchForAssignments(action.payload).pipe(
        map(
          (assignmentSearchResponse: AssignmentSearchResponse) =>
            new LoadedAssignmentsListSuccess(assignmentSearchResponse)
        ),
        catchError((error: ErrorResponseWithIdI) =>
          of(new LoadedAssignmentsListFail(error))
        )
      )
    )
  ));

  
  public loadWatchList$ = createEffect(() => this.actions$.pipe(
    ofType(AssignmentsActionTypes.LoadWatchList),
    switchMap((action: LoadWatchList) =>
      this.assignmentsFacadeService.searchForWatchList(action.payload).pipe(
        map(
          (searchResult: AssignmentSearchResponse) =>
            new LoadedWatchListSuccess(searchResult)
        ),
        catchError((error: ErrorResponseWithIdI) =>
          of(new LoadedWatchListFail(error))
        )
      )
    )
  ));

  
  public loadMakeDecisionList$ = createEffect(() => this.actions$.pipe(
    ofType(AssignmentsActionTypes.LoadMakeDecision),
    switchMap((action: LoadMakeDecision) =>
      this.assignmentsFacadeService.searchForMakeDecision(action.payload).pipe(
        map(
          (searchResult: AssignmentSearchResponse) =>
            new LoadedMakeDecisionSuccess(searchResult)
        ),
        catchError((error: ErrorResponseWithIdI) =>
          of(new LoadedMakeDecisionFail(error))
        )
      )
    )
  ));

  
  public handleFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      AssignmentsActionTypes.LoadedAssignmentFail,
      AssignmentsActionTypes.LoadedWatchListFail,
      AssignmentsActionTypes.LoadedMakeDecisionFail,
      AssignmentsActionTypes.LoadedAssignmentsListFail
    ),
    map((_) => new CleanAssignmentsState())
  ));
}
