<div class="btn-group" dropdown id="language-select-list">
  <div class="mt-4 mt-md-0">
    <div dropdownToggle class="pointer">
      <button class="btn btm-sm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="icon-user-circle"
        >
          <circle cx="12" cy="12" r="10" class="secondary" />
          <path
            class="primary"
            d="M3.66 17.52A5 5 0 0 1 8 15h8a5 5 0 0 1 4.34 2.52 10 10 0 0 1-16.68 0zM12 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
          />
        </svg>
      </button>
      <span class="onlyOnBig ml-3" translate>common.language</span>
    </div>
  </div>

  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
    <li *ngFor="let language of languages">
      <a
        class="dropdown-item"
        (click)="onSelect(language)"
        [ngClass]="{ bold: language === selectedLanguage }"
      >
        <span translate id="{{ language }}">{{ language }}</span>
      </a>
    </li>
  </ul>
</div>
