import { Action } from '@ngrx/store';

export enum SupportActionTypes {
  LoadZenDesk = '[Support] Load ZenDesk',
  LoadZenDeskSuccess = '[Support] Load ZenDesk Success',

  ShowLauncher = '[Support] Show Launcher',
  HideLauncher = '[Support] Hide Launcher',

  OpenSupportPanel = '[Support] Open Support Panel',
}

export class LoadZenDesk implements Action {
  readonly type = SupportActionTypes.LoadZenDesk;
}

export class LoadZenDeskSuccess implements Action {
  readonly type = SupportActionTypes.LoadZenDeskSuccess;
}

export class ShowLauncher implements Action {
  readonly type = SupportActionTypes.ShowLauncher;
}

export class HideLauncher implements Action {
  readonly type = SupportActionTypes.HideLauncher;
}

export class OpenSupportPanel implements Action {
  readonly type = SupportActionTypes.OpenSupportPanel;
}

export type SupportActions =
  | LoadZenDesk
  | LoadZenDeskSuccess
  | ShowLauncher
  | HideLauncher
  | OpenSupportPanel;
