import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { getEventTypesStateSelector } from 'src/app/dictionaries/store';
import { LoadEventTypes } from 'src/app/dictionaries/store/actions/event-types.action';
import { EventTypesReducerState } from 'src/app/dictionaries/store/reducers/event-types.reducer';
import { SpaceTypesReducerState } from 'src/app/dictionaries/store/reducers/space-types.reducer';

@Injectable()
export class EventTypesGuard implements CanActivate {
  constructor(private store: Store<SpaceTypesReducerState>) {}

  canActivate() {
    return this.waitForEventTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForEventTypesToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getEventTypesStateSelector),
      tap((eventTypesState: EventTypesReducerState) => {
        if (
          !eventTypesState.loaded &&
          !eventTypesState.loading &&
          !eventTypesState.error
        ) {
          this.store.dispatch(new LoadEventTypes());
        }
      }),
      filter(
        (eventTypesState: EventTypesReducerState) =>
          eventTypesState.loaded || !!eventTypesState.error
      ),
      map((eventTypesState: EventTypesReducerState) => {
        if (!!eventTypesState.error) {
          throw new Error(eventTypesState.error.error.message);
        } else {
          return eventTypesState.loaded;
        }
      }),
      take(1)
    );
  }
}
