import { AssignmentSortI } from 'src/app/core/models/assignment/assignment-sort.interface';

export enum AssignmentSortEnum {
  ASSIGNMENT_NAME_ASC = 'assignmentNameAsc',
  ASSIGNMENT_NAME_DESC = 'assignmentNameDesc',

  CREATED_DATE_ASC = 'createdDateAsc',
  CREATED_DATE_DESC = 'createdDateDesc',

  TOTAL_ASC = 'totalAsc',
  TOTAL_DESC = 'totalDesc',

  CONTRACTOR_ASC = 'contractorAsc',
  CONTRACTOR_DESC = 'contractorDesc',
}

export function mapAssignmentSortEnumToAssignmentFilter(
  sort: AssignmentSortEnum
): AssignmentSortI {
  switch (sort) {
    case AssignmentSortEnum.ASSIGNMENT_NAME_ASC: {
      return {
        order: 'asc',
        field: 'assignmentName',
      };
    }
    case AssignmentSortEnum.ASSIGNMENT_NAME_DESC: {
      return {
        order: 'desc',
        field: 'assignmentName',
      };
    }
    case AssignmentSortEnum.CREATED_DATE_ASC: {
      return {
        order: 'asc',
        field: 'createdInUw',
      };
    }
    case AssignmentSortEnum.CREATED_DATE_DESC: {
      return {
        order: 'desc',
        field: 'createdInUw',
      };
    }
    case AssignmentSortEnum.TOTAL_ASC: {
      return {
        order: 'asc',
        field: 'costSummaryTotalCostExcludingVat',
      };
    }
    case AssignmentSortEnum.TOTAL_DESC: {
      return {
        order: 'desc',
        field: 'costSummaryTotalCostExcludingVat',
      };
    }
    case AssignmentSortEnum.CONTRACTOR_ASC: {
      return {
        order: 'asc',
        field: 'mainContractor',
      };
    }
    case AssignmentSortEnum.CONTRACTOR_DESC: {
      return {
        order: 'desc',
        field: 'mainContractor',
      };
    }
  }
}
