import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'app-uw-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('input', { static: true }) input;

  @Input()
  query: string;

  @Input()
  placeHolder: string;

  @Output()
  search: EventEmitter<string> = new EventEmitter<string>();

  subscription: Subscription;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.subscription = this.input.valueChanges
      .pipe(
        filter((value) => value !== this.query),
        debounceTime(350),
        distinctUntilChanged()
      )
      .subscribe((q) => this.onChange(q));
  }

  onChange(query: string) {
    this.search.emit(query);
  }

  ngOnDestroy(): void {
    if (!!this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
