import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

export enum TradeTypesActionTypes {
  LoadTradeTypes = '[TradeTypes] Load Trade Types',
  LoadedTradeTypesSuccess = '[TradeTypes] Loaded Trade Types',
  LoadedTradeTypesFail = '[TradeTypes] Fail To Load Trade Types',

  CleanTradeTypesState = '[TradeTypes] Clean',
}

@ShowLoader()
export class LoadTradeTypes implements Action {
  readonly type = TradeTypesActionTypes.LoadTradeTypes;

  constructor(public payload?: any) {}
}

@HideLoader(TradeTypesActionTypes.LoadTradeTypes)
export class LoadedTradeTypesSuccess implements Action {
  readonly type = TradeTypesActionTypes.LoadedTradeTypesSuccess;

  constructor(public payload: DictionaryI[]) {}
}

@HideLoader(TradeTypesActionTypes.LoadTradeTypes)
export class LoadedTradeTypesFail implements Action {
  readonly type = TradeTypesActionTypes.LoadedTradeTypesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanTradeTypesState implements Action {
  readonly type = TradeTypesActionTypes.CleanTradeTypesState;

  constructor(public payload?: string) {}
}

export type TradeTypesAction =
  | LoadTradeTypes
  | LoadedTradeTypesSuccess
  | LoadedTradeTypesFail
  | CleanTradeTypesState;
