import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { PdfPayloadI } from 'src/app/core/models/pdf-payload.interface';

export enum PdfActionsTypes {
  DownloadCostPDF = '[PDF] Download Cost PDF',
  DownloadCostPDFSuccess = '[PDF] Download Cost PDF Success',
  DownloadCostPDFError = '[PDF] Download Cost PDF Error',
}

@ShowLoader()
export class DownloadCostPDF implements Action {
  readonly type = PdfActionsTypes.DownloadCostPDF;

  constructor(public payload: PdfPayloadI) {}
}

@HideLoader(PdfActionsTypes.DownloadCostPDF)
export class DownloadCostPDFSuccess implements Action {
  readonly type = PdfActionsTypes.DownloadCostPDFSuccess;
}

@HideLoader(PdfActionsTypes.DownloadCostPDF)
export class DownloadCostPDFError implements Action {
  readonly type = PdfActionsTypes.DownloadCostPDFError;

  constructor(public payload: any) {}
}

export type PDFActions =
  | DownloadCostPDF
  | DownloadCostPDFSuccess
  | DownloadCostPDFError;
