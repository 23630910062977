export enum CoreUrlEnums {
  ASSIGNMENTS = 'assignments',
  NUMBERS = 'numbers',
  ADMINISTRATION = 'administration',
  PROJECTS = 'projects',

  ANALYSIS = 'analysis',

  LOGIN = 'login',
  BLOCKED = 'blocked',
  REGISTER = 'register',

  SUPPORT = 'support',
  GO_MESSAGE_PANEL = 'messages-panel',
}

export enum AssignmentUrlEnum {
  START = 'start',
  START_FORM = 'form',

  OVERVIEW = 'overview',

  PHASES = 'phases',
  PHASES_MAKE_DECISION = 'make-decision',
  PHASES_WATCHLIST = 'watchlist',
  PHASES_PREPARATION = 'preparation',
  PHASES_ORDERING = 'ordering',
  PHASES_PRODUCTION = 'production',
  PHASES_CLOSING = 'closing',
}

export namespace AssignmentUrlEnum {
  export function getAssignmentUrl(url: string | number) {
    return `${CoreUrlEnums.ASSIGNMENTS}/${url}/`;
  }

  export function getAssignmentStartUrl(url: string | number) {
    return `${CoreUrlEnums.ASSIGNMENTS}/${AssignmentUrlEnum.START}/${url}/`;
  }

  export function getAssignmentOverviewUrl(url: string | number) {
    return `${CoreUrlEnums.ASSIGNMENTS}/${AssignmentUrlEnum.OVERVIEW}/${url}/`;
  }

  export function getAssignmentPhaseUrl(url: string | number) {
    return `${CoreUrlEnums.ASSIGNMENTS}/${AssignmentUrlEnum.PHASES}/${url}/`;
  }
}

export enum NumbersUrlEnum {
  DASHBOARD = 'dashboard',
  DASHBOARD_PORTFOLIO = 'portfolio',
  DASHBOARD_DISTRICT = 'district',
  DASHBOARD_CONTRACTORS = 'contractors',

  SEARCH = 'search',
  SEARCH_LIST = 'list',
  SEARCH_MAP = 'map',
}

export namespace NumbersUrlEnum {
  export function getNumbersUrl(url: string | number) {
    return `${CoreUrlEnums.NUMBERS}/${url}/`;
  }

  export function getNumbersDashboardUrl(url: string | number) {
    return `${CoreUrlEnums.NUMBERS}/${NumbersUrlEnum.DASHBOARD}/${url}/`;
  }

  export function getNumbersSearchUrl(url: string | number) {
    return `${CoreUrlEnums.NUMBERS}/${NumbersUrlEnum.SEARCH}/${url}`;
  }
}

export enum AnalysisUrlEnum {
  COMPARE_ASSIGNMENTS = 'compare-assignments',

  START = 'start',

  LOG = 'log',
  COST_SUMMARY = 'cost-summary',

  ASSIGNMENT_COST = 'assignment-cost',
  ASSIGNMENT_COST_SPACE = 'space',
}

export namespace AnalysisUrlEnum {
  export function getAnalysisUrl(url: string | number) {
    return `${CoreUrlEnums.ANALYSIS}/${url}/`;
  }

  export function getAnalysisDetailsUrl(url: string | number) {
    return `${CoreUrlEnums.ANALYSIS}/${AnalysisUrlEnum.START}/${url}/`;
  }

  export function getAnalysisAssignmentCostUrl(url: string | number) {
    return `${CoreUrlEnums.ANALYSIS}/${AnalysisUrlEnum.ASSIGNMENT_COST}/${url}/`;
  }

  export function getAnalysisLogUrl(url: string | number) {
    return `${CoreUrlEnums.ANALYSIS}/${AnalysisUrlEnum.LOG}/${url}/`;
  }

  export function getAnalysisCompareAssignments() {
    return `${CoreUrlEnums.ANALYSIS}/${AnalysisUrlEnum.COMPARE_ASSIGNMENTS}`;
  }

  export function getAnalysisCostSummary(url: string | number) {
    return `${CoreUrlEnums.ANALYSIS}/${AnalysisUrlEnum.COST_SUMMARY}/${url}/`;
  }

  export function getAnalysisSpaceSummaryUrl(
    url: string | number,
    assignmentId
  ) {
    return `${CoreUrlEnums.ANALYSIS}/${AnalysisUrlEnum.ASSIGNMENT_COST}/${assignmentId}/${AnalysisUrlEnum.ASSIGNMENT_COST_SPACE}`;
  }
}

export enum AdministrationUrlEnum {
  COMPANY = 'company',
  USERS = 'users',
  AGREEMENTS = 'agreements',
}

export namespace AdministrationUrlEnum {
  export function getAdministrationUrl(url: string | number) {
    return `${CoreUrlEnums.ADMINISTRATION}/${url}/`;
  }
}

export enum ProjectsUrlEnum {
  LIST = 'list',
  DETAILS = 'details',
}

export namespace ProjectsUrlEnum {
  export function getProjectListUrl() {
    return `${CoreUrlEnums.PROJECTS}/${ProjectsUrlEnum.LIST}`;
  }
  export function getProjectDetailsUrl(id: string) {
    return `${CoreUrlEnums.PROJECTS}/${ProjectsUrlEnum.DETAILS}/${id}`;
  }
}
