import { createSelector } from '@ngrx/store';
import {
  CustomerPublicI,
  UserPublicI,
} from 'src/app/core/models/user/user-resource.interface';
import { getAuthUserStateSelector } from 'src/app/auth/store/index';

import {
  getCustomerPublic,
  getUserError,
  getUserLoaded,
  getUserLoading,
  getUserPublic,
  getUserResource,
} from 'src/app/auth/store/reducers/user.reducer';

export const getUserLoadingSelector = createSelector(
  getAuthUserStateSelector,
  getUserLoading
);

export const getUserLoadedSelector = createSelector(
  getAuthUserStateSelector,
  getUserLoaded
);

export const getUserErrorSelector = createSelector(
  getAuthUserStateSelector,
  getUserError
);

export const getUserResourceSelector = createSelector(
  getAuthUserStateSelector,
  getUserResource
);

export const getUserPublicSelector = createSelector(
  getAuthUserStateSelector,
  getUserPublic
);

export const getCustomerPublicSelector = createSelector(
  getAuthUserStateSelector,
  getCustomerPublic
);

// CUSTOM

export const isExternalKeyPresentSelector = createSelector(
  getUserPublicSelector,
  (userPublic: UserPublicI) => (!!userPublic ? !!userPublic.externalId : true)
);

export const isPropertyRegisterEnabledSelector = createSelector(
  getCustomerPublicSelector,
  (customerPublic: CustomerPublicI) =>
    !!customerPublic && !!customerPublic.enablePropertyListSelection
);

export const getCustomerIdSelector = createSelector(
  getCustomerPublicSelector,
  (customerPublic: CustomerPublicI) =>
    customerPublic && customerPublic.customerId
);
