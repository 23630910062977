import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { ChartResultI } from 'src/app/core/models/log/chart-result.interface';

export enum AssignmentVolumeChartActionTypes {
  LoadAssignmentVolumeChart = '[AssignmentVolumeChart] Load Assignment Volume Chart',
  LoadedAssignmentVolumeChartSuccess = '[AssignmentVolumeChart] Loaded Assignment Volume Chart Success',
  LoadedAssignmentVolumeChartFail = '[AssignmentVolumeChart] Fail To Load Assignment Volume Chart',

  CleanAssignmentVolumeChartState = '[AssignmentVolumeChart] Clean Assignment Volume Chart',
}

@ShowLoader()
export class LoadAssignmentVolumeChart implements Action {
  readonly type = AssignmentVolumeChartActionTypes.LoadAssignmentVolumeChart;
}

@HideLoader(AssignmentVolumeChartActionTypes.LoadAssignmentVolumeChart)
export class LoadedAssignmentVolumeChartsSuccess implements Action {
  readonly type =
    AssignmentVolumeChartActionTypes.LoadedAssignmentVolumeChartSuccess;

  constructor(public payload: ChartResultI[]) {}
}

@HideLoader(AssignmentVolumeChartActionTypes.LoadAssignmentVolumeChart)
export class LoadedAssignmentVolumeChartsFail implements Action {
  readonly type =
    AssignmentVolumeChartActionTypes.LoadedAssignmentVolumeChartFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanAssignmentVolumeChartState implements Action {
  readonly type =
    AssignmentVolumeChartActionTypes.CleanAssignmentVolumeChartState;
}

export type AssignmentVolumeChartAction =
  | LoadAssignmentVolumeChart
  | LoadedAssignmentVolumeChartsSuccess
  | LoadedAssignmentVolumeChartsFail
  | CleanAssignmentVolumeChartState;
