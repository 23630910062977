import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssignmentLittleI } from 'src/app/core/models/assignment/assignment-little.interface';
import { PullingRequestI } from 'src/app/core/models/polling/pulling-request.interface';
import { PullingResponseI } from 'src/app/core/models/polling/pulling-response.interface';
import { AssignmentSearchRequest } from 'src/app/core/models/search/assignment-search-request.interface';
import { AssignmentSearchResponse } from 'src/app/core/models/search/assignment-search-response.interface';
import { AssignmentStartModelI } from 'src/app/core/models/start/assignment-start-model.interface';
import { AssignmentAppApiService } from 'src/app/core/services/api/assignment-app-api/assignment-app-api.service';

import { RetrieveAssignmentsService } from 'src/app/core/services/api/assignments/retrieve-assignments.service';
import { MeService } from 'src/app/core/services/api/me/me.service';
import { AssignmentLogResourceI } from '../../../models/log/assignment-log-resource.interface';

@Injectable()
export class RetrieveAssignmentsFacadeService {
  constructor(
    private assignmentService: RetrieveAssignmentsService,
    private assignmentAppApiService: AssignmentAppApiService,
    private meService: MeService
  ) {}

  getAssignment(id: number): Observable<AssignmentLittleI> {
    return this.assignmentService.getAssignment(id);
  }

  searchForAssignments(
    assignmentSearchRequest: AssignmentSearchRequest
  ): Observable<AssignmentSearchResponse> {
    return this.assignmentService.searchForAssignments(assignmentSearchRequest);
  }

  searchForMakeDecision(
    assignmentSearchRequest: AssignmentSearchRequest
  ): Observable<AssignmentSearchResponse> {
    return this.assignmentAppApiService.searchForMakeDecision(
      assignmentSearchRequest
    );
  }

  searchForWatchList(
    assignmentSearchRequest: AssignmentSearchRequest
  ): Observable<AssignmentSearchResponse> {
    return this.assignmentAppApiService.searchForWatchlist(
      assignmentSearchRequest
    );
  }

  getPendingAssignmentIds(): Observable<number[]> {
    return this.meService.getPendingAssignmentIds();
  }

  pull(pullingRequest: PullingRequestI): Observable<PullingResponseI[]> {
    return this.assignmentService.pull(pullingRequest);
  }

  getAssignmentStart(assignmentId: number): Observable<AssignmentStartModelI> {
    return this.assignmentService.getAssignmentStart(assignmentId);
  }

  getAssignmentLog(assignmentId: number): Observable<AssignmentLogResourceI> {
    return this.assignmentService.getAssignmentLog(assignmentId);
  }
}
