import { Injectable, Injector } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/core/services/google/analytics/google-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class AngularErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error) {
    const gaService = this.injector.get(GoogleAnalyticsService);

    const message = error.message ? error.message : error.toString();
    const reason =
      error.ngDebugContext &&
      error.ngDebugContext.nodeDef &&
      error.ngDebugContext.nodeDef.element
        ? error.ngDebugContext.nodeDef.element.name
        : 'unknown element';

    gaService.error({
      reason: reason,
      message: message,
    });

    throw error;
  }
}
