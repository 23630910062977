import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ExcelService {
  private excelExportUrl = environment.url + 'api/v1/excel-export/';
  private createWithAssignmentUrl =
    this.excelExportUrl + 'create-with-assignments/';
  private createWithSimpleAssignmentUrl =
    this.createWithAssignmentUrl + 'simple/';
  private downloadExcelWithAssignmentEventIdsUrl =
    this.excelExportUrl + 'create-with-assignment-events/';
  private downloadExcelWithProjectIdUrl =
    this.excelExportUrl + 'create-with-project/simple/';

  constructor(private http: HttpClient) {}

  exportExcel(ids: number[]): Observable<string> {
    return this.http.post(this.createWithAssignmentUrl, ids, {
      responseType: 'text',
    });
  }

  exportSimpleExcel(ids: number[]): Observable<string> {
    return this.http.post(this.createWithSimpleAssignmentUrl, ids, {
      responseType: 'text',
    });
  }

  exportToAssignmentEventsExcel(ids: number[]) {
    return this.http.post(this.downloadExcelWithAssignmentEventIdsUrl, ids, {
      responseType: 'text',
    });
  }

  exportToProjectSimpleExcel(id: number) {
    return this.http.post(this.downloadExcelWithProjectIdUrl, id, {
      responseType: 'text',
    });
  }
}
