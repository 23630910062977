import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AssignmentAgendaEnum } from 'src/app/core/enums/assignment-agenda.enum';
import { AssignmentPhaseEnum } from 'src/app/core/enums/assignment-phase.enum';
import { AssignmentSortEnum } from 'src/app/core/enums/assignment-sort.enum';
import { AssignmentLittleI } from 'src/app/core/models/assignment/assignment-little.interface';
import { AssignmentPaginationI } from 'src/app/core/models/assignment/assignment-pagination.interface';

import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PullingResponseI } from 'src/app/core/models/polling/pulling-response.interface';
import {
  AssignmentsActions,
  AssignmentsActionTypes,
} from 'src/app/core/store/actions/assignments.action';
import {
  PullingQueueActions,
  PullQueueActionTypes,
} from 'src/app/core/store/actions/pulling-queue.actions';

const selectId = (assignmentResource: AssignmentLittleI) =>
  assignmentResource.details.assignmentId;
export const adapter: EntityAdapter<AssignmentLittleI> = createEntityAdapter<
  AssignmentLittleI
>({ selectId });

export interface AssignmentsReducerState
  extends EntityState<AssignmentLittleI> {
  selectedAssignments: AssignmentLittleI[];

  tableType: AssignmentPhaseEnum | AssignmentAgendaEnum;
  sortingOptions: AssignmentSortEnum[];
  sorting: AssignmentSortEnum;

  query: string;
  pagination: AssignmentPaginationI;

  requestedTime: number;
  total: number;

  loading: boolean;
  loaded: boolean;
  newVersionIsAvailable: boolean;
  error: ErrorResponseWithIdI;
}

export const initialAssignmentsReducerState: AssignmentsReducerState = adapter.getInitialState(
  {
    selectedAssignments: [],

    tableType: undefined,

    sortingOptions: [],
    sorting: null,

    query: '',
    pagination: {
      page: 1,
      limit: 10,
    },

    requestedTime: 0,
    total: 0,

    newVersionIsAvailable: false,
    loading: false,
    loaded: false,
    error: null,
  }
);

export function assignmentsReducer(
  state: AssignmentsReducerState = initialAssignmentsReducerState,
  action: AssignmentsActions | PullingQueueActions
): AssignmentsReducerState {
  switch (action.type) {
    case PullQueueActionTypes.SynchronizePullingQueueSuccess:
      const isNewVersionAvailable = action.payload.filter(
        (response: PullingResponseI) =>
          state.tableType === response.phase ||
          state.tableType === response.agenda
      );

      return {
        ...state,
        newVersionIsAvailable:
          state.newVersionIsAvailable || isNewVersionAvailable.length > 0,
      };

    case AssignmentsActionTypes.LoadWatchList:
    case AssignmentsActionTypes.LoadMakeDecision:
    case AssignmentsActionTypes.LoadAssignmentsList: {
      return {
        ...initialAssignmentsReducerState,
        loading: true,
        query: state.query,
        selectedAssignments: [...state.selectedAssignments],
        tableType: state.tableType,
        sorting: state.sorting,
        sortingOptions: state.sortingOptions,
        pagination: { ...state.pagination },
      };
    }

    case AssignmentsActionTypes.LoadedWatchListSuccess:
    case AssignmentsActionTypes.LoadedMakeDecisionSuccess:
    case AssignmentsActionTypes.LoadedAssignmentsListSuccess: {
      return adapter.upsertMany(
        <AssignmentLittleI[]>action.payload.assignments,
        {
          ...state,
          requestedTime: action.payload.created,
          total: action.payload.numberFound,
          loading: false,
          loaded: true,
        }
      );
    }

    case AssignmentsActionTypes.LoadAssignment: {
      return {
        ...state,
        loading: true,
      };
    }

    case AssignmentsActionTypes.LoadedAssignmentSuccess: {
      return adapter.upsertOne(<AssignmentLittleI>action.payload, {
        ...state,
        loading: false,
      });
    }

    case AssignmentsActionTypes.LoadedAssignmentFail:
    case AssignmentsActionTypes.LoadedWatchListFail:
    case AssignmentsActionTypes.LoadedMakeDecisionFail:
    case AssignmentsActionTypes.LoadedAssignmentsListFail: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case AssignmentsActionTypes.RefreshAssignmentTable:
    case AssignmentsActionTypes.ChangeAssignmentsTableType: {
      return {
        ...initialAssignmentsReducerState,
        tableType: action.payload,
        sortingOptions: getSortOptions(action.payload),
      };
    }

    case AssignmentsActionTypes.ChangeAssignmentsQuery: {
      return {
        ...state,
        query: action.payload,
        pagination: {
          ...initialAssignmentsReducerState.pagination,
        },
      };
    }

    case AssignmentsActionTypes.ChangeAssignmentsSorting: {
      return {
        ...state,
        sorting: action.payload,
      };
    }

    case AssignmentsActionTypes.CleanAssignmentsSorting: {
      return {
        ...state,
        sorting: initialAssignmentsReducerState.sorting,
        selectedAssignments: [],
      };
    }

    case AssignmentsActionTypes.ChangeAssignmentsPagination: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload,
        },
      };
    }

    case AssignmentsActionTypes.UpdateSelectedAssignments: {
      return {
        ...state,
        selectedAssignments: [...action.payload],
      };
    }

    case AssignmentsActionTypes.CleanAssignmentsState: {
      return {
        ...initialAssignmentsReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

function getSortOptions(status: AssignmentPhaseEnum | AssignmentAgendaEnum) {
  let filterOptions = [
    AssignmentSortEnum.ASSIGNMENT_NAME_ASC,
    AssignmentSortEnum.ASSIGNMENT_NAME_DESC,
  ];

  if (
    status === AssignmentAgendaEnum.MAKE_DECISION ||
    status === AssignmentAgendaEnum.WATCHLIST
  ) {
    filterOptions = [
      ...filterOptions,
      AssignmentSortEnum.CONTRACTOR_ASC,
      AssignmentSortEnum.CONTRACTOR_DESC,
    ];
  }

  if (
    status === AssignmentPhaseEnum.PREPARATION ||
    status === AssignmentPhaseEnum.ORDERING
  ) {
    filterOptions = [
      ...filterOptions,
      AssignmentSortEnum.CREATED_DATE_ASC,
      AssignmentSortEnum.CREATED_DATE_DESC,
    ];
  }

  if (
    status === AssignmentPhaseEnum.PRODUCTION ||
    status === AssignmentPhaseEnum.CLOSING
  ) {
    filterOptions = [
      ...filterOptions,
      AssignmentSortEnum.TOTAL_ASC,
      AssignmentSortEnum.TOTAL_DESC,
    ];
  }

  return filterOptions;
}

export const getSelectedAssignments = (state: AssignmentsReducerState) =>
  state.selectedAssignments;

export const isNewVersionIsAvailable = (state: AssignmentsReducerState) =>
  state.newVersionIsAvailable;
export const getAssignmentsLoading = (state: AssignmentsReducerState) =>
  state.loading;
export const getAssignmentsLoaded = (state: AssignmentsReducerState) =>
  state.loaded;
export const getAssignmentsError = (state: AssignmentsReducerState) =>
  state.error;

export const getAssignmentsTableType = (state: AssignmentsReducerState) =>
  state.tableType;
export const getAssignmentsSorting = (state: AssignmentsReducerState) =>
  state.sorting;
export const getAssignmentsPagination = (state: AssignmentsReducerState) =>
  state.pagination;

export const getAssignmentsRequestedTime = (state: AssignmentsReducerState) =>
  state.requestedTime;
export const getAllAssignmentsTotal = (state: AssignmentsReducerState) =>
  state.total;

export const getAssignmentQuery = (state: AssignmentsReducerState) =>
  state.query;
export const getAssignmentSorting = (state: AssignmentsReducerState) =>
  state.sorting;
export const getAssignmentSortOptions = (state: AssignmentsReducerState) =>
  state.sortingOptions;
