import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';

import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PullingResponseI } from 'src/app/core/models/polling/pulling-response.interface';

export enum PullQueueActionTypes {
  AddAssignmentForPulling = '[PullQueue] Add Assignment For Pulling',

  LoadPendingAssignmentIdsList = '[PullQueue] Load Pending Assignment Ids List',
  LoadPendingAssignmentIdsListSuccess = '[PullQueue] Load Pending Assignment Ids List Success',
  LoadPendingAssignmentIdsListFail = '[PullQueue] Load Pending Assignment Ids List Fail',

  SynchronizePullingQueue = '[PullQueue] Synchronize',
  SynchronizePullingQueueSuccess = '[PullQueue] Synchronize Success',
  SynchronizePullingQueueFail = '[PullQueue] Synchronize Fail',
}

export class AddAssignmentForPulling implements Action {
  readonly type = PullQueueActionTypes.AddAssignmentForPulling;

  constructor(public payload: number) {}
}

@ShowLoader()
export class LoadPendingAssignmentIdsList implements Action {
  readonly type = PullQueueActionTypes.LoadPendingAssignmentIdsList;
}

@HideLoader(PullQueueActionTypes.LoadPendingAssignmentIdsList)
export class LoadPendingAssignmentIdsListSuccess implements Action {
  readonly type = PullQueueActionTypes.LoadPendingAssignmentIdsListSuccess;

  constructor(public payload: number[]) {}
}

@HideLoader(PullQueueActionTypes.LoadPendingAssignmentIdsList)
export class LoadPendingAssignmentIdsListFail implements Action {
  readonly type = PullQueueActionTypes.LoadPendingAssignmentIdsListFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class SynchronizePullingQueue implements Action {
  readonly type = PullQueueActionTypes.SynchronizePullingQueue;

  constructor(public payload: number[]) {}
}

export class SynchronizePullingQueueSuccess implements Action {
  readonly type = PullQueueActionTypes.SynchronizePullingQueueSuccess;

  constructor(public payload: PullingResponseI[]) {}
}

export class SynchronizePullingQueueFail implements Action {
  readonly type = PullQueueActionTypes.SynchronizePullingQueueFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export type PullingQueueActions =
  | AddAssignmentForPulling
  | LoadPendingAssignmentIdsList
  | LoadPendingAssignmentIdsListSuccess
  | LoadPendingAssignmentIdsListFail
  | SynchronizePullingQueue
  | SynchronizePullingQueueSuccess
  | SynchronizePullingQueueFail;
