import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterReducerState, RouterStateSerializer } from '@ngrx/router-store';
import { Injectable } from '@angular/core';

export interface RouterStateUrl {
  previousUrl: string;
  url: string;
  params: Params;
  queryParams: Params;
}

// TODO: Add test cases
@Injectable()
export class CustomRouterStateSerializer
  implements RouterStateSerializer<RouterStateUrl> {
  loginPageUrl = '/login';
  storedPreviousUrl = '';

  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const previousUrl = this.storedPreviousUrl;
    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params } = route;

    this.storedPreviousUrl =
      url === this.loginPageUrl ? this.storedPreviousUrl : url;

    return { previousUrl, url, params, queryParams };
  }
}

export const getRouterPreviousUrl = (
  state: RouterReducerState<RouterStateUrl>
) => state && state.state && state.state.previousUrl;
export const getRouterUrl = (state: RouterReducerState<RouterStateUrl>) =>
  state && state.state && state.state.url;
export const getRouterParams = (state: RouterReducerState<RouterStateUrl>) =>
  state && state.state && state.state.params;
export const getRouterQueryParams = (
  state: RouterReducerState<RouterStateUrl>
) => state && state.state && state.state.queryParams;
