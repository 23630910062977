import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AnalysisUrlEnum } from 'src/app/core/enums/url-paths.enum';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AssignmentsService } from 'src/app/core/services/api/assignments/assignments.service';
import { LoadAssignment } from 'src/app/core/store/actions/assignments.action';
import {
  ContractorActionTypes,
  InviteContractor,
  InviteContractorFail,
  InviteContractorSuccess,
} from 'src/app/core/store/actions/contractor.action';
import { RouterGo } from 'src/app/core/store/actions/router.action';

@Injectable()
export class ContractorEffects {
  detailsUrl = AnalysisUrlEnum.getAnalysisUrl(AnalysisUrlEnum.START);

  constructor(
    private actions$: Actions,
    private assignmentService: AssignmentsService
  ) {}

  
  public inviteContractor$ = createEffect(() => this.actions$.pipe(
    ofType(ContractorActionTypes.InviteContractor),
    switchMap((action: InviteContractor) =>
      this.assignmentService.inviteContractor(action.payload).pipe(
        map(() => new InviteContractorSuccess(action.payload.assignmentId)),
        catchError((error: ErrorResponseWithIdI) =>
          of(new InviteContractorFail(error))
        )
      )
    )
  ));

  
  public handleInviteContractor$ = createEffect(() => this.actions$.pipe(
    ofType(ContractorActionTypes.InviteContractorSuccess),
    switchMap((action: InviteContractorSuccess) =>
      of(
        new LoadAssignment(action.payload),
        new RouterGo({ path: [this.detailsUrl + action.payload] })
      )
    )
  ));
}
