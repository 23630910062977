import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/auth/services/authorization/authorization.service';
import { isExternalKeyPresentSelector } from 'src/app/auth/store/selectors/user.selectors';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';
import {
  DisablePWAClicked,
  InstructionsPWAClicked,
} from 'src/app/core/store/actions/google-analytics.actions';
import { PWAStateAction } from 'src/app/core/store/actions/pwa.action';
import { RouterGo } from 'src/app/core/store/actions/router.action';
import { getAllAlertMessagesSelector } from 'src/app/core/store/selectors/alert-messages.selectors';
import { shouldDisablePWASelector } from 'src/app/core/store/selectors/pwa.selectors';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent
  extends StoreDispatcher<any>
  implements OnInit, OnDestroy {
  translateKey = 'newVersion';

  assignmentDeletedMessageKey = 'alert.messageDeleted';
  noExternalIdErrorKey = 'noExternalIdMessage';

  alerts$: Observable<AlertMessage[]>;

  doesTokenExists: boolean;

  isExternalIdSet$: Observable<boolean>;

  assignmentDeletedMessage$: Observable<string>;
  noExternalIdErrorMessage$: Observable<string>;

  disablePWA$: Observable<boolean>;

  subscription: Subscription;

  constructor(
    private swUpdate: SwUpdate,
    private authService: AuthorizationService,
    private translateService: TranslateService,
    store: Store<any>
  ) {
    super(store);
  }

  ngOnInit() {
    this.subscription = this.authService.doesTokenExists$.subscribe(
      (exists) => (this.doesTokenExists = exists)
    );

    this.isExternalIdSet$ = this.store.pipe(
      select(isExternalKeyPresentSelector)
    );
    this.noExternalIdErrorMessage$ = this.translateService.stream(
      this.noExternalIdErrorKey
    );

    this.assignmentDeletedMessage$ = this.translateService.stream(
      this.assignmentDeletedMessageKey
    );

    this.alerts$ = this.store.pipe(select(getAllAlertMessagesSelector));

    this.disablePWA$ = this.store.pipe(select(shouldDisablePWASelector));

    this.swUpdate.available
      .pipe(withLatestFrom(this.translateService.stream(this.translateKey)))
      .subscribe(([event, translation]) => {
        if (confirm(translation)) {
          window.location.reload();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSkipPWA(action: PWAStateAction) {
    const gaAction = new DisablePWAClicked();
    this.dispatchActions([action, gaAction]);
  }

  onRedirectToSupport(action: RouterGo) {
    const gaAction = new InstructionsPWAClicked();
    this.dispatchActions([action, gaAction]);
  }
}
