import { InjectionToken } from '@angular/core';
import {
  ActionReducerMap,
  combineReducers,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import {
  AuthInfoState,
  authInfoStateReducer,
} from 'src/app/auth/store/reducers/info.reducer';
import {
  UserState,
  userStateReducer,
} from 'src/app/auth/store/reducers/user.reducer';

export interface AuthState {
  info: AuthInfoState;
  user: UserState;
}

export const authReducers = combineReducers({
  info: authInfoStateReducer,
  user: userStateReducer,
});

// Injection Token
export const authReducerToken = new InjectionToken<ActionReducerMap<AuthState>>(
  'auth'
);

export function getReducers() {
  return authReducers;
}

export const authReducerProvider = [
  { provide: authReducerToken, useFactory: getReducers },
];

// Selectors
export const getAuthStateSelector = createFeatureSelector<AuthState>('auth');

export const getAuthUserStateSelector = createSelector(
  getAuthStateSelector,
  (authState: AuthState) => authState.user
);

export const getAuthInfoStateSelector = createSelector(
  getAuthStateSelector,
  (authState: AuthState) => authState.info
);
