import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { getAgreementsStateSelector } from 'src/app/core/store';
import { LoadAgreements } from 'src/app/core/store/actions/agreements.action';
import { AgreementsReducerState } from 'src/app/core/store/reducers/agreements.reducer';

@Injectable()
export class AgreementsGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<AgreementsReducerState>) {}

  canActivate(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  canActivateChild(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  checkIfCanActivate(): Observable<boolean> {
    return this.waitForAgreementsToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForAgreementsToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getAgreementsStateSelector),
      tap((agreementsState: AgreementsReducerState) => {
        if (
          !agreementsState.loaded &&
          !agreementsState.loading &&
          !agreementsState.error
        ) {
          this.store.dispatch(new LoadAgreements());
        }
      }),
      filter(
        (agreementsState: AgreementsReducerState) =>
          agreementsState.loaded || !!agreementsState.error
      ),
      map((agreementsState: AgreementsReducerState) => {
        if (!!agreementsState.error) {
          throw new Error(agreementsState.error.error.message);
        } else {
          return agreementsState.loaded;
        }
      }),
      take(1)
    );
  }
}
