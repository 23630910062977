import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

const b64toBlob = require('b64-to-blob');

@Injectable({
  providedIn: 'root',
})
export class Base64Service {
  constructor(private datePipe: DatePipe) {}

  openInNewWindow(response) {
    const file = b64toBlob(response, 'application/pdf');
    const fileURL = window.URL.createObjectURL(file);

    const a = document.createElement('a');

    a.href = fileURL;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
  }

  saveToSystem(filenameStart, response) {
    // Simple sanitize
    const filename = `${filenameStart} (${this.datePipe.transform(
      new Date(),
      'yyyyMMdd HHmmss'
    )}).xls`;
    const file = b64toBlob(response, 'application/octet-stream');

    const fileURL = window.URL.createObjectURL(file);
    const a = document.createElement('a');

    a.href = fileURL;
    a.target = '_blank';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  }
}
