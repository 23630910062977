import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CoreUrlEnums } from 'src/app/core/enums/url-paths.enum';
import {
  DismissedPWAClicked,
  GoogleAnalyticsActions,
  InstallPWAClicked,
} from 'src/app/core/store/actions/google-analytics.actions';
import {
  ChangePWA,
  PWAStateAction,
} from 'src/app/core/store/actions/pwa.action';
import { RouterGo } from 'src/app/core/store/actions/router.action';

@Component({
  selector: 'app-a2hs',
  templateUrl: './a2hs.component.html',
  styleUrls: ['./a2hs.component.scss'],
})
export class A2hsComponent {
  deferredPrompt: any;

  showPanel = false;
  isIos = false;

  @Input()
  set disablePWA(disable: boolean) {
    if (disable ?? true) {
      return;
    }

    this.showPWAWindow();
  }

  @Output()
  redirectToSupport: EventEmitter<RouterGo> = new EventEmitter<RouterGo>();

  @Output()
  skipPWA: EventEmitter<PWAStateAction> = new EventEmitter<PWAStateAction>();

  @Output()
  decisionPWA: EventEmitter<GoogleAnalyticsActions> = new EventEmitter<
    GoogleAnalyticsActions
  >();

  checkIfItIsRunningInStandaloneMode: () => boolean = () =>
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator['standalone'];

  checkIfIsIosOrMac: () => boolean = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  showPWAWindow() {
    'onbeforeinstallprompt' in window
      ? this.handleSupportedPWAApp()
      : this.handleIosDevicesNotInStandaloneMode();
  }

  handleSupportedPWAApp() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.showPanel = true;
    });
  }

  handleIosDevicesNotInStandaloneMode() {
    if (
      !this.checkIfItIsRunningInStandaloneMode() &&
      this.checkIfIsIosOrMac()
    ) {
      this.showPanel = true;
      this.isIos = true;
    }
  }

  addToHomeScreen() {
    this.showPanel = false;
    this.deferredPrompt.prompt();

    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        this.decisionPWA.emit(new InstallPWAClicked());
      } else {
        this.decisionPWA.emit(new DismissedPWAClicked());
      }
      this.deferredPrompt = null;
    });
  }

  showInstructions() {
    const action = new RouterGo({
      path: [CoreUrlEnums.SUPPORT],
    });

    this.showPanel = false;
    this.redirectToSupport.emit(action);
  }

  hide() {
    this.showPanel = false;
  }

  disablePWAPanel() {
    this.showPanel = false;
    this.skipPWA.emit(new ChangePWA(true));
  }
}
