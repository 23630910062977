import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { TypesService } from 'src/app/core/services/api/types/types.service';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  CleanOperationWorkTypesState,
  LoadedOperationWorkTypesFail,
  LoadedOperationWorkTypesSuccess,
  OperationWorkTypesActionTypes,
} from 'src/app/dictionaries/store/actions/operation-work-types.action';

@Injectable()
export class OperationWorkTypesEffects {
  constructor(private actions$: Actions, private typesService: TypesService) {}

  public loadOperationWorkTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationWorkTypesActionTypes.LoadOperationWorkTypes),
      switchMap(() =>
        this.typesService.getOperationWorkTypes().pipe(
          map(
            (commonTypes: DictionaryI[]) =>
              new LoadedOperationWorkTypesSuccess(commonTypes)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadedOperationWorkTypesFail(error))
          )
        )
      )
    )
  );

  public handleLoadedOperationWorkTypesFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationWorkTypesActionTypes.LoadedOperationWorkTypesFail),
      map(() => new CleanOperationWorkTypesState())
    )
  );
}
