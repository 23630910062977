<div class="btn-group float-right" dropdown [isDisabled]="disabled">
  <button
    id="analysis-option-button"
    dropdownToggle
    type="button"
    class="btn dropdown-toggle bg-light"
  >
    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
  </button>
  <ul
    id="analysis-option-dropdown"
    class="dropdown-menu dropdown-menu-right border-0 shadow"
    *dropdownMenu
    role="menu"
  >
    <li role="menuitem">
      <div class="dropdown-item-header bg-light mb-2">
        <span>{{ 'dropdown-menu.title' | translate }}</span>
      </div>
    </li>

    <app-actions-dropdown-single-assignment
      *ngIf="!!assignmentResource"
      [assignmentResource]="assignmentResource"
      (goToChatInMeps)="onGoToChatInMeps($event)"
      (goToMeps)="onGoToMeps($event)"
      (exportToExcel)="onExportToExcel([$event])"
      (exportToSimpleExcel)="onExportToSimpleExcel([$event])"
      (remove)="onRemove($event)"
      (close)="onClose($event)"
      (reopen)="onReopen($event)"
    >
    </app-actions-dropdown-single-assignment>

    <app-actions-dropdown-multiple-assignments
      *ngIf="!!selectedAssignmentResourceList"
      [selectedAssignmentResourceList]="selectedAssignmentResourceList"
      [assignmentResourceList]="assignmentResourceList"
      (exportToExcel)="onExportToExcel($event)"
      (exportToSimpleExcel)="onExportToSimpleExcel($event)"
      (exportAllToExcel)="onExportAllToExcel($event)"
      (exportAllToSimpleExcel)="onExportAllToSimpleExcel($event)"
      (goToMeps)="onGoToMeps($event)"
      (goToCostSummary)="onGoToCostSummary($event)"
      (goToCompareAssignment)="onGoToCompareAssignments($event)"
      (goToLog)="onGoToLog($event)"
    >
    </app-actions-dropdown-multiple-assignments>
    <ng-content></ng-content>
  </ul>
</div>
