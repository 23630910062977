import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  PingActionTypes,
  PingFailed,
  PingSuccess,
} from 'src/app/auth/store/actions/ping.action';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PingService } from 'src/app/core/services/api/ping/ping.service';

@Injectable()
export class PingEffects {
  constructor(private pingService: PingService, private actions$: Actions) {}

  public ping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PingActionTypes.Ping),
      switchMap((_) =>
        this.pingService.ping().pipe(
          map((_) => new PingSuccess()),
          catchError((error: ErrorResponseWithIdI) => of(new PingFailed(error)))
        )
      )
    )
  );
}
