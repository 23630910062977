import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { getDecisionTypesStateSelector } from 'src/app/dictionaries/store';
import { LoadDecisionTypes } from 'src/app/dictionaries/store/actions/decision-types.action';
import { DecisionTypesReducerState } from 'src/app/dictionaries/store/reducers/decision-types.reducer';

@Injectable()
export class DecisionTypesGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<DecisionTypesReducerState>) {}

  canActivate() {
    return this.waitForDecisionTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  canActivateChild() {
    return this.waitForDecisionTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForDecisionTypesToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getDecisionTypesStateSelector),
      tap((decisionTypesState: DecisionTypesReducerState) => {
        if (
          !decisionTypesState.loaded &&
          !decisionTypesState.loading &&
          !decisionTypesState.error
        ) {
          this.store.dispatch(new LoadDecisionTypes());
        }
      }),
      filter(
        (decisionTypesState: DecisionTypesReducerState) =>
          decisionTypesState.loaded || !!decisionTypesState.error
      ),
      map((decisionTypesState: DecisionTypesReducerState) => {
        if (!!decisionTypesState.error) {
          throw new Error(decisionTypesState.error.error.message);
        } else {
          return decisionTypesState.loaded;
        }
      }),
      take(1)
    );
  }
}
