import { Action } from '@ngrx/store';
import { UserResourceI } from 'src/app/core/models/user/user-resource.interface';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum CompanyUsersActionTypes {
  LoadCompanyUsers = '[CompanyUsers] Load Company Users',
  LoadedCompanyUsersSuccess = '[CompanyUsers] Loaded Company Users',
  LoadedCompanyUsersFail = '[CompanyUsers] Fail To Load Company Users',

  CleanCompanyUsersState = '[CompanyUsers] Clean',
}

@ShowLoader()
export class LoadCompanyUsers implements Action {
  readonly type = CompanyUsersActionTypes.LoadCompanyUsers;

  constructor(public payload?: any) {}
}

@HideLoader(CompanyUsersActionTypes.LoadCompanyUsers)
export class LoadedCompanyUsersSuccess implements Action {
  readonly type = CompanyUsersActionTypes.LoadedCompanyUsersSuccess;

  constructor(public payload: UserResourceI[]) {}
}

@HideLoader(CompanyUsersActionTypes.LoadCompanyUsers)
export class LoadedCompanyUsersFail implements Action {
  readonly type = CompanyUsersActionTypes.LoadedCompanyUsersFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanCompanyUsersState implements Action {
  readonly type = CompanyUsersActionTypes.CleanCompanyUsersState;

  constructor(public payload?: string) {}
}

export type CompanyUsersActions =
  | LoadCompanyUsers
  | LoadedCompanyUsersSuccess
  | LoadedCompanyUsersFail
  | CleanCompanyUsersState;
