import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectI } from 'src/app/core/models/select.interface';

@Component({
  selector: 'app-searchable-dropdown',
  templateUrl: './dropdown-searchable.component.html',
  styleUrls: ['./dropdown-searchable.component.scss'],
})
export class DropdownSearchableComponent implements OnInit {
  @Input()
  placeHolder: string;

  @Input()
  searchable = true;

  @Input()
  clearable = true;

  @Input()
  disabled = false;

  @Input()
  options: SelectI[];

  @Input()
  selectedOption: SelectI | SelectI[];

  @Input()
  small = false;

  @Input()
  multiple = false;

  @Output()
  change: EventEmitter<string | number> = new EventEmitter<string | number>();

  @Output()
  changeMulti: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output()
  clear: EventEmitter<null> = new EventEmitter<null>();

  constructor() {}

  ngOnInit() {}

  onChange(filterValue: SelectI | SelectI[]) {
    if (!filterValue) {
      return;
    }

    if (Array.isArray(filterValue)) {
      const filterValues = filterValue
        .filter((x) => !!x.id)
        .map((x) => x.id.toString());
      this.changeMulti.emit(filterValues);
    } else {
      this.change.emit(filterValue.id);
    }
  }

  onClear() {
    this.clear.emit(null);
  }
}
