import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LanguageEnum } from 'src/app/core/enums/language.enum';
import { LanguageState } from 'src/app/core/store/reducers/language.reducer';
import { getSelectedLanguageSelector } from 'src/app/core/store/selectors/language.selectors';

@Pipe({
  name: 'ago',
})
export class AgoPipe implements PipeTransform {
  constructor(private store: Store<LanguageState>) {}

  transform(value: any, ...args: any[]): Observable<string> {
    return this.store.pipe(
      select(getSelectedLanguageSelector),
      map((language: LanguageEnum) =>
        this.getAgoValueBaseOnLanguage(value, language)
      )
    );
  }

  getAgoValueBaseOnLanguage(value: number, language: string): string {
    if (!value || !language) {
      return '-';
    }
    if (language === LanguageEnum.EN) {
      return this.getAgoValue(value, 'en');
    }
    if (language === LanguageEnum.SE) {
      return this.getAgoValue(value, 'sv');
    }
  }

  getAgoValue(value: number, locale: string): string {
    return moment(value)
      .locale(locale)
      .fromNow();
  }
}
