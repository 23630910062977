import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AssignmentNotFromMepsDeletedTemplateComponent } from 'src/app/analysis/modules/start/modals/containers/assignment-not-from-meeps-deleted-template/assignment-not-from-meps-deleted-template.component';
import { AssignmentsService } from 'src/app/core/services/api/assignments/assignments.service';
import {
  AssignmentChangeStatusActionTypes,
  CloseAssignment,
  CloseAssignmentFail,
  CloseAssignmentSuccess,
  RemoveAssignment,
  RemoveAssignmentFail,
  RemoveAssignmentSuccess,
  ReopenAssignment,
  ReopenAssignmentFail,
  ReopenAssignmentSuccess,
} from 'src/app/core/store/actions/assignment-change-status.action';
import { LoadAssignment } from 'src/app/core/store/actions/assignments.action';
import { ShowModal } from 'src/app/core/store/actions/modal.action';

@Injectable()
export class AssignmentChangeStatusEffects {
  constructor(
    private actions$: Actions,
    private assignmentsService: AssignmentsService
  ) {}

  
  public closeAssignment$ = createEffect(() => this.actions$.pipe(
    ofType(AssignmentChangeStatusActionTypes.CloseAssignment),
    switchMap((action: CloseAssignment) =>
      this.assignmentsService.closeAssignment(action.payload).pipe(
        map((assignmentId: number) => new CloseAssignmentSuccess(assignmentId)),
        catchError((error) => of(new CloseAssignmentFail(error)))
      )
    )
  ));

  
  public reopenAssignment$ = createEffect(() => this.actions$.pipe(
    ofType(AssignmentChangeStatusActionTypes.ReopenAssignment),
    switchMap((action: ReopenAssignment) =>
      this.assignmentsService.reopenAssignment(action.payload).pipe(
        map(
          (assignmentId: number) => new ReopenAssignmentSuccess(assignmentId)
        ),
        catchError((error) => of(new ReopenAssignmentFail(error)))
      )
    )
  ));

  
  public removeAssignment$ = createEffect(() => this.actions$.pipe(
    ofType(AssignmentChangeStatusActionTypes.RemoveAssignment),
    switchMap((action: RemoveAssignment) =>
      this.assignmentsService
        .removeAssignment(action.payload.assignmentId)
        .pipe(
          map(
            (assignmentId: number) =>
              new RemoveAssignmentSuccess({
                assignmentId: assignmentId,
                wasCreatedInUw: action.payload.wasCreatedInUw,
              })
          ),
          catchError((error) => of(new RemoveAssignmentFail(error)))
        )
    )
  ));

  
  public handleStatusChange$ = createEffect(() => this.actions$.pipe(
    ofType(
      AssignmentChangeStatusActionTypes.CloseAssignmentSuccess,
      AssignmentChangeStatusActionTypes.RemoveAssignmentSuccess,
      AssignmentChangeStatusActionTypes.ReopenAssignmentSuccess
    ),
    switchMap(
      (
        action:
          | CloseAssignmentSuccess
          | RemoveAssignmentSuccess
          | ReopenAssignmentSuccess
      ) => {
        if (
          action.type ===
          AssignmentChangeStatusActionTypes.RemoveAssignmentSuccess
        ) {
          let removeAssignmentActions: Action[] = [
            new LoadAssignment(action.payload.assignmentId),
          ];
          if (!action.payload.wasCreatedInUw) {
            removeAssignmentActions = [
              ...removeAssignmentActions,
              new ShowModal({
                content: AssignmentNotFromMepsDeletedTemplateComponent,
              }),
            ];
          }

          return removeAssignmentActions;
        } else {
          return of(new LoadAssignment(action.payload));
        }
      }
    )
  ));
}
