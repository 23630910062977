<div class="bottom-bar w-100 pt-2">
  <div
    class="bottom-bar-content row no-gutters justify-content-between"
    [ngClass]="{
      'bottom-bar-content': !isExpanded,
      'bottom-bar-content-expanded': isExpanded
    }"
  >
    <div
      class="col-2"
      [ngClass]="{ 'col-6': isExpanded }"
      (click)="isExpanded = !isExpanded"
    >
      <div class="center">
        <span
          class="pointer text-center"
          [ngClass]="{ 'ml-3 ml-sm-0': !isExpanded }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="menu-icon icon-fast-forward"
          >
            <path
              class="secondary"
              d="M1 5a1 1 0 0 1 1.5-.86l12 7a1 1 0 0 1 0 1.72l-12 7A1 1 0 0 1 1 19V5z"
            />
            <path
              class="primary"
              d="M9 5a1 1 0 0 1 1.5-.86l12 7a1 1 0 0 1 0 1.72l-12 7A1 1 0 0 1 9 19V5z"
            />
          </svg>

          <ng-container *ngTemplateOutlet="arrow"> </ng-container>
        </span>

        <ul class="mt-4" *ngIf="isExpanded">
          <li
            class="pointer"
            (click)="goToOverview()"
            [translate]="'menu.overview'"
          ></li>
          <li class="pointer" (click)="goToMakeDecision()">
            <span class="mr-1" [translate]="'phases.makeDecision'"></span>
            <span
              class="count-widget ml-4"
              *ngIf="makeDecisionNumber$ | async as makeDecisionCount"
              >{{ makeDecisionCount }}</span
            >
          </li>
          <li
            class="pointer"
            (click)="goToWatchlist()"
            [translate]="'phases.watchlist'"
          ></li>
          <hr />

          <li
            class="pointer"
            (click)="goToPreparation()"
            [translate]="'phases.preparation'"
          ></li>
          <li
            class="pointer"
            (click)="goToOrdering()"
            [translate]="'phases.ordering'"
          ></li>
          <li
            class="pointer"
            (click)="goToProduction()"
            [translate]="'phases.production'"
          ></li>
          <li
            class="pointer"
            (click)="goToClosing()"
            [translate]="'phases.closing'"
          ></li>
        </ul>
      </div>
    </div>

    <div
      class="col-2"
      [ngClass]="{ 'col-6': isExpanded }"
      (click)="isExpanded = !isExpanded"
    >
      <div class="center">
        <span class="pointer" [ngClass]="{ 'ml-3 ml-sm-0': !isExpanded }">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="menu-icon icon-trending-up"
          >
            <path
              class="primary"
              d="M3.7 20.7a1 1 0 1 1-1.4-1.4l6-6a1 1 0 0 1 1.4 0l3.3 3.29 4.3-4.3a1 1 0 0 1 1.4 1.42l-5 5a1 1 0 0 1-1.4 0L9 15.4l-5.3 5.3z"
            />
            <path
              class="secondary"
              d="M16.59 8l-2.3-2.3A1 1 0 0 1 15 4h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1.7.7L18 9.42l-4.3 4.3a1 1 0 0 1-1.4 0L9 10.4l-5.3 5.3a1 1 0 1 1-1.4-1.42l6-6a1 1 0 0 1 1.4 0l3.3 3.3L16.59 8z"
            />
          </svg>

          <ng-container *ngTemplateOutlet="arrow"> </ng-container>
        </span>

        <ul class="mt-4" *ngIf="isExpanded">
          <li
            class="pointer"
            (click)="goToSearch()"
            [translate]="'numbers.menu.search'"
          ></li>
          <li
            class="pointer"
            (click)="goToDashboard()"
            [translate]="'numbers.menu.dashboard'"
          ></li>
        </ul>
      </div>
    </div>

    <div
      class="col-2 text-center pointer"
      (click)="goToProjects()"
      *ngIf="!isExpanded"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="mr-3 menu-icon icon-collection"
        slot="icon"
      >
        <rect width="20" height="12" x="2" y="10" class="primary" rx="2"></rect>
        <path
          class="secondary"
          d="M20 8H4c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2zm-2-4H6c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2z"
        ></path>
      </svg>
    </div>

    <div
      class="col-2 text-center pointer"
      (click)="goToAdministration()"
      *ngIf="!isExpanded"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="menu-icon icon-mood-happy"
      >
        <circle cx="12" cy="12" r="10" class="primary" />
        <path
          class="secondary"
          d="M6.8 14h10.4a1 1 0 0 1 .86 1.5 7 7 0 0 1-12.12 0A1 1 0 0 1 6.8 14zm1.7-3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
        />
      </svg>
    </div>

    <div
      class="col-2 text-center pointer"
      (click)="goToSupport()"
      *ngIf="!isExpanded"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="menu-icon icon-help"
      >
        <path class="primary" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20z" />
        <path
          class="secondary"
          d="M12 19.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.5a1 1 0 0 1-2 0v-1.41a1 1 0 0 1 .55-.9L14 10.5C14.64 10.08 15 9.53 15 9c0-1.03-1.3-2-3-2-1.35 0-2.49.62-2.87 1.43a1 1 0 0 1-1.8-.86C8.05 6.01 9.92 5 12 5c2.7 0 5 1.72 5 4 0 1.3-.76 2.46-2.05 3.24L13 13.2V14z"
        />
      </svg>
    </div>
  </div>
</div>

<ng-template #arrow>
  <ng-template [ngIf]="!isExpanded" [ngIfElse]="arrowDown">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="menu-icon icon-cheveron-up"
    >
      <path
        class="secondary"
        fill-rule="evenodd"
        d="M8.7 13.7a1 1 0 1 1-1.4-1.4l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 10.42l-3.3 3.3z"
      ></path>
    </svg>
  </ng-template>

  <ng-template #arrowDown>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="menu-icon ml-1 icon-cheveron-down"
    >
      <path
        class="secondary"
        fill-rule="evenodd"
        d="M15.3 10.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
      ></path>
    </svg>
  </ng-template>
</ng-template>
