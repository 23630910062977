<div class="output-element">
  <div class="output-element-label" [translate]="label"></div>
  <div
    class="output-element-value"
    (click)="onClick(outputType, value)"
    [ngClass]="{ pointer: !!outputType }"
  >
    {{ !!value ? value : '-' }}
  </div>

  <div
    class="output-additional-info pointer"
    *ngFor="let a of additionalInfo"
    (click)="onClick(a.type, a.value)"
  >
    {{ a.value }}
  </div>
</div>
