import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AgreementI } from 'src/app/core/models/agreements.interface';
import { environment } from 'src/environments/environment';

@Injectable()
export class AgreementsService {
  private agreementsUrl = environment.url + 'api/v1/agreement/';
  private agreementsWithCidUrl = this.agreementsUrl + 'with-cid/';

  constructor(private http: HttpClient) {}

  getAgreementsWithCid(): Observable<AgreementI[]> {
    return this.http.get<AgreementI[]>(this.agreementsWithCidUrl);
  }

  createAgreement(agreement: AgreementI): Observable<number> {
    return this.http.post<number>(this.agreementsUrl, { ...agreement });
  }

  editAgreement(agreement: AgreementI): Observable<void> {
    return this.http.put<void>(this.agreementsUrl, { ...agreement });
  }
}
