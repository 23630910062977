import { createSelector } from '@ngrx/store';
import { SelectI } from 'src/app/core/models/select.interface';
import { getSelectedLanguageSelector } from 'src/app/core/store/selectors/language.selectors';
import { mapDictionaryToSelectForGivenLanguage } from 'src/app/core/utils/dictionary-utils';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import { getErrorReasonTypesStateSelector } from 'src/app/dictionaries/store/index';
import {
  getAllErrorReasonTypes,
  getErrorReasonTypesEntities,
  getErrorReasonTypesError,
  getErrorReasonTypesIds,
  getErrorReasonTypesLoaded,
  getErrorReasonTypesLoading,
  getErrorReasonTypesTotal,
} from 'src/app/dictionaries/store/reducers/error-reason-types.reducer';

export const getErrorReasonTypesIdsSelector = createSelector(
  getErrorReasonTypesStateSelector,
  getErrorReasonTypesIds
);

export const getErrorReasonTypesEntitiesSelector = createSelector(
  getErrorReasonTypesStateSelector,
  getErrorReasonTypesEntities
);

export const getAllErrorReasonTypesTypesSelector = createSelector(
  getErrorReasonTypesStateSelector,
  getAllErrorReasonTypes
);

export const getErrorReasonTypesTotalSelector = createSelector(
  getErrorReasonTypesStateSelector,
  getErrorReasonTypesTotal
);

export const getErrorReasonTypesLoadingSelector = createSelector(
  getErrorReasonTypesStateSelector,
  getErrorReasonTypesLoading
);

export const getErrorReasonTypesLoadedSelector = createSelector(
  getErrorReasonTypesStateSelector,
  getErrorReasonTypesLoaded
);

export const getErrorReasonTypesErrorSelector = createSelector(
  getErrorReasonTypesStateSelector,
  getErrorReasonTypesError
);

export const getErrorReasonTypesEntitiesForSelectedLanguageSelector = createSelector(
  getAllErrorReasonTypesTypesSelector,
  getSelectedLanguageSelector,
  (errorReasonTypes: DictionaryI[], selectedLanguage: string): SelectI[] =>
    mapDictionaryToSelectForGivenLanguage(errorReasonTypes, selectedLanguage)
);
