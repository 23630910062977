import { Injectable } from '@angular/core';
import { UpdateSessionStorageI } from 'src/app/core/models/session-storage.interface';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  rootItemNameInSessionStorage = 'go-platform-';

  constructor() {}

  getItem(key: string): any {
    const item = sessionStorage.getItem(
      this.rootItemNameInSessionStorage + key
    );

    return !!item ? JSON.parse(item) : item;
  }

  update({ key, value }: UpdateSessionStorageI): void {
    const stringifyValue = JSON.stringify(value);

    sessionStorage.setItem(
      this.rootItemNameInSessionStorage + key,
      stringifyValue
    );
  }
}
