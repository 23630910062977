import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AssetService } from 'src/app/core/services/asset/asset.service';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  CleanErrorReasonTypesState,
  ErrorReasonTypesActionTypes,
  LoadedErrorReasonTypesFail,
  LoadedErrorReasonTypesSuccess,
} from 'src/app/dictionaries/store/actions/error-reason-types.action';

@Injectable()
export class ErrorReasonTypesEffects {
  
  public loadErrorReasonTypes$ = createEffect(() => this.actions$.pipe(
    ofType(ErrorReasonTypesActionTypes.LoadErrorReasonTypes),
    switchMap(() =>
      this.assetService.getErrorReasonTypes().pipe(
        map(
          (ErrorReasonTypes: DictionaryI[]) =>
            new LoadedErrorReasonTypesSuccess(ErrorReasonTypes)
        ),
        catchError((error: ErrorResponseWithIdI) =>
          of(new LoadedErrorReasonTypesFail(error))
        )
      )
    )
  ));

  
  public handleLoadedErrorReasonTypesFail$ = createEffect(() => this.actions$.pipe(
    ofType(ErrorReasonTypesActionTypes.LoadedErrorReasonTypesFail),
    map(() => new CleanErrorReasonTypesState())
  ));

  constructor(private actions$: Actions, private assetService: AssetService) {}
}
