import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/guards/auth.guard';
import { ConfirmGuard } from 'src/app/auth/guards/confirm.guard';
import { UserGuard } from 'src/app/auth/guards/user.guard';
import { MessagesPanelComponent } from 'src/app/core/containers/messages-panel/messages-panel.component';
import { SupportComponent } from 'src/app/core/containers/support/support.component';
import { CoreUrlEnums } from 'src/app/core/enums/url-paths.enum';
import { AlertMessagesGuard } from 'src/app/core/guards/alert-messeges.guard';
import { AssignmentCountGuard } from 'src/app/core/guards/assignment-count.guard';
import { SupportDeactivateGuard } from 'src/app/core/guards/support-deactivate.guard';
import { SupportGuard } from 'src/app/core/guards/support.guard';

// TODO: Add not found page
export const appRoutes: Routes = [
  { path: '', redirectTo: CoreUrlEnums.ASSIGNMENTS, pathMatch: 'full' },
  {
    path: CoreUrlEnums.GO_MESSAGE_PANEL,
    canActivate: [
      AuthGuard,
      UserGuard,
      ConfirmGuard,
      AlertMessagesGuard,
      AssignmentCountGuard,
    ],
    component: MessagesPanelComponent,
  },
  {
    path: CoreUrlEnums.SUPPORT,
    canActivate: [SupportGuard, AssignmentCountGuard],
    canDeactivate: [SupportDeactivateGuard],
    component: SupportComponent,
  },
  {
    path: CoreUrlEnums.ASSIGNMENTS,
    canActivate: [
      AuthGuard,
      UserGuard,
      ConfirmGuard,
      AlertMessagesGuard,
      AssignmentCountGuard,
    ],
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('src/app/assignment/assignment.module').then(
        (m) => m.AssignmentModule
      ),
  },
  {
    path: CoreUrlEnums.NUMBERS,
    canActivate: [
      AuthGuard,
      UserGuard,
      ConfirmGuard,
      AlertMessagesGuard,
      AssignmentCountGuard,
    ],
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('src/app/numbers/numbers.module').then((m) => m.NumbersModule),
  },
  {
    path: CoreUrlEnums.ANALYSIS,
    canActivate: [
      AuthGuard,
      UserGuard,
      ConfirmGuard,
      AlertMessagesGuard,
      AssignmentCountGuard,
    ],
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('src/app/analysis/analysis.module').then((m) => m.AnalysisModule),
  },
  {
    path: CoreUrlEnums.ADMINISTRATION,
    canActivate: [
      AuthGuard,
      UserGuard,
      ConfirmGuard,
      AlertMessagesGuard,
      AssignmentCountGuard,
    ],
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('src/app/administration/administration.module').then(
        (m) => m.AdministrationModule
      ),
  },
  {
    path: CoreUrlEnums.PROJECTS,
    canActivate: [
      AuthGuard,
      UserGuard,
      ConfirmGuard,
      AlertMessagesGuard,
      AssignmentCountGuard,
    ],
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('src/app/projects/projects.module').then((m) => m.ProjectsModule),
  },
  { path: '**', redirectTo: CoreUrlEnums.ASSIGNMENTS },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
