import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import {
  assignmentTypesReducer,
  AssignmentTypesReducerState,
} from 'src/app/dictionaries/store/reducers/assignment-types.reducer';
import {
  clientAssignmentTypesReducer,
  ClientAssignmentTypesReducerState,
} from 'src/app/dictionaries/store/reducers/client-assignment-types.reducer';
import {
  decisionTypesReducer,
  DecisionTypesReducerState,
} from 'src/app/dictionaries/store/reducers/decision-types.reducer';
import {
  errorExtraTypesReducer,
  ErrorExtraTypesReducerState,
} from 'src/app/dictionaries/store/reducers/error-extra-types.reducer';
import {
  errorReasonTypesReducer,
  ErrorReasonTypesReducerState,
} from 'src/app/dictionaries/store/reducers/error-reason-types.reducer';
import {
  establishmentTypesReducer,
  EstablishmentTypesReducerState,
} from 'src/app/dictionaries/store/reducers/establishment-types.reducer';
import {
  eventTypesReducer,
  EventTypesReducerState,
} from 'src/app/dictionaries/store/reducers/event-types.reducer';
import {
  operationWorkTypesReducer,
  OperationWorkTypesReducerState,
} from 'src/app/dictionaries/store/reducers/operation-work-types.reducer';
import {
  realEstateTypesReducer,
  RealEstateTypesReducerState,
} from 'src/app/dictionaries/store/reducers/real-estate-types.reducer';
import {
  spaceTypesReducer,
  SpaceTypesReducerState,
} from 'src/app/dictionaries/store/reducers/space-types.reducer';
import {
  tradeTypesReducer,
  TradeTypesReducerState,
} from 'src/app/dictionaries/store/reducers/trade-types.reducer';

export interface DictionariesReducerState {
  realEstateTypes: RealEstateTypesReducerState;
  establishmentTypes: EstablishmentTypesReducerState;
  spaceTypes: SpaceTypesReducerState;
  assignmentTypes: AssignmentTypesReducerState;
  clientAssignmentTypes: ClientAssignmentTypesReducerState;
  eventTypes: EventTypesReducerState;
  decisionTypes: DecisionTypesReducerState;
  errorReason: ErrorReasonTypesReducerState;
  errorExtra: ErrorExtraTypesReducerState;
  operationWork: OperationWorkTypesReducerState;
  trade: TradeTypesReducerState;
}

export const dictionariesReducer: ActionReducerMap<DictionariesReducerState> = {
  realEstateTypes: realEstateTypesReducer,
  establishmentTypes: establishmentTypesReducer,
  spaceTypes: spaceTypesReducer,
  assignmentTypes: assignmentTypesReducer,
  clientAssignmentTypes: clientAssignmentTypesReducer,
  eventTypes: eventTypesReducer,
  decisionTypes: decisionTypesReducer,
  errorReason: errorReasonTypesReducer,
  errorExtra: errorExtraTypesReducer,
  operationWork: operationWorkTypesReducer,
  trade: tradeTypesReducer,
};

export const getDictionariesStateSelector = createFeatureSelector<DictionariesReducerState>(
  'dict'
);

export const getRealEstateTypesStateSelector = createSelector(
  getDictionariesStateSelector,
  (dictionariesReducerState) => dictionariesReducerState.realEstateTypes
);

export const getEstablishmentTypesStateSelector = createSelector(
  getDictionariesStateSelector,
  (dictionariesReducerState) => dictionariesReducerState.establishmentTypes
);

export const getSpaceTypesStateSelector = createSelector(
  getDictionariesStateSelector,
  (dictionariesReducerState) => dictionariesReducerState.spaceTypes
);

export const getAssignmentTypesStateSelector = createSelector(
  getDictionariesStateSelector,
  (dictionariesReducerState) => dictionariesReducerState.assignmentTypes
);

export const getClientAssignmentTypesStateSelector = createSelector(
  getDictionariesStateSelector,
  (dictionariesReducerState) => dictionariesReducerState.clientAssignmentTypes
);

export const getEventTypesStateSelector = createSelector(
  getDictionariesStateSelector,
  (dictionariesReducerState) => dictionariesReducerState.eventTypes
);

export const getDecisionTypesStateSelector = createSelector(
  getDictionariesStateSelector,
  (dictionariesReducerState) => dictionariesReducerState.decisionTypes
);

export const getOperationWorkTypesStateSelector = createSelector(
  getDictionariesStateSelector,
  (dictionariesReducerState) => dictionariesReducerState.operationWork
);

export const getErrorReasonTypesStateSelector = createSelector(
  getDictionariesStateSelector,
  (dictionariesReducerState) => dictionariesReducerState.errorReason
);

export const getErrorExtraTypesStateSelector = createSelector(
  getDictionariesStateSelector,
  (dictionariesReducerState) => dictionariesReducerState.errorExtra
);

export const getTradeTypesStateSelector = createSelector(
  getDictionariesStateSelector,
  (dictionariesReducerState) => dictionariesReducerState.trade
);
