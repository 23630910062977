import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { LoadAssignmentCount } from 'src/app/core/store/actions/assignment-count.action';
import { AssignmentsReducerState } from 'src/app/core/store/reducers/assignments.reducer';
import { getAssignmentCountLoadedWithLoadedUserSelector } from 'src/app/core/store/selectors/assignment-count.selectors';

@Injectable()
export class AssignmentCountGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<AssignmentsReducerState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  checkIfCanActivate(): Observable<boolean> {
    return this.waitForCountToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForCountToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getAssignmentCountLoadedWithLoadedUserSelector),
      tap(
        (isLoaded: {
          assignmentCountLoaded: boolean;
          userLoaded: boolean;
          userLoading: boolean;
        }) => {
          if (!isLoaded.assignmentCountLoaded && isLoaded.userLoaded) {
            this.store.dispatch(new LoadAssignmentCount());
          }
        }
      ),
      map(
        (isLoaded: {
          assignmentCountLoaded: boolean;
          userLoaded: boolean;
          userLoading: boolean;
        }) =>
          isLoaded.assignmentCountLoaded ||
          (!isLoaded.userLoaded && !isLoaded.userLoading)
      ),
      filter((loaded) => loaded),
      take(1)
    );
  }
}
