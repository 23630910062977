import { Directive, ElementRef, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HideModal } from 'src/app/core/store/actions/modal.action';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';
import { ComponentCanDeactivate } from 'src/app/shared/models/can-deactivate.interface';

@Directive()
export class BaseTemplateComponent
  extends StoreDispatcher<any>
  implements OnInit, ComponentCanDeactivate {
  private eRef: ElementRef;

  constructor(store: Store<any>, eRef: ElementRef) {
    super(store);
    this.eRef = eRef;
  }

  ngOnInit() {}

  dispatchActionAndCloseModal(action: Action) {
    this.dispatchAction(action);
    this.closeModal();
  }

  dispatchActionsAndCloseModal(actions: Action[]) {
    this.dispatchActions(actions);
    this.closeModal();
  }

  closeModal() {
    this.dispatchAction(new HideModal());
  }

  checkIfShouldCloseModal(event) {
    if (
      !this.eRef.nativeElement.contains(event.target) &&
      event.target.classList.contains('modal')
    ) {
      this.closeModal();
    }
  }

  shouldHighlightInvalidField(
    fieldName: string,
    formGroup: FormGroup
  ): boolean {
    return (
      formGroup.controls[fieldName].invalid &&
      formGroup.controls[fieldName].dirty &&
      formGroup.controls[fieldName].touched
    );
  }

  canDeactivate(): boolean | Observable<boolean> {
    return true;
  }
}
