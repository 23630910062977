import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import {
  AssignmentCountActionTypes,
  AssignmentsCountActions,
} from 'src/app/core/store/actions/assignment-count.action';

export interface AssignmentCountReducerState {
  makeDecisionNumberFound: number;
  watchlistNumberFound: number;
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialAssignmentCountReducerState: AssignmentCountReducerState = {
  makeDecisionNumberFound: 0,
  watchlistNumberFound: 0,

  loading: false,
  loaded: false,
  error: null,
};

export function assignmentCountReducer(
  state: AssignmentCountReducerState = initialAssignmentCountReducerState,
  action: AssignmentsCountActions
): AssignmentCountReducerState {
  switch (action.type) {
    // Load single Assignment
    case AssignmentCountActionTypes.LoadAssignmentCount: {
      return {
        ...state,
        loading: true,
      };
    }

    case AssignmentCountActionTypes.LoadedAssignmentCountSuccess: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        loading: false,
      };
    }

    case AssignmentCountActionTypes.LoadedAssignmentCountFail: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case AssignmentCountActionTypes.CleanAssignmentCountState: {
      return {
        ...initialAssignmentCountReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getMakeDecisionCount = (state: AssignmentCountReducerState) =>
  state.makeDecisionNumberFound;

export const getWatchlistCount = (state: AssignmentCountReducerState) =>
  state.watchlistNumberFound;

export const getAssignmentCountLoading = (state: AssignmentCountReducerState) =>
  state.loading;
export const getAssignmentCountLoaded = (state: AssignmentCountReducerState) =>
  state.loaded;
export const getAssignmentCountError = (state: AssignmentCountReducerState) =>
  state.error;
