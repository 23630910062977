import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

export enum ErrorReasonTypesActionTypes {
  LoadErrorReasonTypes = '[ErrorReasonTypes] Load Error Reason Types',
  LoadedErrorReasonTypesSuccess = '[ErrorReasonTypes] Loaded Error Reason Types',
  LoadedErrorReasonTypesFail = '[ErrorReasonTypes] Fail To Load Error Reason Types',

  CleanErrorReasonTypesState = '[ErrorReasonTypes] Clean',
}

@ShowLoader()
export class LoadErrorReasonTypes implements Action {
  readonly type = ErrorReasonTypesActionTypes.LoadErrorReasonTypes;

  constructor(public payload?: any) {}
}

@HideLoader(ErrorReasonTypesActionTypes.LoadErrorReasonTypes)
export class LoadedErrorReasonTypesSuccess implements Action {
  readonly type = ErrorReasonTypesActionTypes.LoadedErrorReasonTypesSuccess;

  constructor(public payload: DictionaryI[]) {}
}

@HideLoader(ErrorReasonTypesActionTypes.LoadErrorReasonTypes)
export class LoadedErrorReasonTypesFail implements Action {
  readonly type = ErrorReasonTypesActionTypes.LoadedErrorReasonTypesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanErrorReasonTypesState implements Action {
  readonly type = ErrorReasonTypesActionTypes.CleanErrorReasonTypesState;
}

export type ErrorReasonTypesAction =
  | LoadErrorReasonTypes
  | LoadedErrorReasonTypesSuccess
  | LoadedErrorReasonTypesFail
  | CleanErrorReasonTypesState;
