import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Action } from '@ngrx/store';
import { BaseTemplateComponent } from 'src/app/core/modal-templates/base-template/base-template.component';
import { ShowModal } from 'src/app/core/store/actions/modal.action';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  templateComponent: BaseTemplateComponent;

  @Output()
  editClicked: EventEmitter<Action> = new EventEmitter<Action>();

  @Input()
  expandable: boolean;

  @Input()
  collapsed: boolean;

  constructor() {}

  ngOnInit() {}

  onEditClicked() {
    this.editClicked.emit(
      new ShowModal({ content: () => this.templateComponent })
    );
  }
}
