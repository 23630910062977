import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { TypesService } from 'src/app/core/services/api/types/types.service';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  CleanRealEstateTypesState,
  LoadedRealEstateTypesFail,
  LoadedRealEstateTypesSuccess,
  RealEstateTypesActionTypes,
} from 'src/app/dictionaries/store/actions/real-estate-types.action';

@Injectable()
export class RealEstateTypesEffects {
  constructor(private actions$: Actions, private typesService: TypesService) {}

  public loadRealEstateTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RealEstateTypesActionTypes.LoadRealEstateTypes),
      switchMap(() =>
        this.typesService.getRealEstateTypes().pipe(
          map(
            (realEstateTypes: DictionaryI[]) =>
              new LoadedRealEstateTypesSuccess(realEstateTypes)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadedRealEstateTypesFail(error))
          )
        )
      )
    )
  );

  public handleLoadedRealEstateTypesFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RealEstateTypesActionTypes.LoadedRealEstateTypesFail),
      map(() => new CleanRealEstateTypesState())
    )
  );
}
