import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { InviteContractorI } from 'src/app/core/models/invite/invite-contractor.interface';

export enum ContractorActionTypes {
  InviteContractor = '[Contractor] Invite Contractor',
  InviteContractorSuccess = '[Contractor] Invited Contractor',
  InviteContractorFail = '[Contractor] Failed to Invite Contractor',
}

@ShowLoader()
export class InviteContractor implements Action {
  readonly type = ContractorActionTypes.InviteContractor;

  constructor(public payload: InviteContractorI) {}
}

@HideLoader(ContractorActionTypes.InviteContractor)
export class InviteContractorSuccess implements Action {
  readonly type = ContractorActionTypes.InviteContractorSuccess;

  constructor(public payload: number) {}
}

@HideLoader(ContractorActionTypes.InviteContractor)
export class InviteContractorFail implements Action {
  readonly type = ContractorActionTypes.InviteContractorFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export type ContractorActions =
  | InviteContractor
  | InviteContractorSuccess
  | InviteContractorFail;
