<div
  class="card border-0 mt-2"
  *ngFor="let alertMessage of paginatedAlertMessages; let first = first"
>
  <div class="card-body border-bottom" [ngClass]="{ 'border-top': first }">
    <span
      [ngClass]="{
        'warning-header': alertMessage.type === alertMessagesTypes.WARNING,
        'success-header': alertMessage.type === alertMessagesTypes.SUCCESS,
        'info-header': alertMessage.type === alertMessagesTypes.INFO,
        'danger-header': alertMessage.type === alertMessagesTypes.DANGER
      }"
    >
      <h5 class="card-title">
        <a
          class="btn btn-outline-secondary btn-sm ml-auto float-right"
          (click)="onClearOne(alertMessage)"
          translate
          >go.messagesPanel.buttons.clear</a
        >

        <p
          class="pointer assignment-title"
          (click)="
            goToDetails(
              alertMessage.assignmentId,
              alertMessage.assignmentStatus
            )
          "
        >
          {{ (alertMessage.title | translate) + alertMessage.assignmentName }}
        </p>
      </h5>

      <h6 class="card-subtitle mb-2">
        {{ alertMessage.timestamp | date: 'yyyy-MM-dd HH:mm:ss' }}
      </h6>
      <p class="card-text mt-3 assignment-status">
        {{ alertMessage.message | translate }}
        {{
          !!alertMessage.errorReason
            ? (alertMessage.errorReason | reasonTranslate | async)
            : ('assignmentStatus.' + alertMessage.assignmentStatus | translate)
        }}
      </p>
    </span>
  </div>
</div>

<div
  class="row mt-4 justify-content-center"
  *ngIf="alertMessages?.length > maxElementOnListPage"
>
  <div class="col-8">
    <pagination
      [totalItems]="alertMessages?.length"
      [itemsPerPage]="maxElementOnListPage"
      [(ngModel)]="currentPage"
      (pageChanged)="onPaginationClick($event)"
    >
    </pagination>
  </div>
</div>
