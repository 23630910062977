import {
  ModalActions,
  ModalActionTypes,
} from 'src/app/core/store/actions/modal.action';

export interface ModalReducerState {
  isOpen: boolean;
}

export const initialModalReducerState: ModalReducerState = {
  isOpen: false,
};

export function modalReducer(
  state: ModalReducerState = initialModalReducerState,
  action: ModalActions
): ModalReducerState {
  switch (action.type) {
    case ModalActionTypes.ShowModal: {
      return {
        ...state,
        isOpen: true,
      };
    }

    case ModalActionTypes.HiddenModal: {
      return {
        ...state,
        isOpen: false,
      };
    }

    case ModalActionTypes.CleanModalState: {
      return {
        ...initialModalReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const isModalOpened = (state: ModalReducerState) => state.isOpen;
