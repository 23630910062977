import {
  PWAActionTypes,
  PWAStateAction,
} from 'src/app/core/store/actions/pwa.action';

export interface PWAState {
  disablePWA: boolean;
}

export const initialPWAState: PWAState = {
  disablePWA: false,
};

export function pwaStateReducer(
  state: PWAState = initialPWAState,
  action: PWAStateAction
): PWAState {
  switch (action.type) {
    case PWAActionTypes.ChangePWA: {
      return {
        ...state,
        disablePWA: action.payload,
      };
    }
    default:
      return state;
  }
}

export const getDisabledPWA = (state: PWAState) => state.disablePWA;
