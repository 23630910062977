import { environment } from 'src/environments/environment';

interface AuthConfig {
  clientID: string;
  domain: string;
  responseType: string;
  audience: string;
}

export const AUTH_CONFIG: AuthConfig = {
  clientID: environment.auth0.clientID,
  domain: environment.auth0.domain,
  responseType: environment.auth0.responseType,
  audience: environment.auth0.audience,
};
