import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ROUTER_NAVIGATED,
  ROUTER_REQUEST,
  RouterNavigatedAction,
  RouterRequestAction,
} from '@ngrx/router-store';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  delay,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  AuthActionTypes,
  LoggedOut,
} from 'src/app/auth/store/actions/auth.action';
import { mapAssignmentSortEnumToAssignmentFilter } from 'src/app/core/enums/assignment-sort.enum';
import { CoreUrlEnums } from 'src/app/core/enums/url-paths.enum';
import { GoogleAnalyticsService } from 'src/app/core/services/google/analytics/google-analytics.service';
import {
  AssignmentChangeStatusActionTypes,
  CloseAssignment,
  RemoveAssignment,
  ReopenAssignment,
} from 'src/app/core/store/actions/assignment-change-status.action';
import { AssignmentsActionTypes } from 'src/app/core/store/actions/assignments.action';
import {
  ExcelActionTypes,
  ExportToAssignmentEventsExcel,
  ExportToExcel,
  ExportToSimpleExcel,
} from 'src/app/core/store/actions/excel.action';
import {
  ExternalRedirectActionTypes,
  GoToMeps,
  GoToMepsConversation,
} from 'src/app/core/store/actions/external-redirect.action';
import {
  AssignmentCostAddCalculationAnalytics,
  AssignmentCostChangeCalculationAnalytics,
  AssignmentCostFilterByRoomTypeAnalytics,
  AssignmentCostFilterByRoomTypeCleanAnalytics,
  AssignmentCostGoToRoomDetailsAnalytics,
  AssignmentCostRemoveCalculationAnalytics,
  AssignmentCostSearchByRoomNameAnalytics,
  AssignmentCostSelectCalculationViewAnalytics,
  AssignmentCostSortRoomsAnalytics,
  AssignmentCostSortRoomsCleanAnalytics,
  AssignmentEventsExcelExportAnalytics,
  AssignmentLogSelectCalculationAnalytics,
  CalculationNotAvailableAnyMoreAnalytics,
  CleanGoogleAnalytics,
  CloseAssignmentClicked,
  DisablePWAClicked,
  DismissedPWAClicked,
  ExcelExportAnalytics,
  GoogleAnalyticsActionTypes,
  GoToMepsChatPageClicked,
  GoToMepsPageClicked,
  GoToMessagesClickedAnalytics,
  HomePageChartClickedAnalytics,
  HomePageNavigationClickedAnalytics,
  InstallPWAClicked,
  InstructionsPWAClicked,
  LogoutClickedAnalytics,
  NativeBackButtonAnalytics,
  NumbersDashboardChangedAnalytics,
  NumbersDashboardPeriodFilterAnalytics,
  NumbersSearchGoToAnalysisFromMenuAnalytics,
  NumbersSearchGoToAssignmentAnalytics,
  NumbersSearchPerformedAnalytics,
  NumbersSearchViewChangedAnalytics,
  PageChangedAnalytics,
  RemoveAssignmentClicked,
  ReopenAssignmentClicked,
  RoomCostExpandCodesAnalytics,
  RoomCostSearchByNameOrCodeAnalytics,
  RoomCostSelectCodesAnalytics,
  RoomCostSortByCodeAnalytics,
  RoomCostSortByCodeCleanAnalytics,
  SideBarMenuClickedAnalytics,
  SimpleExcelExportAnalytics,
  SpinnerTimeAnalytics,
  StartAssignmentClickedAnalytics,
  TableChangeQueryAnalytics,
  TableCleanFilteredAnalytics,
  TableCleanSortedAnalytics,
  TableFilteredAnalytics,
  TableGoToDetailsAnalytics,
  TablePaginatedAnalytics,
  TableSortedAnalytics,
  UserChangedLanguageAnalytics,
  UserLogoutAnalytics,
  UwHomePageClicked,
  UwSupportPageClicked,
} from 'src/app/core/store/actions/google-analytics.actions';
import {
  RouterActionTypes,
  RouterGo,
  RouterUpdateQueryParams,
} from 'src/app/core/store/actions/router.action';
import {
  HideSpinner,
  SpinnerActionTypes,
} from 'src/app/core/store/actions/spinner.action';
import {
  getApplicationStateSelector,
  getAssignmentsStateSelector,
} from 'src/app/core/store/index';
import { mapSearchSortEnumToSearchFilter } from 'src/app/numbers/search/enums/search-sort.enum';
import {
  AssignmentSearchActionTypes,
  SearchAssignmentSuccess,
} from 'src/app/numbers/store/actions/assignment-search.action';

@Injectable()
export class GoogleAnalyticsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private analyticsService: GoogleAnalyticsService
  ) {}

  // Effects used to send analysis
  
  pageChangedAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.PageChangedAnalytics),
    tap((spinnerTimeAnalytics: PageChangedAnalytics) =>
      this.analyticsService.pageChanged(spinnerTimeAnalytics.payload)
    )
  ), { dispatch: false });

  
  spinnerAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.SpinnerTimeAnalytics),
    tap((pageChange: SpinnerTimeAnalytics) =>
      this.analyticsService.spinnerTimeAnalysis(pageChange.payload)
    )
  ), { dispatch: false });

  
  tablePaginatedAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.TablePaginatedAnalytics),
    tap((pageChange: TablePaginatedAnalytics) =>
      this.analyticsService.tablePaginated(pageChange.payload)
    )
  ), { dispatch: false });

  
  tableFilteredAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.TableFilteredAnalytics),
    tap((pageChange: TableFilteredAnalytics) =>
      this.analyticsService.tableFiltered(pageChange.payload)
    )
  ), { dispatch: false });

  
  tableCleanFilteredAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.TableCleanFilteredAnalytics),
    tap((pageChange: TableCleanFilteredAnalytics) =>
      this.analyticsService.tableFilteredClean(
        pageChange.payload.table,
        pageChange.payload.filter
      )
    )
  ), { dispatch: false });

  
  tableSortedAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.TableSortedAnalytics),
    tap((pageChange: TableSortedAnalytics) =>
      this.analyticsService.tableSorted(pageChange.payload)
    )
  ), { dispatch: false });

  
  tableCleanSortedAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.TableCleanSortedAnalytics),
    tap((pageChange: TableCleanSortedAnalytics) =>
      this.analyticsService.tableSortedClean(pageChange.payload)
    )
  ), { dispatch: false });

  
  tableChangeQueryAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.TableChangeQueryAnalytics),
    tap((pageChange: TableChangeQueryAnalytics) =>
      this.analyticsService.tableChangeQuery(pageChange.payload)
    )
  ), { dispatch: false });

  
  tableGoToDetailsAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.TableGoToDetailsAnalytics),
    tap((pageChange: TableGoToDetailsAnalytics) =>
      this.analyticsService.tableGoToDetails(pageChange.payload)
    )
  ), { dispatch: false });

  
  numbersSearchGoToAssignmentAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.NumbersSearchGoToAssignmentAnalytics),
    tap((goToAssignment: NumbersSearchGoToAssignmentAnalytics) =>
      this.analyticsService.numbersGoToAssignmentDetails(
        goToAssignment.payload.view,
        goToAssignment.payload.status
      )
    )
  ), { dispatch: false });

  
  numbersSearchGoToAnalysisFromMenuAnalysis$: Observable<
    Action
  > = createEffect(() => this.actions$.pipe(
    ofType(
      GoogleAnalyticsActionTypes.NumbersSearchGoToAnalysisFromMenuAnalytics
    ),
    tap((action: NumbersSearchGoToAnalysisFromMenuAnalytics) =>
      this.analyticsService.numbersGoToAnalysisFromMenu(action.payload)
    )
  ), { dispatch: false });

  
  numbersSearchAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.NumbersSearchViewChangedAnalytics),
    tap((pageChange: NumbersSearchViewChangedAnalytics) =>
      this.analyticsService.numbersSearchViewChanged(pageChange.payload)
    )
  ), { dispatch: false });

  
  numbersDashboardAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.NumbersDashboardChangedAnalytics),
    tap((pageChange: NumbersDashboardChangedAnalytics) =>
      this.analyticsService.numbersDashboardChanged(pageChange.payload)
    )
  ), { dispatch: false });

  
  exportExcelAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.ExcelExportAnalytics),
    tap((pageChange: ExcelExportAnalytics) =>
      this.analyticsService.excelExportAnalysis(pageChange.payload)
    )
  ), { dispatch: false });

  
  exportSimpleExcelAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.SimpleExcelExportAnalytics),
    tap((pageChange: SimpleExcelExportAnalytics) =>
      this.analyticsService.excelExportSimpleAnalysis(pageChange.payload)
    )
  ), { dispatch: false });

  
  exportAssignmentEventsExcelAnalysis$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.AssignmentEventsExcelExportAnalytics),
    tap((pageChange: AssignmentEventsExcelExportAnalytics) =>
      this.analyticsService.excelExportAssignmentEventsAnalysis(
        pageChange.payload
      )
    )
  ), { dispatch: false });

  
  userChangedLanguage$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.UserChangedLanguageAnalytics),
    tap((pageChange: UserChangedLanguageAnalytics) =>
      this.analyticsService.userChangedLanguage(pageChange.payload)
    )
  ), { dispatch: false });

  
  userLogout$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.UserLogoutAnalytics),
    tap((logout: UserLogoutAnalytics) => this.analyticsService.userLogout())
  ), { dispatch: false });

  
  back$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.NativeBackButtonAnalytics),
    tap((back: NativeBackButtonAnalytics) =>
      this.analyticsService.goBackNative()
    )
  ), { dispatch: false });

  
  timePeriodChanged$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.NumbersDashboardPeriodFilterAnalytics),
    tap((period: NumbersDashboardPeriodFilterAnalytics) =>
      this.analyticsService.timePeriodChanged(period.payload)
    )
  ), { dispatch: false });

  
  assignmentCostChangeCalculation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.AssignmentCostChangeCalculationAnalytics),
    tap((period: AssignmentCostChangeCalculationAnalytics) =>
      this.analyticsService.assignmentCostChangeCalculation(
        period.payload.toString()
      )
    )
  ), { dispatch: false });

  
  assignmentCostAddCalculation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.AssignmentCostAddCalculationAnalytics),
    tap((period: AssignmentCostAddCalculationAnalytics) =>
      this.analyticsService.assignmentCostAddCalculation(
        period.payload.toString()
      )
    )
  ), { dispatch: false });

  
  assignmentCostRemoveCalculation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.AssignmentCostRemoveCalculationAnalytics),
    tap((period: AssignmentCostRemoveCalculationAnalytics) =>
      this.analyticsService.assignmentCostRemoveCalculation(
        period.payload.toString()
      )
    )
  ), { dispatch: false });

  
  assignmentCostSelectCalculationView$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(
      GoogleAnalyticsActionTypes.AssignmentCostSelectCalculationViewAnalytics
    ),
    tap((period: AssignmentCostSelectCalculationViewAnalytics) =>
      this.analyticsService.assignmentCostSelectCalculationView(
        period.payload.view.toString(),
        period.payload.component
      )
    )
  ), { dispatch: false });

  
  assignmentCostSearchByRoomName$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.AssignmentCostSearchByRoomNameAnalytics),
    tap((action: AssignmentCostSearchByRoomNameAnalytics) =>
      this.analyticsService.assignmentCostSearchByRoomName()
    )
  ), { dispatch: false });

  
  assignmentCostFilterByRoomType$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.AssignmentCostFilterByRoomTypeAnalytics),
    tap((action: AssignmentCostFilterByRoomTypeAnalytics) =>
      this.analyticsService.assignmentCostFilterByRoomType(action.payload)
    )
  ), { dispatch: false });

  
  assignmentCostFilterByRoomTypeClean$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(
      GoogleAnalyticsActionTypes.AssignmentCostFilterByRoomTypeCleanAnalytics
    ),
    tap((action: AssignmentCostFilterByRoomTypeCleanAnalytics) =>
      this.analyticsService.assignmentCostFilterByRoomTypeClean()
    )
  ), { dispatch: false });

  
  assignmentCostSortRooms$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.AssignmentCostSortRoomsAnalytics),
    tap((action: AssignmentCostSortRoomsAnalytics) =>
      this.analyticsService.assignmentCostSortRooms(action.payload)
    )
  ), { dispatch: false });

  
  assignmentCostSortRoomsClean$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.AssignmentCostSortRoomsCleanAnalytics),
    tap((action: AssignmentCostSortRoomsCleanAnalytics) =>
      this.analyticsService.assignmentCostSortRoomsClean()
    )
  ), { dispatch: false });

  
  assignmentCostGoToRoomDetails$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.AssignmentCostGoToRoomDetailsAnalytics),
    tap((action: AssignmentCostGoToRoomDetailsAnalytics) =>
      this.analyticsService.assignmentCostGoToRoomDetails()
    )
  ), { dispatch: false });

  
  assignmentLogSelectCalculation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.AssignmentLogSelectCalculationAnalytics),
    tap((action: AssignmentLogSelectCalculationAnalytics) =>
      this.analyticsService.assignmentLogSelectCalculation()
    )
  ), { dispatch: false });

  
  sideBarClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.SideBarMenuClickedAnalytics),
    tap((action: SideBarMenuClickedAnalytics) =>
      this.analyticsService.sideBarClicked(
        action.payload.type,
        action.payload.page
      )
    )
  ), { dispatch: false });

  
  roomCostSearchCodes$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.RoomCostSearchByNameOrCodeAnalytics),
    tap((action: RoomCostSearchByNameOrCodeAnalytics) =>
      this.analyticsService.roomCostSearchCodes()
    )
  ), { dispatch: false });

  
  roomCostSortCodes$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.RoomCostSortByCodeAnalytics),
    tap((action: RoomCostSortByCodeAnalytics) =>
      this.analyticsService.roomCostSortCodes(action.payload)
    )
  ), { dispatch: false });

  
  roomCostSortClearCodes$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.RoomCostSortByCodeCleanAnalytics),
    tap((action: RoomCostSortByCodeCleanAnalytics) =>
      this.analyticsService.roomCostSortClearCodes()
    )
  ), { dispatch: false });

  
  roomCostCodesSelected$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.RoomCostSelectCodesAnalytics),
    tap((action: RoomCostSelectCodesAnalytics) =>
      this.analyticsService.roomCostCodesSelected(action.payload)
    )
  ), { dispatch: false });

  
  roomCostCodesExpanded$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.RoomCostExpandCodesAnalytics),
    tap((action: RoomCostExpandCodesAnalytics) =>
      this.analyticsService.roomCostCodesExpanded()
    )
  ), { dispatch: false });

  
  startAssignmentClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.StartAssignmentClickedAnalytics),
    tap((action: StartAssignmentClickedAnalytics) =>
      this.analyticsService.startAssignmentClicked()
    )
  ), { dispatch: false });

  
  goToMessagesClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.GoToMessagesClickedAnalytics),
    tap((action: GoToMessagesClickedAnalytics) =>
      this.analyticsService.goToMessagesClicked()
    )
  ), { dispatch: false });

  
  logoutClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.LogoutClickedAnalytics),
    tap((action: LogoutClickedAnalytics) =>
      this.analyticsService.logoutClicked()
    )
  ), { dispatch: false });

  
  homePageNavigationClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.HomePageNavigationClickedAnalytics),
    tap((action: HomePageNavigationClickedAnalytics) =>
      this.analyticsService.homePageNavigationClicked(action.payload)
    )
  ), { dispatch: false });

  
  homePageChartClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.HomePageChartClickedAnalytics),
    tap((action: HomePageChartClickedAnalytics) =>
      this.analyticsService.homePageChartClicked(action.payload)
    )
  ), { dispatch: false });

  
  numbersSearch$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.NumbersSearchPerformedAnalytics),
    tap((search: NumbersSearchPerformedAnalytics) =>
      this.analyticsService.numbersSearch(search.payload)
    )
  ), { dispatch: false });

  
  uwHomePageClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.UwHomePageClicked),
    tap((search: UwHomePageClicked) =>
      this.analyticsService.uwHomePageClicked()
    )
  ), { dispatch: false });

  
  uwSupportPageClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.UwSupportPageClicked),
    tap((search: UwSupportPageClicked) =>
      this.analyticsService.uwSupportPageClicked()
    )
  ), { dispatch: false });

  
  mepsAssignmentPageClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.GoToMepsPageClicked),
    tap((search: GoToMepsPageClicked) =>
      this.analyticsService.mepsAssignmentPageClicked()
    )
  ), { dispatch: false });

  
  mepsChatPageClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.GoToMepsChatPageClicked),
    tap((search: GoToMepsChatPageClicked) =>
      this.analyticsService.mepsChatPageClicked()
    )
  ), { dispatch: false });

  
  assignmentClosedClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.CloseAssignmentClicked),
    tap((search: CloseAssignmentClicked) =>
      this.analyticsService.assignmentClosedClicked()
    )
  ), { dispatch: false });

  
  assignmentRemovedClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.RemoveAssignmentClicked),
    tap((search: RemoveAssignmentClicked) =>
      this.analyticsService.assignmentRemovedClicked()
    )
  ), { dispatch: false });

  
  assignmentReopenClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.ReopenAssignmentClicked),
    tap((search: ReopenAssignmentClicked) =>
      this.analyticsService.assignmentReopenClicked()
    )
  ), { dispatch: false });

  
  disablePWAClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.DisablePWAClicked),
    tap((search: DisablePWAClicked) =>
      this.analyticsService.disablePWAClicked()
    )
  ), { dispatch: false });

  
  installPWAClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.InstallPWAClicked),
    tap((search: InstallPWAClicked) =>
      this.analyticsService.installPWAClicked()
    )
  ), { dispatch: false });

  
  dismissedPWAClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.DismissedPWAClicked),
    tap((search: DismissedPWAClicked) =>
      this.analyticsService.dismissedPWAClicked()
    )
  ), { dispatch: false });

  
  instructionsPWAClicked$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.InstructionsPWAClicked),
    tap((search: InstructionsPWAClicked) =>
      this.analyticsService.instructionsPWAClicked()
    )
  ), { dispatch: false });

  
  calculationNotAvailableAnyMore$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GoogleAnalyticsActionTypes.CalculationNotAvailableAnyMoreAnalytics),
    delay(100),
    tap((action: CalculationNotAvailableAnyMoreAnalytics) =>
      this.analyticsService.calculationNotAvailableAnyMore(action.payload)
    )
  ), { dispatch: false });

  // Effects used to map source core event in to analysis
  // lazy loaded modules have its own mappings
  
  routerGo$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATED),
    switchMap((pageChange: RouterNavigatedAction) =>
      of(new PageChangedAnalytics(pageChange.payload.routerState.url))
    )
  ));

  
  routerBack$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_REQUEST),
    filter(
      (router: RouterRequestAction) =>
        router.payload.event.navigationTrigger === 'popstate'
    ),
    switchMap((back: RouterRequestAction) =>
      of(new NativeBackButtonAnalytics())
    )
  ));

  
  spinnerHide$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SpinnerActionTypes.HideSpinner),
    withLatestFrom(this.store.pipe(select(getApplicationStateSelector))),
    switchMap(([action, state]) => {
      const actionsToDispatch = [];

      if (state.spinner.actionsInProgress.length === 0) {
        actionsToDispatch.push(new CleanGoogleAnalytics());
      }

      if (
        state.googleAnalytics.spinner.loadingStarted > 0 &&
        state.googleAnalytics.spinner.actions.length > 0
      ) {
        const loadingTime =
          Date.now() - state.googleAnalytics.spinner.loadingStarted;

        if (loadingTime > 1000) {
          actionsToDispatch.push(
            new SpinnerTimeAnalytics({
              timeInSeconds: loadingTime / 1000,
              actions: state.googleAnalytics.spinner.actions,
            })
          );
        }
      } else {
        const spinnerTimeAnalytics = new SpinnerTimeAnalytics({
          timeInSeconds: 0,
          actions: ['EMPTY_ACTIONS'],
        });

        const { payload } = action as HideSpinner;

        if (!!payload) {
          spinnerTimeAnalytics.payload.actions.push(payload.type);
        }

        actionsToDispatch.push(spinnerTimeAnalytics);
      }

      return of(...actionsToDispatch);
    })
  ));

  
  filterSearchTables$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AssignmentSearchActionTypes.ChangeAssignmentsSearchFilters),
    switchMap((action: any) =>
      of(
        new TableFilteredAnalytics({
          table: 'numbers-search',
          settings: { ...action.payload },
        })
      )
    )
  ));

  
  filterCleanSearchTables$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AssignmentSearchActionTypes.CleanAssignmentsSearchFilters),
    switchMap((action: any) =>
      of(
        new TableCleanFilteredAnalytics({
          table: 'numbers-search',
          filter: action.payload,
        })
      )
    )
  ));

  
  sortedTables$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AssignmentsActionTypes.ChangeAssignmentsSorting),
    withLatestFrom(this.store.pipe(select(getAssignmentsStateSelector))),
    switchMap(([action, state]: [any, any]) =>
      of(
        new TableSortedAnalytics({
          table: state.tableType,
          settings: mapAssignmentSortEnumToAssignmentFilter(action['payload']),
        })
      )
    )
  ));

  
  sortedTablesClean$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AssignmentsActionTypes.CleanAssignmentsSorting),
    withLatestFrom(this.store.pipe(select(getAssignmentsStateSelector))),
    switchMap(([action, state]: [any, any]) =>
      of(new TableCleanSortedAnalytics(state.tableType))
    )
  ));

  
  changeQueryTables$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AssignmentsActionTypes.ChangeAssignmentsQuery),
    withLatestFrom(this.store.pipe(select(getAssignmentsStateSelector))),
    switchMap(([action, state]: [any, any]) =>
      of(
        new TableChangeQueryAnalytics({
          table: state.tableType,
          query: action.payload,
        })
      )
    )
  ));

  
  sortedSearchTables$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AssignmentSearchActionTypes.ChangeAssignmentsSearchSorting),
    map((action) => mapSearchSortEnumToSearchFilter(action['payload'])),
    switchMap((settings) =>
      of(
        new TableSortedAnalytics({
          table: 'numbers-search',
          settings: settings,
        })
      )
    )
  ));

  
  sortedCleanSearchTables$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AssignmentSearchActionTypes.CleanAssignmentsSearchSorting),
    switchMap((settings) => of(new TableCleanSortedAnalytics('numbers-search')))
  ));

  
  paginatedTables$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AssignmentsActionTypes.ChangeAssignmentsPagination),
    withLatestFrom(this.store.pipe(select(getAssignmentsStateSelector))),
    switchMap(([action, state]: [any, any]) =>
      of(
        new TablePaginatedAnalytics({
          table: state.tableType,
          settings: {
            page: action.payload,
            total: Math.ceil(state.total / state.pagination.limit),
          },
        })
      )
    )
  ));

  
  simpleExcelExport$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ExcelActionTypes.ExportToSimpleExcel),
    switchMap((simpleExcel: ExportToSimpleExcel) =>
      of(
        new SimpleExcelExportAnalytics(simpleExcel.payload.assignmentIds.length)
      )
    )
  ));

  
  assignmentEventsExcelExport$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ExcelActionTypes.ExportToAssignmentEventsExcel),
    switchMap((excel: ExportToAssignmentEventsExcel) =>
      of(
        new AssignmentEventsExcelExportAnalytics(
          excel.payload.assignmentIds.length
        )
      )
    )
  ));

  
  excelExport$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ExcelActionTypes.ExportToExcel),
    switchMap((excel: ExportToExcel) =>
      of(new ExcelExportAnalytics(excel.payload.assignmentIds.length))
    )
  ));

  
  logout$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AuthActionTypes.LoggedOut),
    switchMap((action: LoggedOut) => of(new UserLogoutAnalytics()))
  ));

  
  periodFilter$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(RouterActionTypes.UpdateQueryParams),
    filter(
      (action: RouterUpdateQueryParams) => !!action.payload.query['period']
    ),
    switchMap((action: RouterUpdateQueryParams) =>
      of(
        new NumbersDashboardPeriodFilterAnalytics(
          action.payload.query['period']
        )
      )
    )
  ));

  
  assignmentSearch$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AssignmentSearchActionTypes.SearchAssignmentSuccess),
    switchMap((action: SearchAssignmentSuccess) =>
      of(new NumbersSearchPerformedAnalytics(action.payload.assignments.length))
    )
  ));

  
  goToMeps$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ExternalRedirectActionTypes.GoToMeps),
    switchMap((action: GoToMeps) => of(new GoToMepsPageClicked()))
  ));

  
  goConversation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ExternalRedirectActionTypes.GoToMepsConversation),
    switchMap((action: GoToMepsConversation) =>
      of(new GoToMepsChatPageClicked())
    )
  ));

  
  closeAssignment$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AssignmentChangeStatusActionTypes.CloseAssignment),
    switchMap((action: CloseAssignment) => of(new CloseAssignmentClicked()))
  ));

  
  removeAssignment$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AssignmentChangeStatusActionTypes.RemoveAssignment),
    switchMap((action: RemoveAssignment) => of(new RemoveAssignmentClicked()))
  ));

  
  reopenAssignment$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AssignmentChangeStatusActionTypes.ReopenAssignment),
    switchMap((action: ReopenAssignment) => of(new ReopenAssignmentClicked()))
  ));

  
  goToSupport$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(RouterActionTypes.Go),
    filter((action: RouterGo) =>
      action.payload.path.includes(CoreUrlEnums.SUPPORT)
    ),
    switchMap((action: RouterGo) => of(new UwSupportPageClicked()))
  ));
}
