<div
  class="pl-4"
  [ngClass]="highlightHeader ? 'menu-main-item' : 'menu-main-item-simple'"
>
  <span (click)="onClick()" class="pointer">
    <ng-content select="[slot=icon]"></ng-content>

    <span class="icon-text" [translate]="name"></span>
  </span>
</div>

<ng-content select="[slot=list]"></ng-content>
