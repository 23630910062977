import { AuthActionTypes } from 'src/app/auth/store/actions/auth.action';

export function clearState(reducer) {
  return function(state, action) {
    if (action.type === AuthActionTypes.LoggedOut) {
      return reducer(
        {
          state: {
            language: state.state.language,
            support: state.state.support,
          },
          dict: state.dict,
          auth: {
            info: state.auth.info,
          },
        },
        action
      );
    }

    return reducer(state, action);
  };
}
