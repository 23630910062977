import { Component, OnInit } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/auth/services/authorization/authorization.service';
import { Logout } from 'src/app/auth/store/actions/auth.action';
import { LanguageEnum } from 'src/app/core/enums/language.enum';
import {
  AssignmentUrlEnum,
  CoreUrlEnums,
} from 'src/app/core/enums/url-paths.enum';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';
import {
  GoToMessagesClickedAnalytics,
  LogoutClickedAnalytics,
  StartAssignmentClickedAnalytics,
  UserChangedLanguageAnalytics,
  UwHomePageClicked,
} from 'src/app/core/store/actions/google-analytics.actions';
import { LanguageStateAction } from 'src/app/core/store/actions/language.action';
import { RouterGo } from 'src/app/core/store/actions/router.action';
import { LanguageState } from 'src/app/core/store/reducers/language.reducer';
import { getAllAlertMessagesSelector } from 'src/app/core/store/selectors/alert-messages.selectors';
import {
  getAvailableLanguagesSelector,
  getSelectedLanguageSelector,
} from 'src/app/core/store/selectors/language.selectors';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';
import getAssignmentStartUrl = AssignmentUrlEnum.getAssignmentStartUrl;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends StoreDispatcher<any> implements OnInit {
  doesTokenExists$: Observable<boolean>;
  alerts$: Observable<AlertMessage[]>;

  messagesPanelUrl = CoreUrlEnums.GO_MESSAGE_PANEL;

  selectedLanguage$: Observable<LanguageEnum>;
  languages$: Observable<LanguageEnum[]>;

  isCollapsed = true;

  constructor(
    private authService: AuthorizationService,
    store: Store<LanguageState>
  ) {
    super(store);
  }

  ngOnInit() {
    this.getData();
  }

  goToStartAssignment() {
    this.dispatchActions([
      new RouterGo({
        path: [getAssignmentStartUrl(AssignmentUrlEnum.START_FORM)],
      }),
      new StartAssignmentClickedAnalytics(),
    ]);
  }

  goToMessages() {
    this.hideAndDispatchActions([
      new RouterGo({ path: [this.messagesPanelUrl] }),
      new GoToMessagesClickedAnalytics(),
    ]);
  }

  goToMainPage() {
    this.doesTokenExists$
      .pipe(take(1))
      .subscribe((tokenExists: boolean) =>
        tokenExists ? this.redirectToRootDir() : this.redirectToLoginPage()
      );
  }

  private redirectToRootDir(): void {
    this.dispatchActions([
      new RouterGo({ path: ['/'] }),
      new UwHomePageClicked(),
    ]);
  }

  private redirectToLoginPage(): void {
    this.dispatchActions([
      new RouterGo({ path: [CoreUrlEnums.LOGIN] }),
      new UwHomePageClicked(),
    ]);
  }

  logout() {
    this.hideAndDispatchActions([new Logout(), new LogoutClickedAnalytics()]);
  }

  dispatchLanguageAction(action: LanguageStateAction) {
    this.dispatchActions([
      action,
      new UserChangedLanguageAnalytics(action.payload),
    ]);
    this.hide();
  }

  hideAndDispatchActions(action: Action[]) {
    this.hide();
    this.dispatchActions(action);
  }

  hide() {
    this.isCollapsed = true;
  }

  private getData() {
    this.getLanguageData();
  }

  private getLanguageData() {
    this.languages$ = this.store.pipe(select(getAvailableLanguagesSelector));
    this.selectedLanguage$ = this.store.pipe(
      select(getSelectedLanguageSelector)
    );
    this.doesTokenExists$ = this.authService.doesTokenExists$;
    this.alerts$ = this.store.pipe(select(getAllAlertMessagesSelector));
  }
}
