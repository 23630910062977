import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { getAssignmentPhaseChartStateSelector } from 'src/app/core/store';
import { LoadAssignmentPhaseChart } from 'src/app/core/store/actions/assignment-phase-chart.action';
import { AgreementsReducerState } from 'src/app/core/store/reducers/agreements.reducer';
import { AssignmentPhaseChartReducerState } from 'src/app/core/store/reducers/assignment-phase-chart.reducer';

@Injectable()
export class AssignmentPhaseChartGuard implements CanActivate {
  constructor(private store: Store<AgreementsReducerState>) {}

  canActivate(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  checkIfCanActivate(): Observable<boolean> {
    return this.waitForChartToToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForChartToToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getAssignmentPhaseChartStateSelector),
      tap((chartState: AssignmentPhaseChartReducerState) => {
        if (!chartState.loaded && !chartState.loading && !chartState.error) {
          this.store.dispatch(new LoadAssignmentPhaseChart());
        }
      }),
      filter(
        (chartState: AssignmentPhaseChartReducerState) =>
          chartState.loaded || !!chartState.error
      ),
      map((chartState: AssignmentPhaseChartReducerState) => {
        if (!!chartState.error) {
          throw new Error(chartState.error.error.message);
        } else {
          return chartState.loaded;
        }
      }),
      take(1)
    );
  }
}
