/**
 * Representation of LanguageEnum State
 */
import { LanguageEnum } from 'src/app/core/enums/language.enum';
import {
  LanguageActionTypes,
  LanguageStateAction,
} from 'src/app/core/store/actions/language.action';

export interface LanguageState {
  language: LanguageEnum;
  availableLanguages: LanguageEnum[];
}

/**
 * LanguageState can be imagine as table
 */
export const initialLanguageState: LanguageState = {
  language: LanguageEnum.SE,
  availableLanguages: Object.values(LanguageEnum),
};

/**
 * Reducer for LanguageState which will generate values for our store
 *
 * @param {LanguageState} state
 * @param {LanguageStateAction} action
 * @returns {LanguageState}
 * @constructor
 */
export function languageStateReducer(
  state: LanguageState = initialLanguageState,
  action: LanguageStateAction
): LanguageState {
  switch (action.type) {
    case LanguageActionTypes.SetedDefaultLanguage:
    case LanguageActionTypes.ChangedLanguage: {
      return {
        ...state,
        language: action.payload,
      };
    }
    default:
      return state;
  }
}

/**
 * List of selectors functions we can thing of them as a DB query's
 */

export const getSelectedLanguage = (state: LanguageState) => state.language;
export const getAvailableLanguages = (state: LanguageState) =>
  state.availableLanguages;
