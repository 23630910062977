import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssignmentActionsEnum } from 'src/app/core/enums/assignment-actions.enum';
import { AssignmentLittleI } from 'src/app/core/models/assignment/assignment-little.interface';

@Component({
  selector: 'app-actions-dropdown-single-assignment',
  templateUrl: './actions-dropdown-single-assignment.component.html',
  styleUrls: ['./actions-dropdown-single-assignment.component.scss'],
})
export class ActionsDropdownSingleAssignmentComponent implements OnInit {
  @Input()
  assignmentResource: AssignmentLittleI;

  @Output()
  goToMeps: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  goToChatInMeps: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  exportToExcel: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  exportToSimpleExcel: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  close: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  remove: EventEmitter<{
    assignmentId: number;
    wasCreatedInUw: boolean;
  }> = new EventEmitter<{
    assignmentId: number;
    wasCreatedInUw: boolean;
  }>();

  @Output()
  reopen: EventEmitter<number> = new EventEmitter<number>();

  assignmentActionsEnum = AssignmentActionsEnum;

  constructor() {}

  ngOnInit() {}

  onGoToMeps(id: string) {
    this.goToMeps.emit(id);
  }

  onGoToChatInMeps(id: string) {
    this.goToChatInMeps.emit(id);
  }

  onExportToExcel(id: number) {
    this.exportToExcel.emit(id);
  }

  onExportToSimpleExcel(id: number) {
    this.exportToSimpleExcel.emit(id);
  }

  onClose(id: number) {
    this.close.emit(id);
  }

  onRemove(assignmentId: number, wasCreatedInUw) {
    this.remove.emit({ assignmentId, wasCreatedInUw });
  }

  onReopen(id: number) {
    this.reopen.emit(id);
  }
}
