import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { AppPhaseChartInterface } from 'src/app/core/models/app-phase-chart.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum AssignmentPhaseChartActionTypes {
  LoadAssignmentPhaseChart = '[AssignmentPhaseChart] Load Assignment Phase Chart',
  LoadedAssignmentPhaseChartSuccess = '[AssignmentPhaseChart] Loaded Assignment Phase Chart Success',
  LoadedAssignmentPhaseChartFail = '[AssignmentPhaseChart] Fail To Load Assignment Phase Chart',

  CleanAssignmentPhaseChartState = '[AssignmentPhaseChart] Clean Assignment Phase Chart',
}

@ShowLoader()
export class LoadAssignmentPhaseChart implements Action {
  readonly type = AssignmentPhaseChartActionTypes.LoadAssignmentPhaseChart;
}

@HideLoader(AssignmentPhaseChartActionTypes.LoadAssignmentPhaseChart)
export class LoadedAssignmentPhaseChartsSuccess implements Action {
  readonly type =
    AssignmentPhaseChartActionTypes.LoadedAssignmentPhaseChartSuccess;

  // TODO Add type
  constructor(public payload: AppPhaseChartInterface) {}
}

@HideLoader(AssignmentPhaseChartActionTypes.LoadAssignmentPhaseChart)
export class LoadedAssignmentPhaseChartsFail implements Action {
  readonly type =
    AssignmentPhaseChartActionTypes.LoadedAssignmentPhaseChartFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanAssignmentPhaseChartState implements Action {
  readonly type =
    AssignmentPhaseChartActionTypes.CleanAssignmentPhaseChartState;
}

export type AssignmentPhaseChartAction =
  | LoadAssignmentPhaseChart
  | LoadedAssignmentPhaseChartsSuccess
  | LoadedAssignmentPhaseChartsFail
  | CleanAssignmentPhaseChartState;
