import { Injectable } from '@angular/core';
import {
  AnalysisUrlEnum,
  NumbersUrlEnum,
} from 'src/app/core/enums/url-paths.enum';
import { environment } from 'src/environments/environment';

@Injectable()
export class ExternalRedirectService {
  mepsConversationUrl = '/conversations';

  constructor() {}

  goToNewWindow(url: string) {
    window.open(url);
  }

  goToAssignmentAnalysis(assignmentId: number) {
    this.goToNewWindow(
      `#/${AnalysisUrlEnum.getAnalysisDetailsUrl(assignmentId.toString())}`
    );
  }

  goToNumbersSearch(query: string) {
    this.goToNewWindow(
      `#/${NumbersUrlEnum.getNumbersSearchUrl(
        NumbersUrlEnum.SEARCH_LIST
      )}?query=${query}`
    );
  }

  goToMeps(mepsId: string) {
    this.goToNewWindow(`${environment.mepsAssignmentUrl}${mepsId}`);
  }

  goToMepsConversation(mepsId: string) {
    this.goToNewWindow(
      `${environment.mepsAssignmentUrl}${mepsId}${this.mepsConversationUrl}`
    );
  }

  goToEmail(email: string) {
    this.goToNewWindow(`mailto:${email}`);
  }

  goToPhoneNumber(phoneNumber: string) {
    this.goToNewWindow(`tel:${phoneNumber}`);
  }
}
