export enum AssignmentStatusEnum {
  DRAFT = 'draft',
  MAIN_CONTRACTOR_DONE = 'mainContractorDone',
  PENDING_INVITATION = 'pendingInvitation',
  INVITATION_ACCEPTED = 'invitationAccepted',
  INVITATION_DECLINED = 'invitationDeclined',
  NEW_CALCULATION = 'newCalculation',
  NEW_FINAL_CALCULATION = 'newFinalCalculation',
  DECLINED_CALCULATION = 'declinedCalculation',
  DECLINED_FINAL_CALCULATION = 'declinedFinalCalculation',
  APPROVED_CALCULATION = 'approvedCalculation',
  APPROVED_FINAL_CALCULATION = 'approvedFinalCalculation',
  PENDING_DECISION = 'pendingDecision',
  PENDING_CLOSING = 'pendingClosing',
  CLOSED = 'closed',
  REMOVED = 'removed',
}
