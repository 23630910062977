import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthorizationService } from 'src/app/auth/services/authorization/authorization.service';

import { LoggedIn } from 'src/app/auth/store/actions/auth.action';
import { Ping } from 'src/app/auth/store/actions/ping.action';
import { UserState } from 'src/app/auth/store/reducers/user.reducer';

@Injectable()
export class LoginPageGuard implements CanActivate {
  constructor(
    private authService: AuthorizationService,
    private store: Store<UserState>
  ) {}

  canActivate() {
    this.store.dispatch(new Ping());

    if (this.authService.isTokenValid()) {
      this.store.dispatch(new LoggedIn());
      return false;
    } else {
      return true;
    }
  }
}
