import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { AlertMessagesStatus } from 'src/app/core/enums/alert-messages-status.enum';
import { AlertMessagesTypes } from 'src/app/core/enums/alert-messages-types.enum';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import {
  AlertMessages,
  AlertMessagesActions,
} from 'src/app/core/store/actions/alert-messages.action';

function sortByDate(a: AlertMessage, b: AlertMessage): number {
  return b.timestamp - a.timestamp;
}
const selectId = (alertMessage: AlertMessage) => alertMessage.id;

const adapter: EntityAdapter<AlertMessage> = createEntityAdapter<AlertMessage>({
  selectId,
  sortComparer: sortByDate,
});

export interface AlertMessagesReducerState extends EntityState<AlertMessage> {
  filter: AlertMessagesTypes | null;
  loaded: boolean;
  loading: boolean;
  error: ErrorResponseWithIdI;
}

export const initialAlertMessagesReducerState: AlertMessagesReducerState = adapter.getInitialState(
  {
    filter: null,
    loaded: false,
    loading: false,
    error: null,
  }
);

export function alertMessagesReducer(
  state: AlertMessagesReducerState = initialAlertMessagesReducerState,
  action: AlertMessagesActions
): AlertMessagesReducerState {
  switch (action.type) {
    case AlertMessages.LoadMessages: {
      return {
        ...state,
        loading: true,
      };
    }

    case AlertMessages.LoadMessagesFail: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case AlertMessages.LoadMessagesSuccess: {
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case AlertMessages.ShowMessage: {
      return adapter.addOne(action.payload, state);
    }

    case AlertMessages.HideMessage: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: { status: AlertMessagesStatus.READ },
        },
        state
      );
    }

    case AlertMessages.ClearMessage: {
      return adapter.removeOne(action.payload.id, state);
    }

    case AlertMessages.ClearAllMessages: {
      return initialAlertMessagesReducerState;
    }

    case AlertMessages.SetFilter: {
      return {
        ...state,
        filter: state.filter === action.payload ? null : action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getAlertMessagesIds = adapter.getSelectors().selectIds;
export const getAlertMessagesEntities = adapter.getSelectors().selectEntities;
export const getAllAlertMessages = adapter.getSelectors().selectAll;
export const getAlertMessagesTotal = adapter.getSelectors().selectTotal;

export const getAlertMessagesFilter = (state: AlertMessagesReducerState) =>
  state && state.filter;
export const getAlertMessagesLoaded = (state: AlertMessagesReducerState) =>
  state && state.loaded;
export const getAlertMessagesLoading = (state: AlertMessagesReducerState) =>
  state && state.loading;
export const getAlertMessagesError = (state: AlertMessagesReducerState) =>
  state && state.error;
