import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { AssignmentAgendaEnum } from 'src/app/core/enums/assignment-agenda.enum';
import { AssignmentPhaseEnum } from 'src/app/core/enums/assignment-phase.enum';
import { AssignmentSortEnum } from 'src/app/core/enums/assignment-sort.enum';
import { AssignmentLittleI } from 'src/app/core/models/assignment/assignment-little.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AssignmentSearchRequest } from 'src/app/core/models/search/assignment-search-request.interface';
import { AssignmentSearchResponse } from 'src/app/core/models/search/assignment-search-response.interface';

export enum AssignmentsActionTypes {
  LoadAssignment = '[Assignments] Load Assignment',
  LoadedAssignmentSuccess = '[Assignments] Loaded Assignment Success',
  LoadedAssignmentFail = '[Assignments] Fail To Load Assignment',

  LoadAssignmentsList = '[Assignments] Load Assignments List',
  LoadedAssignmentsListSuccess = '[Assignments] Loaded Assignments List Success',
  LoadedAssignmentsListFail = '[Assignments] Fail To Load Assignments List',

  LoadMakeDecision = '[Assignments] Load Make Decision',
  LoadedMakeDecisionSuccess = '[Assignments] Loaded Make Decision Success',
  LoadedMakeDecisionFail = '[Assignments] Fail To Load Make Decision',

  LoadWatchList = '[Assignments] Load Watch List',
  LoadedWatchListSuccess = '[Assignments] Loaded Watch List Success',
  LoadedWatchListFail = '[Assignments] Fail To Load Watch List',

  UpdateSelectedAssignments = '[Assignments] Update Selected Assignments',

  ChangeAssignmentsQuery = '[Assignment] Change Assignments Query',
  ChangeAssignmentsSorting = '[Assignments] Change Assignments Sorting',
  ChangeAssignmentsPagination = '[Assignments] Change Assignments Pagination',
  ChangeAssignmentsTableType = '[Assignments] Change Assignments Table Type',

  RefreshAssignmentTable = '[Assignments] Refresh Assignment Table',

  CleanAssignmentsState = '[Assignments] Clean Assignments State',
  CleanAssignmentsSorting = '[Assignments] Clean Assignments Sorting',
}

// ------ Load Assignment ------

@ShowLoader()
export class LoadAssignment implements Action {
  readonly type = AssignmentsActionTypes.LoadAssignment;

  constructor(public payload: number) {}
}

@HideLoader(AssignmentsActionTypes.LoadAssignment)
export class LoadedAssignmentSuccess implements Action {
  readonly type = AssignmentsActionTypes.LoadedAssignmentSuccess;

  constructor(public payload: AssignmentLittleI) {}
}

@HideLoader(AssignmentsActionTypes.LoadAssignment)
export class LoadedAssignmentFail implements Action {
  readonly type = AssignmentsActionTypes.LoadedAssignmentFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

// ------ Load Assignment List ------

@ShowLoader()
export class LoadAssignmentsList implements Action {
  readonly type = AssignmentsActionTypes.LoadAssignmentsList;

  constructor(public payload: AssignmentSearchRequest) {}
}

@HideLoader(AssignmentsActionTypes.LoadAssignmentsList)
export class LoadedAssignmentsListSuccess implements Action {
  readonly type = AssignmentsActionTypes.LoadedAssignmentsListSuccess;

  constructor(public payload: AssignmentSearchResponse) {}
}

@HideLoader(AssignmentsActionTypes.LoadAssignmentsList)
export class LoadedAssignmentsListFail implements Action {
  readonly type = AssignmentsActionTypes.LoadedAssignmentsListFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

// ------ Load Make Decision List ------

@ShowLoader()
export class LoadMakeDecision implements Action {
  readonly type = AssignmentsActionTypes.LoadMakeDecision;

  constructor(public payload: AssignmentSearchRequest) {}
}

@HideLoader(AssignmentsActionTypes.LoadMakeDecision)
export class LoadedMakeDecisionSuccess implements Action {
  readonly type = AssignmentsActionTypes.LoadedMakeDecisionSuccess;

  constructor(public payload: AssignmentSearchResponse) {}
}

@HideLoader(AssignmentsActionTypes.LoadMakeDecision)
export class LoadedMakeDecisionFail implements Action {
  readonly type = AssignmentsActionTypes.LoadedMakeDecisionFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

// ------ Load Watch List ------

@ShowLoader()
export class LoadWatchList implements Action {
  readonly type = AssignmentsActionTypes.LoadWatchList;

  constructor(public payload: AssignmentSearchRequest) {}
}

@HideLoader(AssignmentsActionTypes.LoadWatchList)
export class LoadedWatchListSuccess implements Action {
  readonly type = AssignmentsActionTypes.LoadedWatchListSuccess;

  constructor(public payload: AssignmentSearchResponse) {}
}

@HideLoader(AssignmentsActionTypes.LoadWatchList)
export class LoadedWatchListFail implements Action {
  readonly type = AssignmentsActionTypes.LoadedWatchListFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

// ------ Change Table Details ------

export class ChangeAssignmentsQuery implements Action {
  readonly type = AssignmentsActionTypes.ChangeAssignmentsQuery;

  constructor(public payload: string) {}
}

export class ChangeAssignmentsPagination implements Action {
  readonly type = AssignmentsActionTypes.ChangeAssignmentsPagination;

  constructor(public payload: number) {}
}

export class ChangeAssignmentsSorting implements Action {
  readonly type = AssignmentsActionTypes.ChangeAssignmentsSorting;

  constructor(public payload: AssignmentSortEnum) {}
}

export class CleanAssignmentsSorting implements Action {
  readonly type = AssignmentsActionTypes.CleanAssignmentsSorting;
}

export class ChangeAssignmentsTableType implements Action {
  readonly type = AssignmentsActionTypes.ChangeAssignmentsTableType;

  constructor(public payload: AssignmentPhaseEnum | AssignmentAgendaEnum) {}
}

export class UpdateSelectedAssignments implements Action {
  readonly type = AssignmentsActionTypes.UpdateSelectedAssignments;

  constructor(public payload: AssignmentLittleI[]) {}
}

// Clean

export class RefreshAssignmentTable implements Action {
  readonly type = AssignmentsActionTypes.RefreshAssignmentTable;

  constructor(public payload: AssignmentPhaseEnum | AssignmentAgendaEnum) {}
}

export class CleanAssignmentsState implements Action {
  readonly type = AssignmentsActionTypes.CleanAssignmentsState;
}

export type AssignmentsActions =
  | LoadAssignment
  | LoadedAssignmentSuccess
  | LoadedAssignmentFail
  | LoadAssignmentsList
  | LoadedAssignmentsListFail
  | LoadedAssignmentsListSuccess
  | LoadMakeDecision
  | LoadedMakeDecisionFail
  | LoadedMakeDecisionSuccess
  | LoadWatchList
  | LoadedWatchListFail
  | LoadedWatchListSuccess
  | ChangeAssignmentsPagination
  | ChangeAssignmentsTableType
  | ChangeAssignmentsQuery
  | ChangeAssignmentsSorting
  | CleanAssignmentsSorting
  | UpdateSelectedAssignments
  | RefreshAssignmentTable
  | CleanAssignmentsState;
