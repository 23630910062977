<li role="menuitem">
  <a
    class="dropdown-item"
    (click)="onExportToExcel(assignmentResource?.details?.assignmentId)"
    >{{ 'dropdown-menu.exportToExcel' | translate }}</a
  >
</li>
<li role="menuitem">
  <a
    class="dropdown-item"
    (click)="onExportToSimpleExcel(assignmentResource?.details?.assignmentId)"
    >{{ 'dropdown-menu.exportToSimpleExcel' | translate }}</a
  >
</li>

<li class="divider dropdown-divider"></li>

<li role="menuitem">
  <a
    class="dropdown-item"
    (click)="onGoToMeps(assignmentResource?.details?.mepsId)"
    >{{ 'dropdown-menu.goToMeps' | translate }}</a
  >
</li>
<li role="menuitem">
  <a
    class="dropdown-item"
    (click)="onGoToChatInMeps(assignmentResource?.details?.mepsId)"
    >{{ 'dropdown-menu.goToMepsConversation' | translate }}</a
  >
</li>

<span
  *ngIf="
    !assignmentResource?.actions?.anyPending &&
    !!assignmentResource?.actions?.possibleActions &&
    assignmentResource?.actions?.possibleActions.length > 0
  "
>
  <li class="divider dropdown-divider"></li>
  <li
    role="menuitem"
    *ngIf="
      assignmentResource?.actions?.possibleActions?.includes(
        assignmentActionsEnum.CLOSE
      ) && assignmentResource?.details?.createdInUnityWorks
    "
  >
    <a
      class="dropdown-item"
      (click)="onClose(assignmentResource?.details?.assignmentId)"
      >{{ 'dropdown-menu.changeStatus.close' | translate }}</a
    >
  </li>
  <li
    role="menuitem"
    *ngIf="
      assignmentResource?.actions?.possibleActions?.includes(
        assignmentActionsEnum.REOPEN
      ) && assignmentResource?.details?.createdInUnityWorks
    "
  >
    <a
      class="dropdown-item"
      (click)="onReopen(assignmentResource?.details?.assignmentId)"
      >{{ 'dropdown-menu.changeStatus.reopen' | translate }}</a
    >
  </li>
  <li
    role="menuitem"
    *ngIf="
      assignmentResource?.actions?.possibleActions?.includes(
        assignmentActionsEnum.REMOVE
      )
    "
  >
    <a
      class="dropdown-item text-danger"
      (click)="
        onRemove(
          assignmentResource?.details?.assignmentId,
          assignmentResource.details.createdInUnityWorks
        )
      "
      >{{ 'dropdown-menu.changeStatus.remove' | translate }}</a
    >
  </li>
</span>
