import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { getClientAssignmentTypesStateSelector } from 'src/app/dictionaries/store';
import { LoadClientAssignmentTypes } from 'src/app/dictionaries/store/actions/client-assignment-types.action';
import { ClientAssignmentTypesReducerState } from 'src/app/dictionaries/store/reducers/client-assignment-types.reducer';

@Injectable()
export class ClientAssignmentTypesGuard
  implements CanActivate, CanActivateChild {
  constructor(private store: Store<ClientAssignmentTypesReducerState>) {}

  canActivate(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  canActivateChild(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  private checkIfCanActivate(): Observable<boolean> {
    return this.waitForClientAssignmentTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  private waitForClientAssignmentTypesToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getClientAssignmentTypesStateSelector),
      tap((assignmentTypesState: ClientAssignmentTypesReducerState) => {
        if (
          !assignmentTypesState.loaded &&
          !assignmentTypesState.loading &&
          !assignmentTypesState.error
        ) {
          this.store.dispatch(new LoadClientAssignmentTypes());
        }
      }),
      filter(
        (assignmentTypesState: ClientAssignmentTypesReducerState) =>
          assignmentTypesState.loaded || !!assignmentTypesState.error
      ),
      map((assignmentTypesState: ClientAssignmentTypesReducerState) => {
        if (!!assignmentTypesState.error) {
          throw new Error(assignmentTypesState.error.error.message);
        } else {
          return assignmentTypesState.loaded;
        }
      }),
      take(1)
    );
  }
}
