import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { AlertMessagesTypes } from 'src/app/core/enums/alert-messages-types.enum';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum AlertMessages {
  LoadMessages = '[Alert] Load Messages',
  LoadMessagesSuccess = '[Alert] Load Messages Success',
  LoadMessagesFail = '[Alert] Load Messages Success Fail',

  ShowMessage = '[Alert] Show Messages',
  HideMessage = '[Alert] Hide Messages',

  SetFilter = '[Alert] Set Filter',

  ClearMessage = '[Alert] Clear Message',
  ClearAllMessages = '[Alert] Clear All Messages',
}

@ShowLoader()
export class LoadMessages implements Action {
  readonly type = AlertMessages.LoadMessages;

  constructor(public payload?: any) {}
}

@HideLoader(AlertMessages.LoadMessages)
export class LoadMessagesSuccess implements Action {
  readonly type = AlertMessages.LoadMessagesSuccess;

  constructor(public payload: AlertMessage[]) {}
}

@HideLoader(AlertMessages.LoadMessages)
export class LoadMessagesFail implements Action {
  readonly type = AlertMessages.LoadMessagesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class ShowMessage implements Action {
  readonly type = AlertMessages.ShowMessage;

  constructor(public payload: AlertMessage) {}
}

export class HideMessage implements Action {
  readonly type = AlertMessages.HideMessage;

  constructor(public payload: AlertMessage) {}
}

export class SetFilter implements Action {
  readonly type = AlertMessages.SetFilter;

  constructor(public payload: AlertMessagesTypes) {}
}

export class ClearMessage implements Action {
  readonly type = AlertMessages.ClearMessage;

  constructor(public payload: AlertMessage) {}
}

export class ClearAllMessages implements Action {
  readonly type = AlertMessages.ClearAllMessages;

  constructor(public payload?: any) {}
}

export type AlertMessagesActions =
  | LoadMessages
  | LoadMessagesSuccess
  | LoadMessagesFail
  | ShowMessage
  | HideMessage
  | SetFilter
  | ClearMessage
  | ClearAllMessages;
