import { createSelector } from '@ngrx/store';
import { getEventTypesStateSelector } from 'src/app/dictionaries/store/index';
import {
  getAllEventTypes,
  getEventTypesEntities,
  getEventTypesError,
  getEventTypesIds,
  getEventTypesLoaded,
  getEventTypesLoading,
  getEventTypesTotal,
} from 'src/app/dictionaries/store/reducers/event-types.reducer';
import { SelectI } from '../../../core/models/select.interface';
import { getSelectedLanguageSelector } from '../../../core/store/selectors/language.selectors';
import {
  mapDictionaryToSelectForGivenLanguage,
  mapSelectArrayToSelectMap,
} from '../../../core/utils/dictionary-utils';
import { DictionaryI } from '../../models/dictionary.interface';

export const getEventTypesIdsSelector = createSelector(
  getEventTypesStateSelector,
  getEventTypesIds
);

export const getEventTypesEntitiesSelector = createSelector(
  getEventTypesStateSelector,
  getEventTypesEntities
);

export const getAllEventTypesTypesSelector = createSelector(
  getEventTypesStateSelector,
  getAllEventTypes
);

export const getEventTypesTotalSelector = createSelector(
  getEventTypesStateSelector,
  getEventTypesTotal
);

export const getEventTypesLoadingSelector = createSelector(
  getEventTypesStateSelector,
  getEventTypesLoading
);

export const getEventTypesLoadedSelector = createSelector(
  getEventTypesStateSelector,
  getEventTypesLoaded
);

export const getEventTypesErrorSelector = createSelector(
  getEventTypesStateSelector,
  getEventTypesError
);

// New Calculation
export const getNewCalculationAndFinalCalculationEventTypesIdsSelector = createSelector(
  getEventTypesEntities,
  eventTypesEntities => [
    eventTypesEntities && eventTypesEntities['calculation'].id,
    eventTypesEntities && eventTypesEntities['finalCalculation'].id,
  ]
);

// Finished
export const getAssignmentRejectedEventTypesSelector = createSelector(
  getEventTypesEntities,
  eventTypesEntities => [
    eventTypesEntities && eventTypesEntities['assignmentRejected'].id,
  ]
);

export const getDecisionForFinalCalculationEventTypesSelector = createSelector(
  getEventTypesEntities,
  eventTypesEntities => [
    eventTypesEntities && eventTypesEntities['decisionForFinalCalculation'].id,
  ]
);

// Waiting For Answer
export const getAssignmentCreatedAndInvitationToAssignmentEventTypesSelector = createSelector(
  getEventTypesEntities,
  eventTypesEntities => [
    eventTypesEntities && eventTypesEntities['assignmentCreated'].id,
    eventTypesEntities && eventTypesEntities['invitationToAssignment'].id,
  ]
);

export const getDecisionForCalculationAndFinalCalculationEventTypesSelector = createSelector(
  getEventTypesEntities,
  eventTypesEntities => [
    eventTypesEntities && eventTypesEntities['decisionForCalculation'].id,
    eventTypesEntities && eventTypesEntities['decisionForFinalCalculation'].id,
  ]
);

export const getAllEventTypesAsSelectObjectForCurrentLanguageSelector = createSelector(
  getAllEventTypesTypesSelector,
  getSelectedLanguageSelector,
  (decisionTypes: DictionaryI[], selectedLanguage: string): SelectI[] =>
    mapDictionaryToSelectForGivenLanguage(decisionTypes, selectedLanguage)
);

export const getAllEventTypesAsMapForCurrentLanguageSelector = createSelector(
  getAllEventTypesAsSelectObjectForCurrentLanguageSelector,
  (selectIList: SelectI[]) => mapSelectArrayToSelectMap(selectIList)
);
