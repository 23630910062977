import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

export enum ErrorExtraTypesActionTypes {
  LoadErrorExtraTypes = '[ErrorExtraTypes] Load Error Extra Types',
  LoadedErrorExtraTypesSuccess = '[ErrorExtraTypes] Loaded Error Extra Types',
  LoadedErrorExtraTypesFail = '[ErrorExtraTypes] Fail To Load Error Extra Types',

  CleanErrorExtraTypesState = '[ErrorExtraTypes] Clean',
}

@ShowLoader()
export class LoadErrorExtraTypes implements Action {
  readonly type = ErrorExtraTypesActionTypes.LoadErrorExtraTypes;

  constructor(public payload?: any) {}
}

@HideLoader(ErrorExtraTypesActionTypes.LoadErrorExtraTypes)
export class LoadedErrorExtraTypesSuccess implements Action {
  readonly type = ErrorExtraTypesActionTypes.LoadedErrorExtraTypesSuccess;

  constructor(public payload: DictionaryI[]) {}
}

@HideLoader(ErrorExtraTypesActionTypes.LoadErrorExtraTypes)
export class LoadedErrorExtraTypesFail implements Action {
  readonly type = ErrorExtraTypesActionTypes.LoadedErrorExtraTypesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanErrorExtraTypesState implements Action {
  readonly type = ErrorExtraTypesActionTypes.CleanErrorExtraTypesState;
}

export type ErrorExtraTypesAction =
  | LoadErrorExtraTypes
  | LoadedErrorExtraTypesSuccess
  | LoadedErrorExtraTypesFail
  | CleanErrorExtraTypesState;
