import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { TypesService } from 'src/app/core/services/api/types/types.service';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  CleanTradeTypesState,
  LoadedTradeTypesFail,
  LoadedTradeTypesSuccess,
  TradeTypesActionTypes,
} from 'src/app/dictionaries/store/actions/trade-types.action';

@Injectable()
export class TradeTypesEffects {
  constructor(private actions$: Actions, private typesService: TypesService) {}

  public loadTradeTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TradeTypesActionTypes.LoadTradeTypes),
      switchMap(() =>
        this.typesService.getTradeTypes().pipe(
          map(
            (TradeTypes: DictionaryI[]) =>
              new LoadedTradeTypesSuccess(TradeTypes)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadedTradeTypesFail(error))
          )
        )
      )
    )
  );

  public handleLoadedTradeTypesFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TradeTypesActionTypes.LoadedTradeTypesFail),
      map(() => new CleanTradeTypesState())
    )
  );
}
