import { createSelector } from '@ngrx/store';
import { CoreUrlEnums } from 'src/app/core/enums/url-paths.enum';

import { getPWAStateSelector } from 'src/app/core/store/index';
import { getDisabledPWA } from 'src/app/core/store/reducers/pwa.reducer';
import { getRouterUrlSelector } from 'src/app/core/store/selectors/router.selectors';

export const getDisabledPWASelector = createSelector(
  getPWAStateSelector,
  getDisabledPWA
);

export const shouldDisablePWASelector = createSelector(
  getDisabledPWASelector,
  getRouterUrlSelector,
  (disablePWA, url) =>
    url !== undefined && !!disablePWA !== undefined
      ? url.includes(CoreUrlEnums.SUPPORT) || disablePWA
      : undefined
);
