import {
  SpinnerActions,
  SpinnerActionTypes,
} from 'src/app/core/store/actions/spinner.action';

export interface SpinnerReducerState {
  active: number;
  actionsInProgress: any[];
}

export const initialSpinnerReducerState: SpinnerReducerState = {
  active: 0,
  actionsInProgress: [],
};

export function spinnerReducer(
  state: SpinnerReducerState = initialSpinnerReducerState,
  action: SpinnerActions
) {
  switch (action.type) {
    case SpinnerActionTypes.HideSpinner:
      const newActionInProgressHide = state.actionsInProgress.filter(
        triggerAction => triggerAction !== action.payload.triggerAction
      );

      return newActionInProgressHide.length === state.actionsInProgress.length
        ? { ...state }
        : {
            ...state,
            active: newActionInProgressHide.length,
            actionsInProgress: [...newActionInProgressHide],
          };

    case SpinnerActionTypes.ShowSpinner:
      const newActionInProgressShow = [
        ...state.actionsInProgress,
        action.payload.type,
      ];

      return state.actionsInProgress.includes(action.payload.type)
        ? { ...state }
        : {
            ...state,
            active: newActionInProgressShow.length,
            actionsInProgress: newActionInProgressShow,
          };

    default:
      return state;
  }
}

export const isSpinnerActive = (state: SpinnerReducerState) => state.active > 0;
