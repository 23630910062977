// TODO: Add integration with our backend to dynamically load
// Trade types
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  TradeTypesAction,
  TradeTypesActionTypes,
} from 'src/app/dictionaries/store/actions/trade-types.action';

const selectId = (dictionary: DictionaryI) => dictionary.name;
const adapter: EntityAdapter<DictionaryI> = createEntityAdapter<DictionaryI>({
  selectId,
});

export interface TradeTypesReducerState extends EntityState<DictionaryI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialTradeTypesReducerState: TradeTypesReducerState = adapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function tradeTypesReducer(
  state: TradeTypesReducerState = initialTradeTypesReducerState,
  action: TradeTypesAction
): TradeTypesReducerState {
  switch (action.type) {
    case TradeTypesActionTypes.LoadTradeTypes: {
      return {
        ...state,
        loading: true,
      };
    }

    case TradeTypesActionTypes.LoadedTradeTypesSuccess: {
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case TradeTypesActionTypes.LoadedTradeTypesFail: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case TradeTypesActionTypes.CleanTradeTypesState: {
      return {
        ...initialTradeTypesReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getTradeTypesIds = adapter.getSelectors().selectIds;
export const getTradeTypesEntities = adapter.getSelectors().selectEntities;
export const getAllTradeTypes = adapter.getSelectors().selectAll;
export const getTradeTypesTotal = adapter.getSelectors().selectTotal;

export const getTradeTypesLoading = (state: TradeTypesReducerState) =>
  state.loading;
export const getTradeTypesLoaded = (state: TradeTypesReducerState) =>
  state.loaded;
export const getTradeTypesError = (state: TradeTypesReducerState) =>
  state.error;
