import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ChartSeriesCalculation,
  ChartSingleCalculation,
  PeriodFilter,
} from 'src/app/shared/models/chart.interface';
import { environment } from 'src/environments/environment';

@Injectable()
export class ChartService {
  chartUrl = environment.url + 'api/v1/chart/';

  periodFilterUrl = this.chartUrl + 'period-filters';

  numberChartUrl = this.chartUrl + 'customer-assignments-numbers';
  volumeChartUrl = this.chartUrl + 'customer-assignments-volume';

  districtNumberChartUrl = this.chartUrl + 'customer-organization-number';
  districtVolumeChartUrl = this.chartUrl + 'customer-organization-volume';

  contractorsDeclinedChartUrl = this.chartUrl + 'customer-contractors-decline';
  contractorsNumberChartUrl = this.chartUrl + 'customer-contractors-number';
  contractorsResponseChartUrl = this.chartUrl + 'customer-contractors-response';

  constructor(private http: HttpClient) {}

  getPeriodFilter(): Observable<PeriodFilter> {
    return this.http.get<PeriodFilter>(this.periodFilterUrl);
  }

  getNumberChartData(): Observable<ChartSeriesCalculation[]> {
    return this.http.get<ChartSeriesCalculation[]>(this.numberChartUrl);
  }

  getVolumeChartData(): Observable<ChartSeriesCalculation[]> {
    return this.http.get<ChartSeriesCalculation[]>(this.volumeChartUrl);
  }

  getDistrictNumberChartData(): Observable<ChartSeriesCalculation[]> {
    return this.http.get<ChartSeriesCalculation[]>(this.districtNumberChartUrl);
  }

  getDistrictVolumeChartData(): Observable<ChartSeriesCalculation[]> {
    return this.http.get<ChartSeriesCalculation[]>(this.districtVolumeChartUrl);
  }

  getContractorsDeclinedChartData(): Observable<ChartSingleCalculation[]> {
    return this.http.get<ChartSingleCalculation[]>(
      this.contractorsDeclinedChartUrl
    );
  }

  getContractorsNumberChartData(): Observable<ChartSeriesCalculation[]> {
    return this.http.get<ChartSeriesCalculation[]>(
      this.contractorsNumberChartUrl
    );
  }

  getContractorsResponseChartData(): Observable<ChartSeriesCalculation[]> {
    return this.http.get<ChartSeriesCalculation[]>(
      this.contractorsResponseChartUrl
    );
  }
}
