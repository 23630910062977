import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { getTradeTypesStateSelector } from 'src/app/dictionaries/store';

import { LoadTradeTypes } from 'src/app/dictionaries/store/actions/trade-types.action';
import { TradeTypesReducerState } from 'src/app/dictionaries/store/reducers/trade-types.reducer';

@Injectable()
export class TradeTypesGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<TradeTypesReducerState>) {}

  canActivate(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  canActivateChild(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  private checkIfCanActivate(): Observable<boolean> {
    return this.waitForTradeTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  private waitForTradeTypesToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getTradeTypesStateSelector),
      tap((tradeTypesState: TradeTypesReducerState) => {
        if (
          !tradeTypesState.loaded &&
          !tradeTypesState.loading &&
          !tradeTypesState.error
        ) {
          this.store.dispatch(new LoadTradeTypes());
        }
      }),
      filter(
        (tradeTypesState: TradeTypesReducerState) =>
          tradeTypesState.loaded || !!tradeTypesState.error
      ),
      map((tradeTypesState: TradeTypesReducerState) => {
        if (!!tradeTypesState.error) {
          throw new Error(tradeTypesState.error.error.message);
        } else {
          return tradeTypesState.loaded;
        }
      }),
      take(1)
    );
  }
}
