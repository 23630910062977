import { createSelector } from '@ngrx/store';
import {
  getUserLoadedSelector,
  getUserLoadingSelector,
} from 'src/app/auth/store/selectors/user.selectors';

import { getAssignmentCountStateSelector } from 'src/app/core/store/index';
import {
  getAssignmentCountError,
  getAssignmentCountLoaded,
  getAssignmentCountLoading,
  getMakeDecisionCount,
  getWatchlistCount,
} from 'src/app/core/store/reducers/assignment-count.reducer';

export const getAssignmentCountLoadingSelector = createSelector(
  getAssignmentCountStateSelector,
  getAssignmentCountLoading
);

export const getAssignmentCountLoadedSelector = createSelector(
  getAssignmentCountStateSelector,
  getAssignmentCountLoaded
);

export const getAssignmentCountLoadedWithLoadedUserSelector = createSelector(
  getAssignmentCountLoadedSelector,
  getUserLoadedSelector,
  getUserLoadingSelector,
  (assignmentCountLoaded, userLoaded, userLoading) => ({
    assignmentCountLoaded,
    userLoaded,
    userLoading,
  })
);

export const getAssignmentCountErrorSelector = createSelector(
  getAssignmentCountStateSelector,
  getAssignmentCountError
);

export const getMakeDecisionCountSelector = createSelector(
  getAssignmentCountStateSelector,
  getMakeDecisionCount
);

export const getWatchlistCountSelector = createSelector(
  getAssignmentCountStateSelector,
  getWatchlistCount
);
