import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssignmentLittleI } from 'src/app/core/models/assignment/assignment-little.interface';

@Component({
  selector: 'app-actions-dropdown-multiple-assignments',
  templateUrl: './actions-dropdown-multiple-assignments.component.html',
  styleUrls: ['./actions-dropdown-multiple-assignments.component.scss'],
})
export class ActionsDropdownMultipleAssignmentsComponent implements OnInit {
  _assignmentResourceList: AssignmentLittleI[];
  _assignmentIds: number[];

  _selectedAssignmentResourceList: AssignmentLittleI[];
  _selectedAssignmentIds: number[];
  _selectedEventIds: number[];

  @Input()
  set assignmentResourceList(assignmentResourceList: AssignmentLittleI[]) {
    assignmentResourceList = assignmentResourceList || [];
    this._assignmentResourceList = assignmentResourceList;
    this._assignmentIds = this.getAssignmentIds(assignmentResourceList);
  }

  @Input()
  set selectedAssignmentResourceList(
    assignmentResourceList: AssignmentLittleI[]
  ) {
    assignmentResourceList = assignmentResourceList || [];
    this._selectedAssignmentResourceList = assignmentResourceList;
    this._selectedAssignmentIds = this.getAssignmentIds(assignmentResourceList);
    this._selectedEventIds = this.getEventIds(assignmentResourceList);
  }

  @Output()
  goToCostSummary: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  goToCompareAssignment: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Output()
  goToLog: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  goToMeps: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  exportToExcel: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Output()
  exportToSimpleExcel: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Output()
  exportAllToExcel: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Output()
  exportAllToSimpleExcel: EventEmitter<number[]> = new EventEmitter<number[]>();

  constructor() {}

  ngOnInit() {}

  onGoToCostSummary(id: number) {
    this.goToCostSummary.emit(id);
  }

  onGoToCompareAssignments() {
    if (!this.shouldDisableComparision()) {
      this.goToCompareAssignment.emit(this._selectedEventIds);
    }
  }

  onGoToLog(id: number) {
    this.goToLog.emit(id);
  }

  onGoToMeps(id: string) {
    this.goToMeps.emit(id);
  }

  onExportToExcel() {
    this.exportToExcel.emit(this._selectedAssignmentIds);
  }

  onExportToSimpleExcel() {
    this.exportToSimpleExcel.emit(this._selectedAssignmentIds);
  }

  onExportAllToExcel() {
    this.exportAllToExcel.emit(this._assignmentIds);
  }

  onExportAllToSimpleExcel() {
    this.exportAllToSimpleExcel.emit(this._assignmentIds);
  }

  shouldDisableComparision(): boolean {
    return (
      this._selectedEventIds.length !==
      this._selectedAssignmentResourceList.length
    );
  }

  private getAssignmentIds(assignmentList: AssignmentLittleI[] = []) {
    return assignmentList
      .filter(
        (assignment) =>
          assignment && assignment.details && assignment.details.assignmentId
      )
      .map((assignment) => assignment.details.assignmentId);
  }

  private getEventIds(assignmentList: AssignmentLittleI[] = []) {
    return assignmentList
      .filter(
        (assignment) =>
          assignment &&
          assignment.calculation &&
          assignment.calculation.assignmentEventId
      )
      .map((assignment) => assignment.calculation.assignmentEventId);
  }
}
