import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { getAssignmentTypesStateSelector } from 'src/app/dictionaries/store';

import { LoadAssignmentTypes } from 'src/app/dictionaries/store/actions/assignment-types.action';
import { AssignmentTypesReducerState } from 'src/app/dictionaries/store/reducers/assignment-types.reducer';

@Injectable()
export class AssignmentTypesGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<AssignmentTypesReducerState>) {}

  canActivate(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  canActivateChild(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  private checkIfCanActivate(): Observable<boolean> {
    return this.waitForAssignmentTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  private waitForAssignmentTypesToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getAssignmentTypesStateSelector),
      tap((assignmentTypesState: AssignmentTypesReducerState) => {
        if (
          !assignmentTypesState.loaded &&
          !assignmentTypesState.loading &&
          !assignmentTypesState.error
        ) {
          this.store.dispatch(new LoadAssignmentTypes());
        }
      }),
      filter(
        (assignmentTypesState: AssignmentTypesReducerState) =>
          assignmentTypesState.loaded || !!assignmentTypesState.error
      ),
      map((assignmentTypesState: AssignmentTypesReducerState) => {
        if (!!assignmentTypesState.error) {
          throw new Error(assignmentTypesState.error.error.message);
        } else {
          return assignmentTypesState.loaded;
        }
      }),
      take(1)
    );
  }
}
