import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AgreementI } from 'src/app/core/models/agreements.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AgreementsService } from 'src/app/core/services/api/agreements/agreements.service';
import {
  AgreementsActionTypes,
  CleanAgreementsState,
  CreateAgreement,
  CreateAgreementFail,
  CreateAgreementSuccess,
  EditAgreement,
  EditAgreementFail,
  EditAgreementSuccess,
  LoadAgreements,
  LoadedAgreementsFail,
  LoadedAgreementsSuccess,
} from 'src/app/core/store/actions/agreements.action';

@Injectable()
export class AgreementsEffects {
  constructor(
    private actions$: Actions,
    private agreementsService: AgreementsService
  ) {}

  
  public createAgreementDetails$ = createEffect(() => this.actions$.pipe(
    ofType(AgreementsActionTypes.CreateAgreement),
    switchMap((action: CreateAgreement) =>
      this.agreementsService.createAgreement(action.payload).pipe(
        map(agreementId => new CreateAgreementSuccess(agreementId)),
        catchError((error: ErrorResponseWithIdI) =>
          of(new CreateAgreementFail(error))
        )
      )
    )
  ));

  
  public editAgreementDetails$ = createEffect(() => this.actions$.pipe(
    ofType(AgreementsActionTypes.EditAgreement),
    switchMap((action: EditAgreement) =>
      this.agreementsService.editAgreement(action.payload).pipe(
        // TODO: This service should also return id
        map(_ => new EditAgreementSuccess(action.payload.agreementId)),
        catchError((error: ErrorResponseWithIdI) =>
          of(new EditAgreementFail(error))
        )
      )
    )
  ));

  
  // TODO: This could be optimised by reloading only agreement that has changed
  // but this requires changes on backend (new endpoint)
  public refreshAgreements$ = createEffect(() => this.actions$.pipe(
    ofType(
      AgreementsActionTypes.EditAgreementSuccess,
      AgreementsActionTypes.CreateAgreementSuccess
    ),
    map(_ => new LoadAgreements())
  ));

  
  public loadAgreementDetails$ = createEffect(() => this.actions$.pipe(
    ofType(AgreementsActionTypes.LoadAgreements),
    switchMap(() =>
      this.agreementsService.getAgreementsWithCid().pipe(
        map(
          (agreementsDetails: AgreementI[]) =>
            new LoadedAgreementsSuccess(agreementsDetails)
        ),
        catchError((error: ErrorResponseWithIdI) =>
          of(new LoadedAgreementsFail(error))
        )
      )
    )
  ));

  
  public handleLoadedAgreementsFail$ = createEffect(() => this.actions$.pipe(
    ofType(AgreementsActionTypes.LoadedAgreementsFail),
    map(() => new CleanAgreementsState())
  ));
}
