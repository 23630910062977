import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SelectI } from 'src/app/core/models/select.interface';
import { getSelectValueFromSelectArrayById } from 'src/app/core/utils/dictionary-utils';
import { DictionariesReducerState } from 'src/app/dictionaries/store';
import { getErrorExtraTypesEntitiesForSelectedLanguageSelector } from 'src/app/dictionaries/store/selectors/error-extra-types.selectors';
import { getErrorReasonTypesEntitiesForSelectedLanguageSelector } from 'src/app/dictionaries/store/selectors/error-reason-types.selectors';

@Injectable({
  providedIn: 'root',
})
// TODO: Replace this service with usage of new
// State Selectors in NgRX 7 during update
export class ErrorTranslationService {
  reasonsDictionary: SelectI[] = [];
  extraDictionary: SelectI[] = [];

  constructor(private store: Store<DictionariesReducerState>) {
    this.store
      .pipe(select(getErrorReasonTypesEntitiesForSelectedLanguageSelector))
      .subscribe(
        (reasonsDictionary: SelectI[]) =>
          (this.reasonsDictionary = reasonsDictionary)
      );

    this.store
      .pipe(select(getErrorExtraTypesEntitiesForSelectedLanguageSelector))
      .subscribe(
        (extraDictionary: SelectI[]) => (this.extraDictionary = extraDictionary)
      );
  }

  getErrorReasonFromDictionary(reason: string): string | number {
    return (
      getSelectValueFromSelectArrayById(reason, this.reasonsDictionary) ||
      reason
    );
  }

  getErrorExtraFromDictionary(extra: string): string | number {
    return (
      getSelectValueFromSelectArrayById(extra, this.extraDictionary) || extra
    );
  }
}
