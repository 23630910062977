import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AssignmentActionsEnum } from 'src/app/core/enums/assignment-actions.enum';
import { AnalysisUrlEnum } from 'src/app/core/enums/url-paths.enum';
import { AssignmentLittleI } from 'src/app/core/models/assignment/assignment-little.interface';
import { ExcelI } from 'src/app/core/models/excel.interface';
import {
  ExcelActions,
  ExportToExcel,
  ExportToSimpleExcel,
} from 'src/app/core/store/actions/excel.action';
import {
  ExternalRedirectActions,
  GoToMeps,
  GoToMepsConversation,
} from 'src/app/core/store/actions/external-redirect.action';
import {
  RouterActions,
  RouterGo,
} from 'src/app/core/store/actions/router.action';
import { BaseActionComponent } from 'src/app/shared/components/base-action/base-action.component';
import getAnalysisCompareAssignments = AnalysisUrlEnum.getAnalysisCompareAssignments;
import getAnalysisCostSummary = AnalysisUrlEnum.getAnalysisCostSummary;
import getAnalysisLogUrl = AnalysisUrlEnum.getAnalysisLogUrl;

@Component({
  selector: 'app-actions-dropdown',
  templateUrl: './actions-dropdown.component.html',
  styleUrls: ['./actions-dropdown.component.scss'],
})
export class ActionsDropdownComponent
  extends BaseActionComponent
  implements OnInit {
  assignmentActionsEnum = AssignmentActionsEnum;

  @Input()
  assignmentResource: AssignmentLittleI;

  @Input()
  selectedAssignmentResourceList: AssignmentLittleI[];

  @Input()
  assignmentResourceList: AssignmentLittleI[];

  @Input()
  disabled = false;

  @Output()
  externalRedirectAction: EventEmitter<
    ExternalRedirectActions
  > = new EventEmitter<ExternalRedirectActions>();

  @Output()
  redirect: EventEmitter<RouterActions> = new EventEmitter<RouterActions>();

  @Output()
  excelAction: EventEmitter<ExcelActions> = new EventEmitter<ExcelActions>();

  constructor(translationService: TranslateService) {
    super(translationService);
  }

  ngOnInit() {}

  onGoToCostSummary(id: number) {
    this.redirect.emit(new RouterGo({ path: [getAnalysisCostSummary(id)] }));
  }

  onGoToLog(id: number) {
    this.redirect.emit(new RouterGo({ path: [getAnalysisLogUrl(id)] }));
  }

  onGoToCompareAssignments(ids: number[]) {
    this.redirect.emit(
      new RouterGo({
        path: [getAnalysisCompareAssignments()],
        query: { assignmentEventIds: ids },
      })
    );
  }

  onGoToMeps(id: string) {
    this.externalRedirectAction.emit(new GoToMeps(id));
  }

  onGoToChatInMeps(id: string) {
    this.externalRedirectAction.emit(new GoToMepsConversation(id));
  }

  onExportToExcel(id: number[]) {
    this.excelAction.emit(
      new ExportToExcel(this.getPayloadForExportExcelAction(id))
    );
  }

  onExportToSimpleExcel(id: number[]) {
    this.excelAction.emit(
      new ExportToSimpleExcel(this.getPayloadForExportExcelAction(id))
    );
  }

  onExportAllToExcel(id: number[]) {
    this.excelAction.emit(
      new ExportToExcel(this.getPayloadForExportExcelAction(id))
    );
  }

  onExportAllToSimpleExcel(id: number[]) {
    this.excelAction.emit(
      new ExportToSimpleExcel(this.getPayloadForExportExcelAction(id))
    );
  }

  private getPayloadForExportExcelAction(ids: number[] = []): ExcelI {
    return {
      fileName: `${ids.length} assignment(s)`,
      assignmentIds: ids,
    };
  }
}
