import { Injectable } from '@angular/core';
import { LanguageEnum } from 'src/app/core/enums/language.enum';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  languageKey = 'language';
  pwaKey = 'skipPWA';

  constructor() {}

  getLanguage(): LanguageEnum {
    const languageFromLocalStorage = localStorage.getItem(
      this.languageKey
    ) as LanguageEnum;

    return this.checkIfLanguageExistsInAvailableLanguages(
      languageFromLocalStorage
    )
      ? (languageFromLocalStorage as LanguageEnum)
      : undefined;
  }

  updateLanguage(language: LanguageEnum): void {
    if (this.checkIfLanguageExistsInAvailableLanguages(language)) {
      localStorage.setItem(this.languageKey, language);
    }
  }

  getPWA(): boolean {
    return localStorage.getItem(this.pwaKey) === 'true';
  }

  updatePWA(pwa: boolean): void {
    pwa = pwa ?? false;
    localStorage.setItem(this.pwaKey, pwa.toString());
  }

  private checkIfLanguageExistsInAvailableLanguages(language: LanguageEnum) {
    return Object.values(LanguageEnum).includes(language);
  }
}
