import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { getCompanyUsersStateSelector } from 'src/app/core/store';
import { LoadCompanyUsers } from 'src/app/core/store/actions/company-users.action';
import { CompanyUsersReducerState } from 'src/app/core/store/reducers/company-users.reducer';

@Injectable()
export class CompanyUsersGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<CompanyUsersReducerState>) {}

  canActivate(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  canActivateChild(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  checkIfCanActivate(): Observable<boolean> {
    return this.waitForCompanyUsersToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForCompanyUsersToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getCompanyUsersStateSelector),
      tap((companyState: CompanyUsersReducerState) => {
        if (
          !companyState.loaded &&
          !companyState.loading &&
          !companyState.error
        ) {
          this.store.dispatch(new LoadCompanyUsers());
        }
      }),
      filter(
        (companyState: CompanyUsersReducerState) =>
          companyState.loaded || !!companyState.error
      ),
      map((companyState: CompanyUsersReducerState) => {
        if (!!companyState.error) {
          throw new Error(companyState.error.error.message);
        } else {
          return companyState.loaded;
        }
      }),
      take(1)
    );
  }
}
