import { SelectI } from 'src/app/core/models/select.interface';
import { createSelectObject } from 'src/app/core/utils/select-utils';

export const mapOptionToSelectWithTranslationKey = (
  option: string | number,
  translationKey
): SelectI => {
  if (checkIfValuesArePresent(option, translationKey)) {
    return createSelectObject(option, translationKey + option);
  }
};

export const mapOptionsToSelectsWithTranslationKey = (
  options: (string | number)[],
  translationKey
): SelectI[] => {
  if (checkIfValuesArePresent(options, translationKey)) {
    return options.map(option =>
      createSelectObject(option, translationKey + option)
    );
  }
};

function checkIfValuesArePresent(option, translationKeys) {
  return !!option && !!translationKeys;
}
