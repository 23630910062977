import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  HideLoginPanel,
  ShowLoginPanel,
} from 'src/app/auth/store/actions/auth.action';
import { getUserErrorSelector } from 'src/app/auth/store/selectors/user.selectors';
import { LanguageState } from 'src/app/core/store/reducers/language.reducer';
import { getSelectedLanguageSelector } from 'src/app/core/store/selectors/language.selectors';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends StoreDispatcher<any>
  implements OnInit, OnDestroy {
  private languageSubscription: ISubscription;
  private loginErrorSubscription: ISubscription;

  language: string;

  constructor(store: Store<LanguageState>) {
    super(store);
  }

  ngOnInit() {
    this.languageSubscription = this.store
      .pipe(select(getSelectedLanguageSelector))
      .subscribe((language) => {
        this.dispatchAction(new ShowLoginPanel(language));
        this.language = language;
      });

    this.loginErrorSubscription = this.store
      .pipe(
        select(getUserErrorSelector),
        filter((error) => !!error)
      )
      .subscribe(() => this.dispatchAction(new ShowLoginPanel(this.language)));
  }

  ngOnDestroy() {
    this.dispatchAction(new HideLoginPanel());

    this.unsubscribe(this.languageSubscription);
    this.unsubscribe(this.loginErrorSubscription);
  }

  unsubscribe(iSubscription: ISubscription) {
    if (!!iSubscription) {
      iSubscription.unsubscribe();
    }
  }
}
