import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { EMPTY, Observable, of, timer } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { LoadStart } from 'src/app/analysis/store/actions/start.actions';
import { UserActionTypes } from 'src/app/auth/store/actions/user.action';
import { AssignmentUrlEnum } from 'src/app/core/enums/url-paths.enum';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { PullingResponseI } from 'src/app/core/models/polling/pulling-response.interface';
import { RetrieveAssignmentsFacadeService } from 'src/app/core/services/api/assignments/retrieve-assignments-facade.service';
import { LoadAssignmentCount } from 'src/app/core/store/actions/assignment-count.action';
import { LoadAssignmentPhaseChart } from 'src/app/core/store/actions/assignment-phase-chart.action';
import { LoadAssignmentVolumeChart } from 'src/app/core/store/actions/assignment-volume-chart.action';
import {
  AssignmentsActionTypes,
  LoadAssignment,
  LoadedAssignmentSuccess,
} from 'src/app/core/store/actions/assignments.action';
import {
  AddAssignmentForPulling,
  LoadPendingAssignmentIdsList,
  LoadPendingAssignmentIdsListFail,
  LoadPendingAssignmentIdsListSuccess,
  PullingQueueActions,
  PullQueueActionTypes,
  SynchronizePullingQueue,
  SynchronizePullingQueueFail,
  SynchronizePullingQueueSuccess,
} from 'src/app/core/store/actions/pulling-queue.actions';
import { getRouterStateSelector } from 'src/app/core/store/index';
import { PullingQueueReducerState } from 'src/app/core/store/reducers/pulling-queue.reducer';
import {
  getAllAssignmentIdsFromPullingQueueSelector,
  getPullingStartedSelector,
} from 'src/app/core/store/selectors/pulling-queue.selectors';
import { environment } from 'src/environments/environment';

@Injectable()
export class PullingQueueEffects {
  intervalInMilliSeconds = environment.pullingIntervalInSeconds * 1000;

  pullingQueueIsNotEmpty: Observable<boolean>;
  assignmentIdsFromPullingQueue: Observable<number[]>;

  
  addToPullingAfterActions$ = createEffect(() => this.actions$.pipe(
    ofType(AssignmentsActionTypes.LoadedAssignmentSuccess),
    filter(
      (action: LoadedAssignmentSuccess) =>
        !!action.payload.actions && action.payload.actions.anyPending
    ),
    map(
      (action: LoadedAssignmentSuccess) =>
        new AddAssignmentForPulling(action.payload.details.assignmentId)
    )
  ));

  
  loadAllPendingAssignmentAfterUserIsLoggedIn$ = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.LoadedUserResourceSuccess),
    map(
      (action: LoadPendingAssignmentIdsList) =>
        new LoadPendingAssignmentIdsList()
    )
  ));

  
  getAssignmentsIdsWithPendingStatus$ = createEffect(() => this.actions$.pipe(
    ofType(PullQueueActionTypes.LoadPendingAssignmentIdsList),
    switchMap((action: LoadPendingAssignmentIdsList) =>
      this.getAssignmentPendingIds()
    )
  ));

  
  startPulling$ = createEffect(() => this.actions$.pipe(
    ofType(
      PullQueueActionTypes.LoadPendingAssignmentIdsListSuccess,
      PullQueueActionTypes.AddAssignmentForPulling
    ),
    exhaustMap(() =>
      this.startPullingQueue(
        this.pullingQueueIsNotEmpty,
        this.assignmentIdsFromPullingQueue
      )
    )
  ));

  
  synchronizePullingQueue$ = createEffect(() => this.actions$.pipe(
    ofType(PullQueueActionTypes.SynchronizePullingQueue),
    switchMap((action: SynchronizePullingQueue) =>
      this.synchronizePullingQueue(action.payload)
    )
  ));

  
  handleSynchronizePullingQueueSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(PullQueueActionTypes.SynchronizePullingQueueSuccess),
    filter(
      (action: SynchronizePullingQueueSuccess) => action.payload.length > 0
    ),
    withLatestFrom(this.store.pipe(select(getRouterStateSelector))),
    switchMap(([action, routerState]) => {
      const assignmentDetailsId = routerState.state.params['assignmentId'];
      const currentUrl = routerState.state.url;
      const actionToDispatch = this.handleSynchronizePullingQueueSuccess(
        currentUrl,
        assignmentDetailsId,
        action.payload
      );

      return actionToDispatch || EMPTY;
    })
  ));

  constructor(
    private actions$: Actions,
    private store: Store<PullingQueueReducerState>,
    private alignmentsFacadeService: RetrieveAssignmentsFacadeService
  ) {
    this.pullingQueueIsNotEmpty = this.store.pipe(
      select(getPullingStartedSelector),
      filter((x) => !x)
    );
    this.assignmentIdsFromPullingQueue = this.store.pipe(
      select(getAllAssignmentIdsFromPullingQueueSelector)
    );
  }

  private getAssignmentPendingIds() {
    return this.alignmentsFacadeService.getPendingAssignmentIds().pipe(
      map(
        (pendingAssignmentIds: number[]) =>
          new LoadPendingAssignmentIdsListSuccess(pendingAssignmentIds)
      ),
      catchError((error: ErrorResponseWithIdI) =>
        of(new LoadPendingAssignmentIdsListFail(error))
      )
    );
  }

  private startPullingQueue(
    pullingQueueIsNotEmpty: Observable<boolean>,
    assignmentIdsFromPullingQueue: Observable<number[]>
  ): Observable<SynchronizePullingQueue> {
    return timer(1, this.intervalInMilliSeconds).pipe(
      // This is used just to debugging purposes during test phase could be remove later on
      // It informs that pulling has started and which revolution it is
      tap(console.log),
      takeUntil(pullingQueueIsNotEmpty),
      withLatestFrom(assignmentIdsFromPullingQueue),
      map(([action, ids]) => new SynchronizePullingQueue(ids)),
      // This is used just to debugging purposes during test phase could be remove later on
      // it informs that pulling has stopped
      finalize(() => console.log(-1))
    );
  }

  private synchronizePullingQueue(
    assignmentIds: number[]
  ): Observable<PullingQueueActions> {
    return this.alignmentsFacadeService.pull({ assignmentIds }).pipe(
      map(
        (synchronizedPullingQueue: PullingResponseI[]) =>
          new SynchronizePullingQueueSuccess(synchronizedPullingQueue)
      ),
      catchError((error: ErrorResponseWithIdI) =>
        of(new SynchronizePullingQueueFail(error))
      )
    );
  }

  private handleSynchronizePullingQueueSuccess(
    currentUrl,
    currentAssignmentIdInUrl,
    changedAssignments: PullingResponseI[]
  ): Observable<Action> {
    if (this.userIsOnPageWithCountsVisible(currentUrl)) {
      return of(
        new LoadAssignmentCount(),
        new LoadAssignmentVolumeChart(),
        new LoadAssignmentPhaseChart()
      );
    } else if (
      this.userIsOnAssignmentDetailsPageThatWasRefreshed(
        currentAssignmentIdInUrl,
        changedAssignments
      )
    ) {
      return of(
        new LoadAssignmentCount(),
        new LoadAssignment(currentAssignmentIdInUrl),
        new LoadStart(currentAssignmentIdInUrl)
      );
    } else {
      return of(new LoadAssignmentCount());
    }
  }

  private userIsOnPageWithCountsVisible(url: string): boolean {
    return url.includes(AssignmentUrlEnum.OVERVIEW);
  }

  private userIsOnAssignmentDetailsPageThatWasRefreshed(
    currentAssignmentIdInUrl: string,
    changedAssignments: PullingResponseI[]
  ): boolean {
    return (
      !!currentAssignmentIdInUrl &&
      changedAssignments.findIndex(
        (x) => String(x.assignmentId) === String(currentAssignmentIdInUrl)
      ) !== -1
    );
  }
}
