import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';
import {
  HideSpinner,
  ShowSpinner,
} from 'src/app/core/store/actions/spinner.action';
import { SpinnerReducerState } from 'src/app/core/store/reducers/spinner.reducer';
import { isSpinnerActiveSelector } from 'src/app/core/store/selectors/spinner.selectors';

@Injectable()
export class SpinnerEffects {
  constructor(
    private actions$: Actions,
    private store: Store<SpinnerReducerState>,
    private spinnerService: SpinnerService
  ) {
    this.triggerSpinnerVisibility();
  }

  
  showSpinner$: Observable<Action> = createEffect(() => this.actions$.pipe(
    filter((action: any) => action.showLoader),
    map(action => new ShowSpinner(action))
  ));

  
  hideSpinner$: Observable<Action> = createEffect(() => this.actions$.pipe(
    filter((action: any) => !!action.triggerAction),
    map(action => new HideSpinner(action))
  ));

  private triggerSpinnerVisibility() {
    this.store
      .pipe(select(isSpinnerActiveSelector))
      .subscribe((isActive: boolean) =>
        isActive ? this.spinnerService.show() : this.spinnerService.hide()
      );
  }
}
