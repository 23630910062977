import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { AgreementI } from 'src/app/core/models/agreements.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import {
  AgreementsActions,
  AgreementsActionTypes,
} from 'src/app/core/store/actions/agreements.action';

function sortByName(a: AgreementI, b: AgreementI): number {
  return a.name.localeCompare(b.name);
}
const selectId = (agreement: AgreementI) => agreement.agreementId;

const adapter: EntityAdapter<AgreementI> = createEntityAdapter<AgreementI>({
  selectId,
  sortComparer: sortByName,
});

export interface AgreementsReducerState extends EntityState<AgreementI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialAgreementReducerState: AgreementsReducerState = adapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function agreementsReducer(
  state: AgreementsReducerState = initialAgreementReducerState,
  action: AgreementsActions
): AgreementsReducerState {
  switch (action.type) {
    case AgreementsActionTypes.LoadAgreements: {
      return {
        ...initialAgreementReducerState,
        loading: true,
      };
    }

    case AgreementsActionTypes.LoadedAgreementsSuccess: {
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case AgreementsActionTypes.LoadedAgreementsFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    }

    case AgreementsActionTypes.CleanAgreementsState: {
      return {
        ...initialAgreementReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getAgreementsIds = adapter.getSelectors().selectIds;
export const getAgreementsEntities = adapter.getSelectors().selectEntities;
export const getAllAgreements = adapter.getSelectors().selectAll;
export const getAgreementsTotal = adapter.getSelectors().selectTotal;

export const getAgreementsLoading = (state: AgreementsReducerState) =>
  state.loading;
export const getAgreementsLoaded = (state: AgreementsReducerState) =>
  state.loaded;
export const getAgreementsError = (state: AgreementsReducerState) =>
  state.error;
