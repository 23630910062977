import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

import {
  RouterActionTypes,
  RouterGo,
  RouterUpdateQueryParams,
} from 'src/app/core/store/actions/router.action';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {}

  
  navigate$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActionTypes.Go),
    map((action: RouterGo) => action.payload),
    tap(({ path, query: queryParams, extras }) => {
      this.router.navigate(path, { queryParams, ...extras });
    })
  ), { dispatch: false });

  
  updateUrlParams$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActionTypes.UpdateQueryParams),
    withLatestFrom(this.activatedRoute.queryParams),
    filter(([action, queryParams]: [RouterUpdateQueryParams, Params]) =>
      this.checkIfAnyUrlParamHasChanged(queryParams, action.payload.query)
    ),
    tap(([action, queryParams]: [RouterUpdateQueryParams, Params]) => {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          ...queryParams,
          ...action.payload.query,
        },
      });
    })
  ), { dispatch: false });

  
  navigateBack$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActionTypes.Back),
    tap(() => this.location.back())
  ), { dispatch: false });

  
  navigateForward$ = createEffect(() => this.actions$.pipe().pipe(
    ofType(RouterActionTypes.Forward),
    tap(() => this.location.forward())
  ), { dispatch: false });

  checkIfAnyUrlParamHasChanged(oldQueryParams, newQueryParams) {
    return Object.keys(newQueryParams).some(queryKey => {
      const newValue = newQueryParams[queryKey];
      const oldValue = oldQueryParams[queryKey];

      return (
        (this.checkIfValidValue(newValue) &&
          this.checkIfValuesDiffer(newValue, oldValue)) ||
        this.checkIfOldValueWasRemoved(oldValue, newValue)
      );
    });
  }

  private checkIfValidValue(value: any): boolean {
    return !!value || value === '';
  }

  private checkIfValuesDiffer(newValue: any, oldValue: string): boolean {
    return oldValue !== String(newValue).toString();
  }

  private checkIfOldValueWasRemoved(oldValue, newValue): boolean {
    return !!oldValue && !newValue;
  }
}
