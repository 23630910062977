import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { getSpaceTypesStateSelector } from 'src/app/dictionaries/store';

import { LoadSpaceTypes } from 'src/app/dictionaries/store/actions/space-types.action';
import { SpaceTypesReducerState } from 'src/app/dictionaries/store/reducers/space-types.reducer';

@Injectable()
export class SpaceTypesGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<SpaceTypesReducerState>) {}

  canActivate() {
    return this.waitForSpaceTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  canActivateChild() {
    return this.waitForSpaceTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForSpaceTypesToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getSpaceTypesStateSelector),
      tap((spaceTypesState: SpaceTypesReducerState) => {
        if (
          !spaceTypesState.loaded &&
          !spaceTypesState.loading &&
          !spaceTypesState.error
        ) {
          this.store.dispatch(new LoadSpaceTypes());
        }
      }),
      filter(
        (spaceTypesState: SpaceTypesReducerState) =>
          spaceTypesState.loaded || !!spaceTypesState.error
      ),
      map((spaceTypesState: SpaceTypesReducerState) => {
        if (!!spaceTypesState.error) {
          throw new Error(spaceTypesState.error.error.message);
        } else {
          return spaceTypesState.loaded;
        }
      }),
      take(1)
    );
  }
}
