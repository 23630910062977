import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AlertMessagesStatus } from 'src/app/core/enums/alert-messages-status.enum';
import { AlertMessagesTypes } from 'src/app/core/enums/alert-messages-types.enum';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';
import { PullingResponseI } from 'src/app/core/models/polling/pulling-response.interface';
import { SessionStorageService } from 'src/app/core/services/session-storage/session-storage.service';
import {
  AlertMessages,
  LoadMessagesSuccess,
  ShowMessage,
} from 'src/app/core/store/actions/alert-messages.action';
import {
  PullQueueActionTypes,
  SynchronizePullingQueueSuccess,
} from 'src/app/core/store/actions/pulling-queue.actions';
import { AlertMessagesReducerState } from 'src/app/core/store/reducers/alert-messages.reducer';
import { getAllAlertMessagesSelector } from 'src/app/core/store/selectors/alert-messages.selectors';
import { createId } from 'src/app/core/utils/id-utils';

@Injectable()
export class AlertMessagesEffects {
  alertMessagesKey = 'alertMessages';

  
  public loadMessagesFromSessionStorage$ = createEffect(() => this.actions$.pipe(
    ofType(AlertMessages.LoadMessages),
    map(() => this.sessionStorageService.getItem(this.alertMessagesKey) || []),
    map(messages => new LoadMessagesSuccess(messages))
  ));

  
  public updateSessionStorage$ = createEffect(() => this.actions$.pipe(
    ofType(
      AlertMessages.ShowMessage,
      AlertMessages.HideMessage,
      AlertMessages.ClearMessage,
      AlertMessages.ClearAllMessages
    ),
    withLatestFrom(this.store$.pipe(select(getAllAlertMessagesSelector))),
    tap(([action, alertMessages]) =>
      this.sessionStorageService.update({
        key: this.alertMessagesKey,
        value: alertMessages,
      })
    )
  ), { dispatch: false });

  
  showMessageAfterAssignmentsListReloadedSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(PullQueueActionTypes.SynchronizePullingQueueSuccess),
    map((action: SynchronizePullingQueueSuccess) => action.payload),
    filter(
      (pullingResponse: PullingResponseI[]) =>
        !!pullingResponse && pullingResponse.length > 0
    ),
    map((pullingResponse: PullingResponseI[]) =>
      this.createMessagesListFromPullingResponse(pullingResponse)
    ),
    switchMap((messages: ShowMessage[]) => of(...messages))
  ));

  constructor(
    private actions$: Actions,
    public sessionStorageService: SessionStorageService,
    public store$: Store<AlertMessagesReducerState>
  ) {}

  private createMessagesListFromPullingResponse(
    pullingResponseList: PullingResponseI[]
  ): ShowMessage[] {
    return pullingResponseList.map((pullingResponse: PullingResponseI) =>
      !!pullingResponse.errorMessage
        ? this.createFailMessageAction(pullingResponse)
        : this.createSuccessMessageAction(pullingResponse)
    );
  }

  private createSuccessMessageAction(
    pullingResponse: PullingResponseI
  ): ShowMessage {
    const alertMessage = this.createBaseAlertMessageFromAssignmentResource(
      pullingResponse
    );

    return new ShowMessage({
      ...alertMessage,
      title: 'alert.messages.updated.title',
      message: 'alert.messages.updated.message',
      type: AlertMessagesTypes.SUCCESS,
    });
  }

  private createFailMessageAction(
    pullingResponse: PullingResponseI
  ): ShowMessage {
    const alertMessage = this.createBaseAlertMessageFromAssignmentResource(
      pullingResponse
    );

    return new ShowMessage({
      ...alertMessage,
      title: 'alert.messages.error.title',
      message: 'alert.messages.error.message',
      errorReason: pullingResponse.errorReason,
      type: AlertMessagesTypes.DANGER,
    });
  }

  private createBaseAlertMessageFromAssignmentResource(
    pullingResponse: PullingResponseI
  ): AlertMessage {
    return {
      id: createId(),
      assignmentName: pullingResponse.assignmentName,
      assignmentId: pullingResponse.assignmentId,
      assignmentStatus: pullingResponse.status,
      timestamp: pullingResponse.responseTime,
      status: AlertMessagesStatus.UNREADED,
      title: null,
      message: null,
      type: null,
      errorReason: null,
    };
  }
}
