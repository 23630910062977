import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CalculationsI } from 'src/app/analysis/store/models/calculations.interface';
import { ChangeRequestRequestI } from 'src/app/analysis/store/models/change-request.interface';

import { CreateAssignmentDetailsI } from 'src/app/core/models/assignment/assignment-details.interface';
import { ChangeRequestModelI } from 'src/app/core/models/assignment/change-request-model.interface';
import { BookInspectionI } from 'src/app/core/models/book-inspection/book-inspection.interface';
import { ChangeOwnerRequestI } from 'src/app/core/models/change-owner/change-owner-request.interface';
import { DecisionRequestI } from 'src/app/core/models/decision/decision-request.interface';
import { AssignmentEditPayloadI } from 'src/app/core/models/edit-assignment/assignment-edit-payload.interface';
import { InviteContractorI } from 'src/app/core/models/invite/invite-contractor.interface';
import { PdfPayloadI } from 'src/app/core/models/pdf-payload.interface';
import { environment } from 'src/environments/environment';

@Injectable()
export class AssignmentsService {
  assignmentsUrl = environment.url + 'api/v1/assignment/';
  closeAssignmentUrl = this.assignmentsUrl + 'close/';
  removeAssignmentUrl = this.assignmentsUrl + 'remove/';
  reopenAssignmentUrl = this.assignmentsUrl + 'reopen/';

  editAssignmentUrl = this.assignmentsUrl + 'edit/';

  inviteUrl = this.assignmentsUrl + 'invite/';
  bookInspectionUrl = this.assignmentsUrl + 'inspection/';

  decisionUrl = this.assignmentsUrl + 'decision/';

  changeOwnerUrl = this.assignmentsUrl + 'change-owner/';

  assignmentCostUrl = this.assignmentsUrl + 'costs/';

  changeRequestCalculationsUrl =
    this.assignmentsUrl + 'change-request-calculations/';

  printPdfUrl = this.assignmentsUrl + 'contractor-operations';

  constructor(private http: HttpClient) {}

  startAssignment(assignment: CreateAssignmentDetailsI): Observable<number> {
    return this.http.post<number>(this.assignmentsUrl, assignment);
  }

  closeAssignment(assignmentId: number): Observable<number> {
    return this.http.post<number>(this.closeAssignmentUrl + assignmentId, {});
  }

  removeAssignment(assignmentId: number): Observable<number> {
    return this.http.post<number>(this.removeAssignmentUrl + assignmentId, {});
  }

  reopenAssignment(assignmentId: number): Observable<number> {
    return this.http.post<number>(this.reopenAssignmentUrl + assignmentId, {});
  }

  inviteContractor({
    agreementId,
    assignmentType,
    assignmentId,
    message,
  }: InviteContractorI): Observable<void> {
    return this.http.put<void>(this.inviteUrl + assignmentId, {
      agreementId,
      assignmentType,
      message,
    });
  }

  bookInspection(bookInspectionI: BookInspectionI) {
    return this.http.post<number>(this.bookInspectionUrl, bookInspectionI);
  }

  makeDecision(decision: DecisionRequestI): Observable<void> {
    return this.http.post<void>(
      this.decisionUrl + decision.assignmentId,
      decision.decisionResource
    );
  }

  changeOwner(
    request: ChangeOwnerRequestI,
    assignmentId: number
  ): Observable<void> {
    return this.http.put<void>(this.changeOwnerUrl + assignmentId, request);
  }

  editAssignment(payload: AssignmentEditPayloadI): Observable<void> {
    return this.http.put<void>(
      this.editAssignmentUrl + payload.assignmentId,
      payload.assignmentEdit
    );
  }

  getChangeRequest(
    changeRequestRequest: ChangeRequestRequestI
  ): Observable<ChangeRequestModelI> {
    let params = new HttpParams();
    Object.keys(changeRequestRequest)
      .filter((x) => x !== 'assignmentId')
      .filter((x) => !!changeRequestRequest[x])
      .forEach((x) => {
        params = params.append(x, changeRequestRequest[x]);
      });

    return this.http.get<ChangeRequestModelI>(
      this.assignmentCostUrl + changeRequestRequest.assignmentId,
      { params }
    );
  }

  getCalculations(assignmentId: number): Observable<CalculationsI> {
    return this.http.get<CalculationsI>(
      this.changeRequestCalculationsUrl + assignmentId
    );
  }

  printPdf(payload: PdfPayloadI): Observable<any> {
    return this.http.get<string>(
      `${this.printPdfUrl}/${payload.assignmentId}/${payload.newEventId}/${payload.oldEventId}`,
      {
        // @ts-ignore
        responseType: 'text',
      }
    );
  }
}
