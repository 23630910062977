// TODO: Add integration with our backend to dynamically load
// common types
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  DecisionTypesAction,
  DecisionTypesActionTypes,
} from 'src/app/dictionaries/store/actions/decision-types.action';

const selectId = (dictionary: DictionaryI) => dictionary.name;
const adapter: EntityAdapter<DictionaryI> = createEntityAdapter<DictionaryI>({
  selectId,
});

export interface DecisionTypesReducerState extends EntityState<DictionaryI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialDecisionTypesReducerState: DecisionTypesReducerState = adapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function decisionTypesReducer(
  state: DecisionTypesReducerState = initialDecisionTypesReducerState,
  action: DecisionTypesAction
): DecisionTypesReducerState {
  switch (action.type) {
    case DecisionTypesActionTypes.LoadDecisionTypes: {
      return {
        ...state,
        loading: true,
      };
    }

    case DecisionTypesActionTypes.LoadedDecisionTypesSuccess: {
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case DecisionTypesActionTypes.LoadedDecisionTypesFail: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case DecisionTypesActionTypes.CleanDecisionTypesState: {
      return {
        ...initialDecisionTypesReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getDecisionTypesIds = adapter.getSelectors().selectIds;
export const getDecisionTypesEntities = adapter.getSelectors().selectEntities;
export const getAllDecisionTypes = adapter.getSelectors().selectAll;
export const getDecisionTypesTotal = adapter.getSelectors().selectTotal;

export const getDecisionTypesLoading = (state: DecisionTypesReducerState) =>
  state.loading;
export const getDecisionTypesLoaded = (state: DecisionTypesReducerState) =>
  state.loaded;
export const getDecisionTypesError = (state: DecisionTypesReducerState) =>
  state.error;
