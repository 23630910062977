// TODO: Add integration with our backend to dynamically load
// common types
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  EventTypesAction,
  EventTypesActionTypes,
} from 'src/app/dictionaries/store/actions/event-types.action';

const selectId = (dictionary: DictionaryI) => dictionary.name;
const adapter: EntityAdapter<DictionaryI> = createEntityAdapter<DictionaryI>({
  selectId,
});

export interface EventTypesReducerState extends EntityState<DictionaryI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialEventTypesReducerState: EventTypesReducerState = adapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function eventTypesReducer(
  state: EventTypesReducerState = initialEventTypesReducerState,
  action: EventTypesAction
): EventTypesReducerState {
  switch (action.type) {
    case EventTypesActionTypes.LoadEventTypes: {
      return {
        ...state,
        loading: true,
      };
    }

    case EventTypesActionTypes.LoadedEventTypesSuccess: {
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case EventTypesActionTypes.LoadedEventTypesFail: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case EventTypesActionTypes.CleanEventTypesState: {
      return {
        ...initialEventTypesReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getEventTypesIds = adapter.getSelectors().selectIds;
export const getEventTypesEntities = adapter.getSelectors().selectEntities;
export const getAllEventTypes = adapter.getSelectors().selectAll;
export const getEventTypesTotal = adapter.getSelectors().selectTotal;

export const getEventTypesLoading = (state: EventTypesReducerState) =>
  state.loading;
export const getEventTypesLoaded = (state: EventTypesReducerState) =>
  state.loaded;
export const getEventTypesError = (state: EventTypesReducerState) =>
  state.error;
