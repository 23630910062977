import { AssignmentSortI } from 'src/app/core/models/assignment/assignment-sort.interface';

export enum SearchSortEnum {
  ASSIGNMENT_NAME_ASC = 'assignmentNameAsc',
  ASSIGNMENT_NAME_DESC = 'assignmentNameDesc',

  CREATED_DATE_ASC = 'createdDateAsc',
  CREATED_DATE_DESC = 'createdDateDesc',

  TOTAL_ASC = 'totalAsc',
  TOTAL_DESC = 'TotalDesc',
}

export function mapSearchSortEnumToSearchFilter(
  sort: SearchSortEnum
): AssignmentSortI {
  switch (sort) {
    case SearchSortEnum.ASSIGNMENT_NAME_ASC: {
      return {
        order: 'asc',
        field: 'assignmentName',
      };
    }
    case SearchSortEnum.ASSIGNMENT_NAME_DESC: {
      return {
        order: 'desc',
        field: 'assignmentName',
      };
    }
    case SearchSortEnum.CREATED_DATE_ASC: {
      return {
        order: 'asc',
        field: 'createdInUw',
      };
    }
    case SearchSortEnum.CREATED_DATE_DESC: {
      return {
        order: 'desc',
        field: 'createdInUw',
      };
    }
    case SearchSortEnum.TOTAL_ASC: {
      return {
        order: 'asc',
        field: 'costSummaryTotalCostExcludingVat',
      };
    }
    case SearchSortEnum.TOTAL_DESC: {
      return {
        order: 'desc',
        field: 'costSummaryTotalCostExcludingVat',
      };
    }
  }
}
