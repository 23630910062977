import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { BookInspectionI } from 'src/app/core/models/book-inspection/book-inspection.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum InspectionActionTypes {
  BookInspection = '[Inspection] Book Inspection',
  BookInspectionSuccess = '[Inspection] Booking Inspection Success',
  BookInspectionFail = '[Inspection] Failed to Book Inspection',
}

@ShowLoader()
export class BookInspection implements Action {
  readonly type = InspectionActionTypes.BookInspection;

  constructor(public payload: BookInspectionI) {}
}

@HideLoader(InspectionActionTypes.BookInspection)
export class BookInspectionSuccess implements Action {
  readonly type = InspectionActionTypes.BookInspectionSuccess;

  constructor(public payload: number) {}
}

@HideLoader(InspectionActionTypes.BookInspection)
export class BookInspectionFail implements Action {
  readonly type = InspectionActionTypes.BookInspectionFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export type BookActions =
  | BookInspection
  | BookInspectionSuccess
  | BookInspectionFail;
