import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthState } from 'src/app/auth/store';
import { CoreUrlEnums } from 'src/app/core/enums/url-paths.enum';
import { RouterGo } from 'src/app/core/store/actions/router.action';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';

@Component({
  selector: 'app-blocked',
  templateUrl: './blocked.component.html',
  styleUrls: ['./blocked.component.scss'],
})
export class BlockedComponent extends StoreDispatcher<any> implements OnInit {
  constructor(store: Store<AuthState>) {
    super(store);
  }

  ngOnInit() {}

  goToLoginPage() {
    this.dispatchAction(
      new RouterGo({
        path: [CoreUrlEnums.LOGIN],
      })
    );
  }
}
