import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TypesService {
  apiUrl = environment.url + 'api/v1/types/';

  spaceTypesUrl = this.apiUrl + 'space';
  realEstateTypesUrl = this.apiUrl + 'real-estate';
  establishmentTypesUrl = this.apiUrl + 'establishment';
  clientAssignmentTypesUrl = this.apiUrl + 'client-assignment';
  assignmentTypesUrl = this.apiUrl + 'assignment';
  eventTypesUrl = this.apiUrl + 'assignment-event';
  decisionTypesUrl = this.apiUrl + 'decision';
  operationWorkTypesUrl = this.apiUrl + 'operation-work';
  tradeTypesUrl = this.apiUrl + 'trade';

  constructor(private http: HttpClient) {}

  getSpaceTypes(): Observable<DictionaryI[]> {
    return this.http.get<DictionaryI[]>(this.spaceTypesUrl);
  }

  getRealEstateTypes(): Observable<DictionaryI[]> {
    return this.http.get<DictionaryI[]>(this.realEstateTypesUrl);
  }

  getEstablishmentTypes(): Observable<DictionaryI[]> {
    return this.http.get<DictionaryI[]>(this.establishmentTypesUrl);
  }

  getAssignmentTypes(): Observable<DictionaryI[]> {
    return this.http.get<DictionaryI[]>(this.assignmentTypesUrl);
  }

  getClientAssignmentTypes(): Observable<DictionaryI[]> {
    return this.http.get<DictionaryI[]>(this.clientAssignmentTypesUrl);
  }

  getEventTypes(): Observable<DictionaryI[]> {
    return this.http.get<DictionaryI[]>(this.eventTypesUrl);
  }

  getDecisionTypes(): Observable<DictionaryI[]> {
    return this.http.get<DictionaryI[]>(this.decisionTypesUrl);
  }

  getOperationWorkTypes(): Observable<DictionaryI[]> {
    return this.http.get<DictionaryI[]>(this.operationWorkTypesUrl);
  }

  getTradeTypes(): Observable<DictionaryI[]> {
    return this.http.get<DictionaryI[]>(this.tradeTypesUrl);
  }
}
