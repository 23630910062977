import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ChangeOwnerPayloadI } from 'src/app/core/models/change-owner-payload.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum ChangeOwnerActionTypes {
  ChangeOwner = '[ChangeOwner] ChangeOwner',
  ChangeOwnerSuccess = '[ChangeOwner] ChangeOwner Success',
  ChangeOwnerFail = '[ChangeOwner] ChangeOwner Fail',
}

@ShowLoader()
export class ChangeOwner implements Action {
  readonly type = ChangeOwnerActionTypes.ChangeOwner;

  constructor(public payload: ChangeOwnerPayloadI) {}
}

@HideLoader(ChangeOwnerActionTypes.ChangeOwner)
export class ChangeOwnerSuccess implements Action {
  readonly type = ChangeOwnerActionTypes.ChangeOwnerSuccess;

  constructor(public payload: number) {}
}

@HideLoader(ChangeOwnerActionTypes.ChangeOwner)
export class ChangeOwnerFail implements Action {
  readonly type = ChangeOwnerActionTypes.ChangeOwnerFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export type ChangeOwnerActions =
  | ChangeOwner
  | ChangeOwnerSuccess
  | ChangeOwnerFail;
