<div class="mb-1 mt-5">
  <div class="row mb-3 justify-content-between">
    <div class="col-6 col-md-10">
      <p class="lead mb-0" [translate]="title"></p>
    </div>
    <div class="col-3 col-md-1">
      <div *ngIf="expandable">
        <div class="text-center">
          <span
            class="button pointer float-right"
            (click)="collapsed = !collapsed"
            *ngIf="collapsed; else up"
            [translate]="'common.buttons.expand'"
          >
          </span>
          <ng-template #up>
            <span
              class="button pointer float-right"
              (click)="collapsed = !collapsed"
              [translate]="'common.buttons.collapse'"
            ></span>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="col-3 col-md-1">
      <div *ngIf="!!templateComponent">
        <span
          class="button pointer float-right"
          (click)="onEditClicked()"
          [translate]="'common.buttons.edit'"
        ></span>
      </div>
    </div>
  </div>
</div>

<div class="shadow p-2 p-md-4 mt-4">
  <div id="collapseBasic" [collapse]="collapsed" [isAnimated]="true">
    <ng-content></ng-content>
  </div>
</div>
