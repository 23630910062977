<app-a2hs
  [disablePWA]="disablePWA$ | async"
  (redirectToSupport)="onRedirectToSupport($event)"
  (skipPWA)="onSkipPWA($event)"
  (decisionPWA)="dispatchAction($event)"
></app-a2hs>

<ngx-spinner
  class="spinner"
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#fff"
  type="ball-elastic-dots"
>
  <p style="font-size: 20px; color: white" [translate]="'loading'"></p>
</ngx-spinner>

<app-header></app-header>

<div class="app" [ngClass]="{ 'without-sidebar': !doesTokenExists }">
  <div class="sidebar d-none d-md-block" *ngIf="doesTokenExists">
    <app-side-bar></app-side-bar>
  </div>

  <div class="app-content">
    <div class="container-fluid">
      <div class="row ml-1 mr-1 ml-xl-4 mr-xl-4 mt-5 mb-5">
        <div class="col-12">
          <app-missing-external-id
            *ngIf="!(isExternalIdSet$ | async)"
            [errorMessage]="noExternalIdErrorMessage$ | async"
          >
          </app-missing-external-id>

          <app-alert-messages
            [alerts]="alerts$ | async"
            [assignmentDeletedMessage]="assignmentDeletedMessage$ | async"
            (assignmentDetails)="dispatchAction($event)"
            (dismissed)="dispatchAction($event)"
          >
          </app-alert-messages>
          <div class="mb-5">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bottom-bar d-block d-md-none" *ngIf="doesTokenExists">
  <app-bottom-bar></app-bottom-bar>
</div>
