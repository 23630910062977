import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { AuthorizationService } from 'src/app/auth/services/authorization/authorization.service';
import { getAlertMessagesStateSelector } from 'src/app/core/store';
import { LoadMessages } from 'src/app/core/store/actions/alert-messages.action';
import { AlertMessagesReducerState } from 'src/app/core/store/reducers/alert-messages.reducer';

@Injectable()
export class AlertMessagesGuard implements CanActivate {
  constructor(
    private store: Store<AlertMessagesReducerState>,
    private authorizationService: AuthorizationService
  ) {}

  canActivate() {
    return this.authorizationService.isTokenValid()
      ? this.waitForAlertsToLoad().pipe(
          switchMap(() => of(true)),
          catchError(() => of(false))
        )
      : of(true);
  }

  waitForAlertsToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getAlertMessagesStateSelector),
      tap((alertMessagesState: AlertMessagesReducerState) => {
        if (
          !alertMessagesState.loaded &&
          !alertMessagesState.loading &&
          !alertMessagesState.error
        ) {
          this.store.dispatch(new LoadMessages());
        }
      }),
      filter(
        (alertMessagesState: AlertMessagesReducerState) =>
          alertMessagesState.loaded || !!alertMessagesState.error
      ),
      map((alertMessagesState: AlertMessagesReducerState) => {
        if (!!alertMessagesState.error) {
          throw new Error(alertMessagesState.error.error.message);
        } else {
          return alertMessagesState.loaded;
        }
      }),
      take(1)
    );
  }
}
