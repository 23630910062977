import { AppPhaseChartInterface } from 'src/app/core/models/app-phase-chart.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import {
  AssignmentPhaseChartAction,
  AssignmentPhaseChartActionTypes,
} from 'src/app/core/store/actions/assignment-phase-chart.action';

export interface AssignmentPhaseChartReducerState {
  charts: AppPhaseChartInterface;

  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialAssignmentPhaseChartReducerState: AssignmentPhaseChartReducerState = {
  charts: null,

  loading: false,
  loaded: false,
  error: null,
};

export function assignmentPhaseChartReducer(
  state: AssignmentPhaseChartReducerState = initialAssignmentPhaseChartReducerState,
  action: AssignmentPhaseChartAction
): AssignmentPhaseChartReducerState {
  switch (action.type) {
    case AssignmentPhaseChartActionTypes.LoadAssignmentPhaseChart: {
      return {
        ...state,
        loading: true,
      };
    }

    case AssignmentPhaseChartActionTypes.LoadedAssignmentPhaseChartSuccess: {
      return {
        ...state,
        charts: action.payload,
        loading: false,
        loaded: true,
      };
    }

    case AssignmentPhaseChartActionTypes.LoadedAssignmentPhaseChartFail: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case AssignmentPhaseChartActionTypes.CleanAssignmentPhaseChartState: {
      return {
        ...initialAssignmentPhaseChartReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getAssignmentPhaseCharts = (
  state: AssignmentPhaseChartReducerState
) => state.charts;

export const getAssignmentPhaseChartsLoading = (
  state: AssignmentPhaseChartReducerState
) => state.loading;
export const getAssignmentPhaseChartsLoaded = (
  state: AssignmentPhaseChartReducerState
) => state.loaded;
export const getAssignmentPhaseChartsError = (
  state: AssignmentPhaseChartReducerState
) => state.error;
