import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { AppCountsResponseI } from 'src/app/core/models/app-counts.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum AssignmentCountActionTypes {
  LoadAssignmentCount = '[AssignmentsCount] Load Assignment Count',
  LoadedAssignmentCountSuccess = '[AssignmentsCount] Loaded Assignment Count Success',
  LoadedAssignmentCountFail = '[AssignmentsCount] Fail To Load Assignment Count',

  CleanAssignmentCountState = '[AssignmentsCount] Clean Assignment Count',
}

@ShowLoader()
export class LoadAssignmentCount implements Action {
  readonly type = AssignmentCountActionTypes.LoadAssignmentCount;
}

@HideLoader(AssignmentCountActionTypes.LoadAssignmentCount)
export class LoadedAssignmentCountSuccess implements Action {
  readonly type = AssignmentCountActionTypes.LoadedAssignmentCountSuccess;

  constructor(public payload: AppCountsResponseI) {}
}

@HideLoader(AssignmentCountActionTypes.LoadAssignmentCount)
export class LoadedAssignmentCountFail implements Action {
  readonly type = AssignmentCountActionTypes.LoadedAssignmentCountFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanAssignmentCountState implements Action {
  readonly type = AssignmentCountActionTypes.CleanAssignmentCountState;
}

export type AssignmentsCountActions =
  | LoadAssignmentCount
  | LoadedAssignmentCountSuccess
  | LoadedAssignmentCountFail
  | CleanAssignmentCountState;
