export function getValueFromObjectOrReplaceWithDefault(
  object: object,
  value: string,
  defaultValue?: any
) {
  return !!object ? object[value] : defaultValue;
}

export function getErrorMessage(e: any) {
  return !!e && !!e.message ? e.message : e;
}
