import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { enGbLocale, svLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';

import { LanguageEnum } from 'src/app/core/enums/language.enum';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage.service';
import {
  ChangedLanguage,
  LanguageActionTypes,
  SetDefaultLanguage,
  SetedDefaultLanguage,
} from 'src/app/core/store/actions/language.action';
import { LanguageState } from 'src/app/core/store/reducers/language.reducer';
import { getSelectedLanguageSelector } from 'src/app/core/store/selectors/language.selectors';

@Injectable()
export class LanguageEffects {
  constructor(
    private translateService: TranslateService,
    private store: Store<LanguageState>,
    private localStorageService: LocalStorageService,
    private localeService: BsLocaleService,
    private actions$: Actions
  ) {}

   setDefaultLanguage$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(LanguageActionTypes.SetDefaultLanguage),
    withLatestFrom(this.store.pipe(select(getSelectedLanguageSelector))),
    map(
      ([action, language]) => this.localStorageService.getLanguage() || language
    ),
    tap((language) => {
      this.translateService.setDefaultLang(language);
      this.setBootstrapLanguage(language);
    }),
    map((language) => {
      return new SetedDefaultLanguage(language);
    })
  ));

   changeLanguage$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(LanguageActionTypes.ChangeLanguage),
    map((action: SetDefaultLanguage) => action.payload),
    tap((language) => {
      this.translateService.use(language);
      this.setBootstrapLanguage(language);
    }),
    map((language) => {
      return new ChangedLanguage(language);
    })
  ));

   handleLanguageModification$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActionTypes.ChangedLanguage),
    map((action: ChangedLanguage) => action.payload),
    tap((language) => this.localStorageService.updateLanguage(language))
  ), { dispatch: false });

  // TODO: Consider changing naming convention for language
  // to match it to bootstrap pattern in that case this switch
  // will be not needed any more
  private setBootstrapLanguage(language: string) {
    switch (language) {
      case LanguageEnum.SE: {
        this.localeService.use(svLocale.abbr);
        break;
      }
      case LanguageEnum.EN: {
        this.localeService.use(enGbLocale.abbr);
        break;
      }
    }
  }
}
