<div class="toast" *ngIf="showPanel">
  <div class="toast-header">
    <strong class="mr-auto" [translate]="'pwa.header'"></strong>
    <button type="button" class="ml-2 mb-1 close" (click)="hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="toast-body">
    <span class="text" [translate]="'pwa.info'"></span>
    <div class="btn-row">
      <div
        class="add-button pointer"
        (click)="isIos ? showInstructions() : addToHomeScreen()"
        [translate]="isIos ? 'pwa.showInfoButton' : 'pwa.installButton'"
      ></div>
      <div
        class="disable-button pointer"
        (click)="disablePWAPanel()"
        [translate]="'pwa.disableButton'"
      ></div>
    </div>
  </div>
</div>
