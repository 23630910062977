import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreUrlEnums } from 'src/app/core/enums/url-paths.enum';
import { HideLauncher } from 'src/app/core/store/actions/support.action';
import { SupportReducerState } from 'src/app/core/store/reducers/support.reducer';
import { isLauncherVisibleSelector } from 'src/app/core/store/selectors/support.selectors';

@Injectable()
export class SupportDeactivateGuard implements CanDeactivate<any> {
  constructor(private store: Store<SupportReducerState>) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(isLauncherVisibleSelector),
      map((isLauncherVisible: boolean) => {
        if (
          isLauncherVisible &&
          !(
            nextState.url.includes(`/${CoreUrlEnums.SUPPORT}`) ||
            nextState.url.includes(`/${CoreUrlEnums.LOGIN}`)
          )
        ) {
          this.store.dispatch(new HideLauncher());
        }
        return true;
      })
    );
  }
}
