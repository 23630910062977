import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
/**
 * Intercepts the HTTP responses, and in case that an error/exception is thrown, handles it
 * and extract the relevant information of it.
 */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Blocked, Unauthorized } from 'src/app/auth/store/actions/auth.action';
import { UnregisteredUser } from 'src/app/auth/store/actions/user.action';
import { ErrorEnum } from 'src/app/core/enums/error.enum';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { ErrorI } from 'src/app/core/models/error.interface';
import { GoogleAnalyticsService } from 'src/app/core/services/google/analytics/google-analytics.service';
import { ApplicationState } from 'src/app/core/store';
import { createId } from 'src/app/core/utils/id-utils';
import { ErrorTranslationService } from 'src/app/dictionaries/services/error-translations.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  unknownError = 'Unknown Error';
  unknownErrorTranslationKey = 'common.unknownError';

  constructor(
    private store: Store<ApplicationState>,
    private translateService: TranslateService,
    private errorTranslationService: ErrorTranslationService,
    protected googleAnalyticsService: GoogleAnalyticsService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        const errorWithId: ErrorResponseWithIdI = {
          ...error,
          id: createId(),
        };

        if (error instanceof HttpErrorResponse) {
          this.handleHttpError(errorWithId);
        }
        this.googleAnalyticsService.httpError({
          message: errorWithId.error.message || error.message,
          reason: errorWithId.error.reason || error.statusText,
        });

        return throwError(errorWithId);
      })
    );
  }

  private handleHttpError(error: ErrorResponseWithIdI) {
    switch (error.status) {
      case 400: {
        error.error.reason === ErrorEnum.ASSIGNMENT_DELETED
          ? console.log('AD')
          : this.showAlertWithTranslatedMessage(error.error);

        break;
      }
      case 307: {
        this.store.dispatch(new UnregisteredUser());
        break;
      }
      case 401: {
        this.store.dispatch(new Unauthorized(error));

        break;
      }

      case 403: {
        this.store.dispatch(new Blocked());

        break;
      }

      default: {
        this.showAlertWithTranslatedMessage(error.error);
        break;
      }
    }
  }

  private showAlertWithTranslatedMessage(error: ErrorI): void {
    const translatedMessageOrReason =
      error && error.reason
        ? this.getErrorMessageFromDictionary(error.reason)
        : this.getErrorMessageFromTranslations();

    const notificationBody = this.createAlertNotificationBodyFromMessage(
      translatedMessageOrReason,
      error.timestamp
    );

    alert(notificationBody);
  }

  private getErrorMessageFromTranslations(): string {
    return this.translateService.instant(this.unknownErrorTranslationKey);
  }

  private getErrorMessageFromDictionary(reason: string): string | number {
    return this.errorTranslationService.getErrorReasonFromDictionary(reason);
  }

  private createAlertNotificationBodyFromMessage(
    message: string | number,
    timestamp: number
  ) {
    const date = !!timestamp ? new Date(timestamp) : new Date();

    return `${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}\n${message}`;
  }
}
