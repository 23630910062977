import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ExcelService } from 'src/app/core/services/api/excel/excel.service';
import { Base64Service } from 'src/app/core/services/base64/base64.service';
import {
  ExcelActionTypes,
  ExportToAssignmentEventsExcel,
  ExportToAssignmentEventsExcelError,
  ExportToAssignmentEventsExcelSuccess,
  ExportToExcel,
  ExportToExcelError,
  ExportToExcelSuccess,
  ExportToProjectSimpleExcel,
  ExportToProjectSimpleExcelError,
  ExportToProjectSimpleExcelSuccess,
  ExportToSimpleExcel,
  ExportToSimpleExcelError,
  ExportToSimpleExcelSuccess,
} from 'src/app/core/store/actions/excel.action';
import { getErrorMessage } from 'src/app/core/utils/core-utils';

@Injectable()
export class ExcelEffects {
  constructor(
    private actions$: Actions,
    private excelService: ExcelService,
    private base64Service: Base64Service
  ) {}

  public exportToExcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExcelActionTypes.ExportToExcel),
      switchMap((action: ExportToExcel) =>
        this.excelService.exportExcel(action.payload.assignmentIds).pipe(
          tap((file) =>
            this.base64Service.saveToSystem(action.payload.fileName, file)
          ),
          map((x) => new ExportToExcelSuccess()),
          catchError((e) => of(new ExportToExcelError(getErrorMessage(e))))
        )
      )
    )
  );

  public exportToSimpleExcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExcelActionTypes.ExportToSimpleExcel),
      switchMap((action: ExportToSimpleExcel) =>
        this.excelService.exportSimpleExcel(action.payload.assignmentIds).pipe(
          tap((file) =>
            this.base64Service.saveToSystem(action.payload.fileName, file)
          ),
          map((x) => new ExportToSimpleExcelSuccess()),
          catchError((e) =>
            of(new ExportToSimpleExcelError(getErrorMessage(e)))
          )
        )
      )
    )
  );

  public exportToAssignmentEventsExcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExcelActionTypes.ExportToAssignmentEventsExcel),
      switchMap((action: ExportToAssignmentEventsExcel) =>
        this.excelService
          .exportToAssignmentEventsExcel(action.payload.assignmentIds)
          .pipe(
            tap((file) =>
              this.base64Service.saveToSystem(action.payload.fileName, file)
            ),
            map((x) => new ExportToAssignmentEventsExcelSuccess()),
            catchError((e) =>
              of(new ExportToAssignmentEventsExcelError(getErrorMessage(e)))
            )
          )
      )
    )
  );

  public exportToProjectSimpleExcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExcelActionTypes.ExportToProjectSimpleExcel),
      switchMap((action: ExportToProjectSimpleExcel) =>
        this.excelService.exportToProjectSimpleExcel(action.payload).pipe(
          tap((file) =>
            this.base64Service.saveToSystem(`project_${action.payload}`, file)
          ),
          map((x) => new ExportToProjectSimpleExcelSuccess()),
          catchError((e) =>
            of(new ExportToProjectSimpleExcelError(getErrorMessage(e)))
          )
        )
      )
    )
  );
}
