<span *ngIf="_selectedAssignmentResourceList?.length === 1">
  <li class="menuitem">
    <a
      class="dropdown-item"
      *ngIf="!!_selectedAssignmentResourceList[0]?.calculation"
      [translate]="'dropdown-menu.costSummary'"
      (click)="
        onGoToCostSummary(
          _selectedAssignmentResourceList[0]?.details?.assignmentId
        )
      "
    >
    </a>
  </li>
  <li class="menuitem">
    <a
      class="dropdown-item"
      [translate]="'dropdown-menu.log'"
      (click)="
        onGoToLog(_selectedAssignmentResourceList[0]?.details?.assignmentId)
      "
    >
    </a>
  </li>

  <li class="divider dropdown-divider"></li>

  <li class="menuitem">
    <a
      class="dropdown-item"
      [translate]="'dropdown-menu.meps'"
      (click)="onGoToMeps(_selectedAssignmentResourceList[0]?.details?.mepsId)"
    >
    </a>
  </li>
</span>

<span *ngIf="_selectedAssignmentResourceList?.length > 1">
  <li class="menuitem">
    <a
      [tooltip]="'dropdown-menu.unableToCompareAssignments' | translate"
      [placement]="'top'"
      [isDisabled]="!shouldDisableComparision()"
    >
      <button
        class="dropdown-item"
        [ngClass]="{ disabled: shouldDisableComparision() }"
        [translate]="'dropdown-menu.compareAssignments'"
        (click)="onGoToCompareAssignments()"
      ></button>
    </a>
  </li>
</span>

<span *ngIf="_selectedAssignmentResourceList?.length > 0">
  <li class="divider dropdown-divider"></li>

  <li class="menuitem">
    <a
      class="dropdown-item"
      [translate]="'dropdown-menu.exportToExcel'"
      (click)="onExportToExcel()"
    >
    </a>
  </li>
  <li class="menuitem">
    <a
      class="dropdown-item"
      [translate]="'dropdown-menu.exportToSimpleExcel'"
      (click)="onExportToSimpleExcel()"
    >
    </a>
  </li>
</span>

<span
  *ngIf="
    _selectedAssignmentResourceList?.length === 0 && _assignmentIds.length > 0
  "
>
  <li class="menuitem">
    <a
      class="dropdown-item"
      [translate]="'dropdown-menu.exportAllToExcel'"
      (click)="onExportAllToExcel()"
    >
    </a>
  </li>
  <li class="menuitem">
    <a
      class="dropdown-item"
      [translate]="'dropdown-menu.exportAllToSimpleExcel'"
      (click)="onExportAllToSimpleExcel()"
    >
    </a>
  </li>
</span>
