import { Action, Store } from '@ngrx/store';

export class StoreDispatcher<T extends Object> {
  constructor(public store: Store<T>) {}

  dispatchAction(action: Action) {
    this.store.dispatch(action);
  }

  dispatchActions(actions: Action[]) {
    actions.forEach(action => this.dispatchAction(action));
  }
}
