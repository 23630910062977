import { InjectionToken } from '@angular/core';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import {
  ActionReducerMap,
  combineReducers,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { clearState } from 'src/app/core/store/meta-reducers/clear-state';

import {
  agreementsReducer,
  AgreementsReducerState,
} from 'src/app/core/store/reducers/agreements.reducer';
import {
  alertMessagesReducer,
  AlertMessagesReducerState,
} from 'src/app/core/store/reducers/alert-messages.reducer';
import {
  assignmentCountReducer,
  AssignmentCountReducerState,
} from 'src/app/core/store/reducers/assignment-count.reducer';
import {
  assignmentPhaseChartReducer,
  AssignmentPhaseChartReducerState,
} from 'src/app/core/store/reducers/assignment-phase-chart.reducer';
import {
  assignmentVolumeChartReducer,
  AssignmentVolumeChartReducerState,
} from 'src/app/core/store/reducers/assignment-volume-chart.reducer';

import {
  assignmentsReducer,
  AssignmentsReducerState,
} from 'src/app/core/store/reducers/assignments.reducer';
import {
  companyUsersReducer,
  CompanyUsersReducerState,
} from 'src/app/core/store/reducers/company-users.reducer';

import {
  googleAnalyticsReducer,
  GoogleAnalyticsReducerState,
} from 'src/app/core/store/reducers/google-analytics.reducer';

import {
  LanguageState,
  languageStateReducer,
} from 'src/app/core/store/reducers/language.reducer';
import {
  modalReducer,
  ModalReducerState,
} from 'src/app/core/store/reducers/modal.reducer';

import {
  pullingQueueReducer,
  PullingQueueReducerState,
} from 'src/app/core/store/reducers/pulling-queue.reducer';
import {
  PWAState,
  pwaStateReducer,
} from 'src/app/core/store/reducers/pwa.reducer';
import { RouterStateUrl } from 'src/app/core/store/reducers/router.reducer';
import {
  spinnerReducer,
  SpinnerReducerState,
} from 'src/app/core/store/reducers/spinner.reducer';
import {
  supportReducer,
  SupportReducerState,
} from 'src/app/core/store/reducers/support.reducer';
import { environment } from 'src/environments/environment';

/**
 * Interface which represent our store structure
 * ApplicationState can be imagine as DB
 * uiState can be imagine as table
 */
export interface ApplicationState {
  state: State;
  data: Data;
}

export interface Data {
  count: AssignmentCountReducerState;
  volumeChart: AssignmentVolumeChartReducerState;
  phaseChart: AssignmentPhaseChartReducerState;
  companyUsers: CompanyUsersReducerState;
  agreements: AgreementsReducerState;
  assignments: AssignmentsReducerState;
}

export interface State {
  language: LanguageState;
  pwa: PWAState;
  router: RouterReducerState<RouterStateUrl>;
  spinner: SpinnerReducerState;
  alertMessages: AlertMessagesReducerState;
  pullingQueue: PullingQueueReducerState;
  googleAnalytics: GoogleAnalyticsReducerState;
  modal: ModalReducerState;
  support: SupportReducerState;
}

/**
 * Store composed from reducers ( small parts of store )
 * this is place where our data will be stored base on logic
 * applied in reducers
 */
export const stateReducers = combineReducers({
  language: languageStateReducer,
  pwa: pwaStateReducer,
  router: routerReducer,
  spinner: spinnerReducer,
  alertMessages: alertMessagesReducer,
  pullingQueue: pullingQueueReducer,
  googleAnalytics: googleAnalyticsReducer,
  modal: modalReducer,
  support: supportReducer,
});

export const dataReducers = combineReducers({
  count: assignmentCountReducer,
  volumeChart: assignmentVolumeChartReducer,
  phaseChart: assignmentPhaseChartReducer,
  companyUsers: companyUsersReducer,
  agreements: agreementsReducer,
  assignments: assignmentsReducer,
});

export const reducerToken = new InjectionToken<
  ActionReducerMap<ApplicationState>
>('Reducers');

export function getReducers() {
  return {
    state: stateReducers,
    data: dataReducers,
  };
}

export const reducerProvider = [
  { provide: reducerToken, useFactory: getReducers },
];

/**
 * This is are additional reducers which are used only in dev env to help us
 * logger will log for us all dispatched actions in console
 * storeFreeze will make sure that we are not mutating object in store
 */
export const metaReducers: MetaReducer<
  ApplicationState
>[] = !environment.production
  ? <MetaReducer<ApplicationState>[]>[clearState]
  : [clearState];

/**
 * Feature Selectors
 */
export const getApplicationStateSelector = createFeatureSelector<State>(
  'state'
);
export const getApplicationDataSelector = createFeatureSelector<Data>('data');

export const getLanguageStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.language
);
export const getPWAStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.pwa
);
export const getRouterStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.router
);
export const getSpinnerStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.spinner
);
export const getAlertMessagesStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.alertMessages
);
export const getPullingQueueStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.pullingQueue
);
export const getGoogleAnalyticsStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.googleAnalytics
);
export const getModalStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.modal
);
export const getSupportStateSelector = createSelector(
  getApplicationStateSelector,
  (applicationState: State) => applicationState.support
);

export const getAssignmentCountStateSelector = createSelector(
  getApplicationDataSelector,
  (applicationState: Data) => applicationState.count
);
export const getAssignmentVolumeChartStateSelector = createSelector(
  getApplicationDataSelector,
  (applicationState: Data) => applicationState.volumeChart
);
export const getAssignmentPhaseChartStateSelector = createSelector(
  getApplicationDataSelector,
  (applicationState: Data) => applicationState.phaseChart
);

export const getCompanyUsersStateSelector = createSelector(
  getApplicationDataSelector,
  (applicationState: Data) => applicationState.companyUsers
);
export const getAgreementsStateSelector = createSelector(
  getApplicationDataSelector,
  (applicationState: Data) => applicationState.agreements
);
export const getAssignmentsStateSelector = createSelector(
  getApplicationDataSelector,
  (applicationState: Data) => applicationState.assignments
);
