import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';
import { AppCountsResponseI } from 'src/app/core/models/app-counts.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AssignmentAppApiService } from 'src/app/core/services/api/assignment-app-api/assignment-app-api.service';
import {
  AssignmentCountActionTypes,
  CleanAssignmentCountState,
  LoadAssignmentCount,
  LoadedAssignmentCountFail,
  LoadedAssignmentCountSuccess,
} from 'src/app/core/store/actions/assignment-count.action';

@Injectable()
export class AssignmentCountEffects {
  constructor(
    private actions$: Actions,
    private assignmentAppService: AssignmentAppApiService
  ) {}

  
  count$ = createEffect(() => this.actions$.pipe(
    ofType(AssignmentCountActionTypes.LoadAssignmentCount),
    switchMap((loadAssignmentCount: LoadAssignmentCount) =>
      this.assignmentAppService.getCounts().pipe(
        map(
          (count: AppCountsResponseI) => new LoadedAssignmentCountSuccess(count)
        ),
        catchError((error: ErrorResponseWithIdI) =>
          of(new LoadedAssignmentCountFail(error))
        )
      )
    )
  ));

  
  handleLoadedCountFail$ = createEffect(() => this.actions$.pipe(
    ofType(AssignmentCountActionTypes.LoadedAssignmentCountFail),
    map(() => new CleanAssignmentCountState())
  ));
}
