import { Action } from '@ngrx/store';
import { UserRegisterI } from 'src/app/core/models/user/user-register.interface';
import {
  MeResourceI,
  UserPublicI,
} from 'src/app/core/models/user/user-resource.interface';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum UserActionTypes {
  LoadUserResource = '[User] Load User Resource',
  LoadedUserResourceSuccess = '[User] Loaded User Resource',
  LoadedUserResourceFail = '[User] Fail To Load User Resource',

  UnregisteredUser = '[User] Unregistered User',
  RegisterUser = '[User] Register User',
  RegisterUserSuccess = '[User] Register User Success',
  RegisterUserFail = '[User] Register User Fail',

  UpdateUser = '[User] Update User',
  UpdateUserSuccess = '[User] Update User Success',
  UpdateUserFail = '[User] Update User Fail',

  ResendEmailToUser = '[User] Resend Email To User',
  ResendEmailToUserSuccess = '[User] Resend Email To User Success',
  ResendEmailToUserFail = '[User] Resend Email To User Fail',

  CleanUserState = '[User] Clean',
}

// Load
@ShowLoader()
export class LoadUserResource implements Action {
  readonly type = UserActionTypes.LoadUserResource;

  constructor(public payload?: any) {}
}

@HideLoader(UserActionTypes.LoadUserResource)
export class LoadedUserResourceSuccess implements Action {
  readonly type = UserActionTypes.LoadedUserResourceSuccess;

  constructor(public payload: MeResourceI) {}
}

@HideLoader(UserActionTypes.LoadUserResource)
export class LoadedUserResourceFail implements Action {
  readonly type = UserActionTypes.LoadedUserResourceFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

// Register
export class UnregisteredUser implements Action {
  readonly type = UserActionTypes.UnregisteredUser;
}

@ShowLoader()
export class RegisterUser implements Action {
  readonly type = UserActionTypes.RegisterUser;

  constructor(public payload: UserRegisterI) {}
}

@HideLoader(UserActionTypes.RegisterUser)
export class RegisterUserSuccess implements Action {
  readonly type = UserActionTypes.RegisterUserSuccess;

  constructor(public payload?: any) {}
}

@HideLoader(UserActionTypes.RegisterUser)
export class RegisterUserFail implements Action {
  readonly type = UserActionTypes.RegisterUserFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

// Update
@ShowLoader()
export class UpdateUser implements Action {
  readonly type = UserActionTypes.UpdateUser;

  constructor(public payload: UserPublicI) {}
}

@HideLoader(UserActionTypes.UpdateUser)
export class UpdateUserSuccess implements Action {
  readonly type = UserActionTypes.UpdateUserSuccess;

  constructor(public payload?: any) {}
}

@HideLoader(UserActionTypes.UpdateUser)
export class UpdateUserFail implements Action {
  readonly type = UserActionTypes.UpdateUserFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

// Resend
@ShowLoader()
export class ResendEmailToUser implements Action {
  readonly type = UserActionTypes.ResendEmailToUser;

  constructor(public payload: number) {}
}

@HideLoader(UserActionTypes.ResendEmailToUser)
export class ResendEmailToUserSuccess implements Action {
  readonly type = UserActionTypes.ResendEmailToUserSuccess;

  constructor(public payload?: any) {}
}

@HideLoader(UserActionTypes.ResendEmailToUser)
export class ResendEmailToUserFail implements Action {
  readonly type = UserActionTypes.ResendEmailToUserFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

// Clean
export class CleanUserState implements Action {
  readonly type = UserActionTypes.CleanUserState;

  constructor(public payload?: any) {}
}

export type UserActions =
  | UnregisteredUser
  | RegisterUser
  | RegisterUserSuccess
  | RegisterUserFail
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFail
  | LoadUserResource
  | LoadedUserResourceSuccess
  | LoadedUserResourceFail
  | CleanUserState;
