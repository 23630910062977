import { Action } from '@ngrx/store';
import { ModalPayloadI } from 'src/app/core/models/modal-payload.interface';

export enum ModalActionTypes {
  ShowModal = '[Modal] Show Modal',
  HideModal = '[Modal] Hide Modal',
  HiddenModal = '[Modal] Hidden Modal',
  CleanModalState = '[Modal] Clean Modal State',
}

export class ShowModal implements Action {
  readonly type = ModalActionTypes.ShowModal;

  constructor(public payload: ModalPayloadI) {}
}

export class HideModal implements Action {
  readonly type = ModalActionTypes.HideModal;
}

export class HiddenModal implements Action {
  readonly type = ModalActionTypes.HiddenModal;
}

export class CleanModalState implements Action {
  readonly type = ModalActionTypes.CleanModalState;
}

export type ModalActions =
  | ShowModal
  | HideModal
  | HiddenModal
  | CleanModalState;
