import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthRoutingModule } from 'src/app/auth/auth-routing.module';
import { BlockedComponent } from 'src/app/auth/containers/blocked/blocked.component';
import { LoginComponent } from 'src/app/auth/containers/login/login.component';
import { RegisterComponent } from 'src/app/auth/containers/register/register.component';
import { AuthGuard } from 'src/app/auth/guards/auth.guard';
import { BlockedGuard } from 'src/app/auth/guards/blocked.guard';
import { ConfirmGuard } from 'src/app/auth/guards/confirm.guard';
import { UserGuard } from 'src/app/auth/guards/user.guard';
import { AuthorizationService } from 'src/app/auth/services/authorization/authorization.service';
import { authReducerProvider, authReducerToken } from 'src/app/auth/store';
import { AuthorizationEffects } from 'src/app/auth/store/effects/auth.effects';
import { PingEffects } from 'src/app/auth/store/effects/ping.effects';
import { UserEffects } from 'src/app/auth/store/effects/user.effects';
import { SharedModule } from 'src/app/shared/shared.module';

const COMPONENTS = [LoginComponent, RegisterComponent, BlockedComponent];

@NgModule({
  declarations: [COMPONENTS],
  providers: [AuthorizationService, authReducerProvider],
  imports: [
    SharedModule,
    AuthRoutingModule,
    StoreModule.forFeature('auth', authReducerToken),
    EffectsModule.forFeature([AuthorizationEffects, UserEffects, PingEffects]),
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [AuthGuard, ConfirmGuard, UserGuard, BlockedGuard],
    };
  }
}
