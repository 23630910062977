// TODO: Add integration with our backend to dynamically load
// common types

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  OperationWorkTypesAction,
  OperationWorkTypesActionTypes,
} from 'src/app/dictionaries/store/actions/operation-work-types.action';

const selectId = (dictionary: DictionaryI) => dictionary.name;
const adapter: EntityAdapter<DictionaryI> = createEntityAdapter<DictionaryI>({
  selectId,
});

export interface OperationWorkTypesReducerState
  extends EntityState<DictionaryI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialOperationWorkTypesReducerState: OperationWorkTypesReducerState = adapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function operationWorkTypesReducer(
  state: OperationWorkTypesReducerState = initialOperationWorkTypesReducerState,
  action: OperationWorkTypesAction
): OperationWorkTypesReducerState {
  switch (action.type) {
    case OperationWorkTypesActionTypes.LoadOperationWorkTypes: {
      return {
        ...state,
        loading: true,
      };
    }

    case OperationWorkTypesActionTypes.LoadedOperationWorkTypesSuccess: {
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case OperationWorkTypesActionTypes.LoadedOperationWorkTypesFail: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case OperationWorkTypesActionTypes.CleanOperationWorkTypesState: {
      return {
        ...initialOperationWorkTypesReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getOperationWorkTypesIds = adapter.getSelectors().selectIds;
export const getOperationWorkTypesEntities = adapter.getSelectors()
  .selectEntities;
export const getAllOperationWorkTypes = adapter.getSelectors().selectAll;
export const getOperationWorkTypesTotal = adapter.getSelectors().selectTotal;

export const getOperationWorkTypesLoading = (
  state: OperationWorkTypesReducerState
) => state.loading;
export const getOperationWorkTypesLoaded = (
  state: OperationWorkTypesReducerState
) => state.loaded;
export const getOperationWorkTypesError = (
  state: OperationWorkTypesReducerState
) => state.error;
