// TODO: Add integration with our backend to dynamically load
// Assignment types
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  ClientAssignmentTypesAction,
  ClientAssignmentTypesActionTypes,
} from 'src/app/dictionaries/store/actions/client-assignment-types.action';

const selectId = (dictionary: DictionaryI) => dictionary.name;
const adapter: EntityAdapter<DictionaryI> = createEntityAdapter<DictionaryI>({
  selectId,
});

export interface ClientAssignmentTypesReducerState
  extends EntityState<DictionaryI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialClientAssignmentTypesReducerState: ClientAssignmentTypesReducerState = adapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function clientAssignmentTypesReducer(
  state: ClientAssignmentTypesReducerState = initialClientAssignmentTypesReducerState,
  action: ClientAssignmentTypesAction
): ClientAssignmentTypesReducerState {
  switch (action.type) {
    case ClientAssignmentTypesActionTypes.LoadClientAssignmentTypes: {
      return {
        ...state,
        loading: true,
      };
    }

    case ClientAssignmentTypesActionTypes.LoadedClientAssignmentTypesSuccess: {
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case ClientAssignmentTypesActionTypes.LoadedClientAssignmentTypesFail: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case ClientAssignmentTypesActionTypes.CleanClientAssignmentTypesState: {
      return {
        ...initialClientAssignmentTypesReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getClientAssignmentTypesIds = adapter.getSelectors().selectIds;
export const getClientAssignmentTypesEntities = adapter.getSelectors()
  .selectEntities;
export const getAllClientAssignmentTypes = adapter.getSelectors().selectAll;
export const getClientAssignmentTypesTotal = adapter.getSelectors().selectTotal;

export const getClientAssignmentTypesLoading = (
  state: ClientAssignmentTypesReducerState
) => state.loading;
export const getClientAssignmentTypesLoaded = (
  state: ClientAssignmentTypesReducerState
) => state.loaded;
export const getClientAssignmentTypesError = (
  state: ClientAssignmentTypesReducerState
) => state.error;
