// TODO: Add integration with our backend to dynamically load
// Assignment types
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  AssignmentTypesAction,
  AssignmentTypesActionTypes,
} from 'src/app/dictionaries/store/actions/assignment-types.action';

const selectId = (dictionary: DictionaryI) => dictionary.name;
const adapter: EntityAdapter<DictionaryI> = createEntityAdapter<DictionaryI>({
  selectId,
});

export interface AssignmentTypesReducerState extends EntityState<DictionaryI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialAssignmentTypesReducerState: AssignmentTypesReducerState = adapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function assignmentTypesReducer(
  state: AssignmentTypesReducerState = initialAssignmentTypesReducerState,
  action: AssignmentTypesAction
): AssignmentTypesReducerState {
  switch (action.type) {
    case AssignmentTypesActionTypes.LoadAssignmentTypes: {
      return {
        ...state,
        loading: true,
      };
    }

    case AssignmentTypesActionTypes.LoadedAssignmentTypesSuccess: {
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case AssignmentTypesActionTypes.LoadedAssignmentTypesFail: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case AssignmentTypesActionTypes.CleanAssignmentTypesState: {
      return {
        ...initialAssignmentTypesReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getAssignmentTypesIds = adapter.getSelectors().selectIds;
export const getAssignmentTypesEntities = adapter.getSelectors().selectEntities;
export const getAllAssignmentTypes = adapter.getSelectors().selectAll;
export const getAssignmentTypesTotal = adapter.getSelectors().selectTotal;

export const getAssignmentTypesLoading = (state: AssignmentTypesReducerState) =>
  state.loading;
export const getAssignmentTypesLoaded = (state: AssignmentTypesReducerState) =>
  state.loaded;
export const getAssignmentTypesError = (state: AssignmentTypesReducerState) =>
  state.error;
