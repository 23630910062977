import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { getRealEstateTypesStateSelector } from 'src/app/dictionaries/store';
import { LoadRealEstateTypes } from 'src/app/dictionaries/store/actions/real-estate-types.action';
import { RealEstateTypesReducerState } from 'src/app/dictionaries/store/reducers/real-estate-types.reducer';

@Injectable()
export class RealEstateTypesGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<RealEstateTypesReducerState>) {}

  canActivate() {
    return this.waitForRealEstateTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  canActivateChild() {
    return this.waitForRealEstateTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForRealEstateTypesToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getRealEstateTypesStateSelector),
      tap((realEstateTypesState: RealEstateTypesReducerState) => {
        if (
          !realEstateTypesState.loaded &&
          !realEstateTypesState.loading &&
          !realEstateTypesState.error
        ) {
          this.store.dispatch(new LoadRealEstateTypes());
        }
      }),
      filter(
        (realEstateTypesState: RealEstateTypesReducerState) =>
          realEstateTypesState.loaded || !!realEstateTypesState.error
      ),
      map((realEstateTypesState: RealEstateTypesReducerState) => {
        if (!!realEstateTypesState.error) {
          throw new Error(realEstateTypesState.error.error.message);
        } else {
          return realEstateTypesState.loaded;
        }
      }),
      take(1)
    );
  }
}
