<div class="container-header">
  <p class="font-weight-light" [translate]="translation"></p>

  <span
    class="calculation-buttons pointer"
    [ngClass]="{ highlight: highlight }"
    *ngIf="!!buttonTranslation"
    (click)="onClick()"
    [translate]="buttonTranslation"
  ></span>
</div>
