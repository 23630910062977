// TODO: Add integration with our backend to dynamically load
// real estate types
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  RealEstateTypesAction,
  RealEstateTypesActionTypes,
} from 'src/app/dictionaries/store/actions/real-estate-types.action';

const selectId = (dictionary: DictionaryI) => dictionary.name;
const adapter: EntityAdapter<DictionaryI> = createEntityAdapter<DictionaryI>({
  selectId,
});

export interface RealEstateTypesReducerState extends EntityState<DictionaryI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialRealEstateTypesReducerState: RealEstateTypesReducerState = adapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function realEstateTypesReducer(
  state: RealEstateTypesReducerState = initialRealEstateTypesReducerState,
  action: RealEstateTypesAction
): RealEstateTypesReducerState {
  switch (action.type) {
    case RealEstateTypesActionTypes.LoadRealEstateTypes: {
      return {
        ...state,
        loading: true,
      };
    }

    case RealEstateTypesActionTypes.LoadedRealEstateTypesSuccess: {
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case RealEstateTypesActionTypes.LoadedRealEstateTypesFail: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case RealEstateTypesActionTypes.CleanRealEstateTypesState: {
      return {
        ...initialRealEstateTypesReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getRealEstateTypesIds = adapter.getSelectors().selectIds;
export const getRealEstateTypesEntities = adapter.getSelectors().selectEntities;
export const getAllRealEstateTypes = adapter.getSelectors().selectAll;
export const getRealEstateTypesTotal = adapter.getSelectors().selectTotal;

export const getRealEstateTypesLoading = (state: RealEstateTypesReducerState) =>
  state.loading;
export const getRealEstateTypesLoaded = (state: RealEstateTypesReducerState) =>
  state.loaded;
export const getRealEstateTypesError = (state: RealEstateTypesReducerState) =>
  state.error;
