import { select, Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';

import { filter, take } from 'rxjs/operators';
import { SetDefaultLanguage } from 'src/app/core/store/actions/language.action';
import { GetPWA } from 'src/app/core/store/actions/pwa.action';
import { LoadErrorExtraTypes } from 'src/app/dictionaries/store/actions/error-extra-types.action';
import { LoadErrorReasonTypes } from 'src/app/dictionaries/store/actions/error-reason-types.action';
import { getErrorExtraTypesLoadedSelector } from 'src/app/dictionaries/store/selectors/error-extra-types.selectors';
import { getErrorReasonTypesLoadedSelector } from 'src/app/dictionaries/store/selectors/error-reason-types.selectors';

// TODO: This could be simplified with NgRX 7
export function initApplication(store: Store<any>): Function {
  return () =>
    new Promise((resolve) => {
      const areLoaded = selectLoadedActionProperty(store);

      dispatchInitAction(store);
      resolveWhenActionsAreLoaded(areLoaded, resolve);
    });
}

function dispatchInitAction(store: Store<any>): void {
  store.dispatch(new LoadErrorReasonTypes());
  store.dispatch(new LoadErrorExtraTypes());
  store.dispatch(new SetDefaultLanguage());
  store.dispatch(new GetPWA());
}

function selectLoadedActionProperty(store: Store<any>): Observable<boolean>[] {
  const isErrorReasonLoaded: Observable<boolean> = store.pipe(
    select(getErrorReasonTypesLoadedSelector),
    filter((loaded) => loaded),
    take(1)
  );

  const isErrorExtraLoaded: Observable<boolean> = store.pipe(
    select(getErrorExtraTypesLoadedSelector),
    filter((loaded) => loaded),
    take(1)
  );

  return [isErrorReasonLoaded, isErrorExtraLoaded];
}

function resolveWhenActionsAreLoaded(
  areLoaded: Observable<boolean>[],
  resolve
): void {
  forkJoin(...areLoaded)
    .pipe(take(1))
    .subscribe(([isReason, isExtra]) => {
      if (isReason && isExtra) {
        resolve(true);
      }
    });
}
