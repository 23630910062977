import { Action } from '@ngrx/store';
import { LanguageEnum } from 'src/app/core/enums/language.enum';

/**
 * Types of Actions related to LanguageState
 */
export enum LanguageActionTypes {
  ChangeLanguage = '[LanguageState] Change Language',
  ChangedLanguage = '[LanguageState] Changed Language',
  SetDefaultLanguage = '[LanguageState] Set Default Language',
  SetedDefaultLanguage = '[LanguageState] Seted Default Language',
}

/**
 * Implementation of Actions related to LanguageState
 */
// Language
export class ChangeLanguage implements Action {
  readonly type = LanguageActionTypes.ChangeLanguage;

  constructor(public payload: LanguageEnum) {}
}

export class ChangedLanguage implements Action {
  readonly type = LanguageActionTypes.ChangedLanguage;

  constructor(public payload: LanguageEnum) {}
}

export class SetDefaultLanguage implements Action {
  readonly type = LanguageActionTypes.SetDefaultLanguage;

  constructor(public payload?: any) {}
}

export class SetedDefaultLanguage implements Action {
  readonly type = LanguageActionTypes.SetedDefaultLanguage;

  constructor(public payload: LanguageEnum) {}
}

/**
 * Export Actions
 */
export type LanguageStateAction =
  | ChangeLanguage
  | ChangedLanguage
  | SetDefaultLanguage
  | SetedDefaultLanguage;
