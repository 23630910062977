import { createSelector } from '@ngrx/store';

import { getPullingQueueStateSelector } from 'src/app/core/store/index';
import {
  getAllAssignmentIdsFromPullingQueue,
  getAssignmentIdsFromPullingQueue,
  getAssignmentIdsFromPullingQueueEntities,
  getAssignmentIdsFromPullingQueueTotal,
  getPullingError,
  getPullingStarted,
} from 'src/app/core/store/reducers/pulling-queue.reducer';

export const getAssignmentIdsFromPullingQueueSelector = createSelector(
  getPullingQueueStateSelector,
  getAssignmentIdsFromPullingQueue
);
export const getAssignmentIdsFromPullingQueueEntitiesSelector = createSelector(
  getPullingQueueStateSelector,
  getAssignmentIdsFromPullingQueueEntities
);
export const getAllAssignmentIdsFromPullingQueueSelector = createSelector(
  getPullingQueueStateSelector,
  getAllAssignmentIdsFromPullingQueue
);
export const getAssignmentIdsFromPullingQueueTotalSelector = createSelector(
  getPullingQueueStateSelector,
  getAssignmentIdsFromPullingQueueTotal
);

export const getPullingStartedSelector = createSelector(
  getPullingQueueStateSelector,
  getPullingStarted
);
export const getPullingErrorSelector = createSelector(
  getPullingQueueStateSelector,
  getPullingError
);
