import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { getEstablishmentTypesStateSelector } from 'src/app/dictionaries/store';
import { LoadEstablishmentTypes } from 'src/app/dictionaries/store/actions/establishment-types.action';
import { EstablishmentTypesReducerState } from 'src/app/dictionaries/store/reducers/establishment-types.reducer';

@Injectable()
export class EstablishmentTypesGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<EstablishmentTypesReducerState>) {}

  canActivate() {
    return this.waitForEstablishmentTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  canActivateChild() {
    return this.waitForEstablishmentTypesToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForEstablishmentTypesToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getEstablishmentTypesStateSelector),
      tap((EstablishmentTypesState: EstablishmentTypesReducerState) => {
        if (
          !EstablishmentTypesState.loaded &&
          !EstablishmentTypesState.loading &&
          !EstablishmentTypesState.error
        ) {
          this.store.dispatch(new LoadEstablishmentTypes());
        }
      }),
      filter(
        (EstablishmentTypesState: EstablishmentTypesReducerState) =>
          EstablishmentTypesState.loaded || !!EstablishmentTypesState.error
      ),
      map((EstablishmentTypesState: EstablishmentTypesReducerState) => {
        if (!!EstablishmentTypesState.error) {
          throw new Error(EstablishmentTypesState.error.error.message);
        } else {
          return EstablishmentTypesState.loaded;
        }
      }),
      take(1)
    );
  }
}
