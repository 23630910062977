import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { TypesService } from 'src/app/core/services/api/types/types.service';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  CleanSpaceTypesState,
  LoadedSpaceTypesFail,
  LoadedSpaceTypesSuccess,
  SpaceTypesActionTypes,
} from 'src/app/dictionaries/store/actions/space-types.action';

@Injectable()
export class SpaceTypesEffects {
  constructor(private actions$: Actions, private typesService: TypesService) {}

  public loadSpaceTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpaceTypesActionTypes.LoadSpaceTypes),
      switchMap(() =>
        this.typesService.getSpaceTypes().pipe(
          map(
            (commonTypes: DictionaryI[]) =>
              new LoadedSpaceTypesSuccess(commonTypes)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadedSpaceTypesFail(error))
          )
        )
      )
    )
  );

  public handleLoadedSpaceTypesFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpaceTypesActionTypes.LoadedSpaceTypesFail),
      map(() => new CleanSpaceTypesState())
    )
  );
}
