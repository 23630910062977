import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

export enum OperationWorkTypesActionTypes {
  LoadOperationWorkTypes = '[OperationWorkTypes] Load Operation Work Types',
  LoadedOperationWorkTypesSuccess = '[OperationWorkTypes] Loaded Operation Work Types',
  LoadedOperationWorkTypesFail = '[OperationWorkTypes] Fail To Load Operation Work Types',

  CleanOperationWorkTypesState = '[OperationWorkTypes] Clean',
}

@ShowLoader()
export class LoadOperationWorkTypes implements Action {
  readonly type = OperationWorkTypesActionTypes.LoadOperationWorkTypes;

  constructor(public payload?: any) {}
}

@HideLoader(OperationWorkTypesActionTypes.LoadOperationWorkTypes)
export class LoadedOperationWorkTypesSuccess implements Action {
  readonly type = OperationWorkTypesActionTypes.LoadedOperationWorkTypesSuccess;

  constructor(public payload: DictionaryI[]) {}
}

@HideLoader(OperationWorkTypesActionTypes.LoadOperationWorkTypes)
export class LoadedOperationWorkTypesFail implements Action {
  readonly type = OperationWorkTypesActionTypes.LoadedOperationWorkTypesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanOperationWorkTypesState implements Action {
  readonly type = OperationWorkTypesActionTypes.CleanOperationWorkTypesState;

  constructor(public payload?: string) {}
}

export type OperationWorkTypesAction =
  | LoadOperationWorkTypes
  | LoadedOperationWorkTypesSuccess
  | LoadedOperationWorkTypesFail
  | CleanOperationWorkTypesState;
