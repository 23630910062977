import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserResourceI } from 'src/app/core/models/user/user-resource.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { UserService } from 'src/app/core/services/api/user/user.service';
import {
  CleanCompanyUsersState,
  CompanyUsersActionTypes,
  LoadedCompanyUsersFail,
  LoadedCompanyUsersSuccess,
} from 'src/app/core/store/actions/company-users.action';

@Injectable()
export class CompanyUsersEffects {
  constructor(private actions$: Actions, private usersService: UserService) {}

  
  public loadCompanyUsers$ = createEffect(() => this.actions$.pipe(
    ofType(CompanyUsersActionTypes.LoadCompanyUsers),
    switchMap(() =>
      this.usersService.getCompanyUsersList().pipe(
        map(
          (userPublic: UserResourceI[]) =>
            new LoadedCompanyUsersSuccess(userPublic)
        ),
        catchError((error: ErrorResponseWithIdI) =>
          of(new LoadedCompanyUsersFail(error))
        )
      )
    )
  ));

  
  public handleLoadedCompanyUsersFail$ = createEffect(() => this.actions$.pipe(
    ofType(CompanyUsersActionTypes.LoadedCompanyUsersFail),
    map(() => new CleanCompanyUsersState())
  ));
}
