import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { SelectI } from 'src/app/core/models/select.interface';
import { getSelectValueFromSelectArrayById } from 'src/app/core/utils/dictionary-utils';
import { DictionariesReducerState } from 'src/app/dictionaries/store';
import { getErrorReasonTypesEntitiesForSelectedLanguageSelector } from 'src/app/dictionaries/store/selectors/error-reason-types.selectors';

@Pipe({
  name: 'reasonTranslate',
})
export class ReasonTranslatePipe implements PipeTransform {
  // TODO: Use new State Selectors in NgRX 7 during update
  constructor(private store: Store<DictionariesReducerState>) {}

  transform(reason: any) {
    return this.store.pipe(
      select(getErrorReasonTypesEntitiesForSelectedLanguageSelector),
      map(
        (reasonDictionary: SelectI[]) =>
          getSelectValueFromSelectArrayById(reason, reasonDictionary) || reason
      )
    );
  }
}
