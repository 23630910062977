import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { TypesService } from 'src/app/core/services/api/types/types.service';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  AssignmentTypesActionTypes,
  CleanAssignmentTypesState,
  LoadedAssignmentTypesFail,
  LoadedAssignmentTypesSuccess,
} from 'src/app/dictionaries/store/actions/assignment-types.action';

@Injectable()
export class AssignmentTypesEffects {
  constructor(private actions$: Actions, private typesService: TypesService) {}

  public loadAssignmentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentTypesActionTypes.LoadAssignmentTypes),
      switchMap(() =>
        this.typesService.getAssignmentTypes().pipe(
          map(
            (AssignmentTypes: DictionaryI[]) =>
              new LoadedAssignmentTypesSuccess(AssignmentTypes)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadedAssignmentTypesFail(error))
          )
        )
      )
    )
  );

  public handleLoadedAssignmentTypesFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentTypesActionTypes.LoadedAssignmentTypesFail),
      map(() => new CleanAssignmentTypesState())
    )
  );
}
