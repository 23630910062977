<app-page-header
  [headerLight]="'go.elements.support'"
  [headerBold]="'supportPage.action'"
  [subheader]="'supportPage.subHeader'"
>
</app-page-header>

<div class="row">
  <div class="col-md-6">
    <app-section-header
      [translation]="'supportPage.contact.header'"
    ></app-section-header>

    <div class="shadow">
      <div class="row">
        <div class="col-12 col-lg-6">
          <app-info-item
            [outputType]="outputTypes.PHONE_NUMBER"
            [label]="'supportPage.contact.telephone'"
            [value]="'070 328 26 83'"
            (clicked)="dispatchAction($event)"
          ></app-info-item>
        </div>
        <div class="col-12 col-lg-6">
          <app-info-item
            [outputType]="outputTypes.EMAIL"
            [label]="'supportPage.contact.email'"
            [value]="'info@unityworks.se'"
            (clicked)="dispatchAction($event)"
          ></app-info-item>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <app-section-header
      [translation]="'supportPage.pwa.header'"
    ></app-section-header>

    <div class="shadow p-2 p-md-4 mt-4 mt-md-0">
      <h5>iOS</h5>

      <span>
        <p class="mt-4">
          <span class="mr-3" [translate]="'supportPage.pwa.share'"></span>
          (<i class="fa fa-share-square-o ml-2 mr-2"></i>)
        </p>
      </span>

      <p [translate]="'supportPage.pwa.click'"></p>
    </div>
  </div>

  <div class="col-md-6">
    <app-section-header
      [translation]="'supportPage.support.header'"
    ></app-section-header>

    <div class="container shadow p-2 p-md-4">
      <div class="row">
        <div class="col">
          <p [translate]="'supportPage.support.quickSolution.welcome'"></p>
          <p [translate]="'supportPage.support.quickSolution.question'"></p>

          <ol>
            <li
              [translate]="'supportPage.support.quickSolution.solution.refresh'"
            ></li>
            <li
              [translate]="
                'supportPage.support.quickSolution.solution.otherBrowser'
              "
            ></li>
            <li
              [translate]="
                'supportPage.support.quickSolution.solution.submit.header'
              "
            ></li>
            <ul>
              <li
                [translate]="
                  'supportPage.support.quickSolution.solution.submit.options.browserName'
                "
              ></li>
              <li
                [translate]="
                  'supportPage.support.quickSolution.solution.submit.options.operatingSystem'
                "
              ></li>
              <li
                [translate]="
                  'supportPage.support.quickSolution.solution.submit.options.typeOfDevices'
                "
              ></li>
              <li
                [translate]="
                  'supportPage.support.quickSolution.solution.submit.options.error'
                "
              ></li>
              <li
                [translate]="
                  'supportPage.support.quickSolution.solution.submit.options.behaviour'
                "
              ></li>
              <li
                [translate]="
                  'supportPage.support.quickSolution.solution.submit.options.reproduce'
                "
              ></li>
            </ul>
          </ol>
        </div>
      </div>

      <div class="row justify-content-end">
        <div class="col-3 col-md-6">
          <button
            class="btn-primary btn float-right"
            [translate]="'supportPage.support.submitButton'"
            (click)="createTicket()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
