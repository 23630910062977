import { MeResourceI } from 'src/app/core/models/user/user-resource.interface';
import {
  UserActions,
  UserActionTypes,
} from 'src/app/auth/store/actions/user.action';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export interface UserState {
  updating: boolean;
  registering: boolean;
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
  resource: MeResourceI | null;
}

export const initialUserState: UserState = {
  updating: false,
  registering: false,
  loading: false,
  loaded: false,
  error: null,
  resource: null,
};

export function userStateReducer(
  state: UserState = initialUserState,
  action: UserActions
): UserState {
  switch (action.type) {
    // Load
    case UserActionTypes.LoadUserResource: {
      return {
        ...state,
        loading: true,
      };
    }

    case UserActionTypes.LoadedUserResourceSuccess: {
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        resource: action.payload,
      };
    }

    case UserActionTypes.LoadedUserResourceFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    }

    // Register
    case UserActionTypes.UnregisteredUser: {
      return {
        ...initialUserState,
      };
    }

    case UserActionTypes.RegisterUser: {
      return {
        ...state,
        registering: true,
      };
    }

    case UserActionTypes.RegisterUserSuccess: {
      return {
        ...initialUserState,
      };
    }

    case UserActionTypes.RegisterUserFail: {
      return {
        ...state,
        registering: false,
        error: action.payload,
      };
    }

    // Update
    case UserActionTypes.UpdateUser: {
      return {
        ...state,
        updating: true,
      };
    }

    case UserActionTypes.UpdateUserSuccess: {
      return {
        ...state,
        updating: false,
      };
    }

    case UserActionTypes.UpdateUserFail: {
      return {
        ...state,
        error: action.payload,
        updating: false,
      };
    }

    // Clean
    case UserActionTypes.CleanUserState: {
      return {
        ...initialUserState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getUserUpdating = (state: UserState) => state.updating;
export const getUserRegistering = (state: UserState) => state.registering;
export const getUserLoading = (state: UserState) => state.loading;
export const getUserLoaded = (state: UserState) => state.loaded;
export const getUserError = (state: UserState) => state.error;
export const getUserResource = (state: UserState) => state.resource;
export const getUserPublic = (state: UserState) =>
  state.resource && state.resource.userPublic;
export const getCustomerPublic = (state: UserState) =>
  state.resource && state.resource.customerPublic;
