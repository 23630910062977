import { createSelector } from '@ngrx/store';

import { getAlertMessagesStateSelector } from 'src/app/core/store/index';
import {
  getAlertMessagesEntities,
  getAlertMessagesError,
  getAlertMessagesFilter,
  getAlertMessagesIds,
  getAlertMessagesLoaded,
  getAlertMessagesLoading,
  getAlertMessagesTotal,
  getAllAlertMessages,
} from 'src/app/core/store/reducers/alert-messages.reducer';

export const getAlertMessagesIdsSelector = createSelector(
  getAlertMessagesStateSelector,
  getAlertMessagesIds
);

export const getAlertMessagesEntitiesSelector = createSelector(
  getAlertMessagesStateSelector,
  getAlertMessagesEntities
);

export const getAllAlertMessagesSelector = createSelector(
  getAlertMessagesStateSelector,
  getAllAlertMessages
);

export const getAlertMessagesTotalSelector = createSelector(
  getAlertMessagesStateSelector,
  getAlertMessagesTotal
);

export const getAlertMessagesFilterSelector = createSelector(
  getAlertMessagesStateSelector,
  getAlertMessagesFilter
);

export const getAlertMessagesLoadedSelector = createSelector(
  getAlertMessagesStateSelector,
  getAlertMessagesLoaded
);

export const getAlertMessagesLoadingSelector = createSelector(
  getAlertMessagesStateSelector,
  getAlertMessagesLoading
);

export const getAlertMessagesErrorSelector = createSelector(
  getAlertMessagesStateSelector,
  getAlertMessagesError
);

export const getAlertMessagesByFilterSelector = createSelector(
  getAllAlertMessagesSelector,
  getAlertMessagesFilterSelector,
  (alertMessages, filter) =>
    alertMessages.filter(am => (!!filter ? am.type === filter : true))
);
