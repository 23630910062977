import { createSelector } from '@ngrx/store';

import { getLanguageStateSelector } from 'src/app/core/store/index';
import {
  getAvailableLanguages,
  getSelectedLanguage,
} from 'src/app/core/store/reducers/language.reducer';

export const getSelectedLanguageSelector = createSelector(
  getLanguageStateSelector,
  getSelectedLanguage
);

export const getAvailableLanguagesSelector = createSelector(
  getLanguageStateSelector,
  getAvailableLanguages
);
