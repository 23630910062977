import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppCountsResponseI } from 'src/app/core/models/app-counts.interface';
import { AppPhaseChartInterface } from 'src/app/core/models/app-phase-chart.interface';
import { ChartResultI } from 'src/app/core/models/log/chart-result.interface';
import { AssignmentSearchRequest } from 'src/app/core/models/search/assignment-search-request.interface';
import { AssignmentSearchResponse } from 'src/app/core/models/search/assignment-search-response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssignmentAppApiService {
  assignmentAppUrl = environment.url + 'api/v1/assignments-app/';

  countUrl = this.assignmentAppUrl + 'counts';
  watchlistUrl = this.assignmentAppUrl + 'watchlist';
  makeDecisionUrl = this.assignmentAppUrl + 'make-decision';

  volumeChartUrl = this.assignmentAppUrl + 'volume-chart';
  phaseChartUrl = this.assignmentAppUrl + 'phases-chart';

  constructor(private httpClient: HttpClient) {}

  getCounts(): Observable<AppCountsResponseI> {
    return this.httpClient.get<AppCountsResponseI>(this.countUrl);
  }

  getVolumeChart(): Observable<ChartResultI[]> {
    return this.httpClient.get<ChartResultI[]>(this.volumeChartUrl);
  }

  getPhaseChart(): Observable<AppPhaseChartInterface> {
    return this.httpClient.get<AppPhaseChartInterface>(this.phaseChartUrl);
  }

  searchForWatchlist(
    assignmentSearchRequest: AssignmentSearchRequest
  ): Observable<AssignmentSearchResponse> {
    return this.httpClient.post<AssignmentSearchResponse>(
      this.watchlistUrl,
      assignmentSearchRequest
    );
  }

  searchForMakeDecision(
    assignmentSearchRequest: AssignmentSearchRequest
  ): Observable<AssignmentSearchResponse> {
    return this.httpClient.post<AssignmentSearchResponse>(
      this.makeDecisionUrl,
      assignmentSearchRequest
    );
  }
}
