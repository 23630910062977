import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseBarComponent } from 'src/app/core/containers/base-bar/base-bar.component';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent extends BaseBarComponent {
  constructor(store: Store<any>) {
    super(store, 'side bar');
  }
}
