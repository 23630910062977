import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
})
export class MenuListComponent implements OnInit, AfterViewInit, OnDestroy {
  _url = '';

  @ViewChild('scrollingWrapper') el: ElementRef;

  @Input()
  set currentUrl(url: string) {
    this._url = url;
    this.addStyleToSelectedElement();
  }

  @Input()
  supportText: string;

  readonly listenerFunction = this.addStyleToScrollMenu.bind(this);
  readonly listenerScrollFunction = this.removeStyleAtEndOfScroll.bind(this);
  readonly cssScrollMenu = 'scrolling-wrapper-gradient';

  readonly notActiveCss = 'not-active-tab';
  readonly activeCss = 'active-tab';

  minWidthWithoutScroll: number;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.addStyleToSelectedElement();
    this.addStyleToScrollMenu();
    window.addEventListener('resize', this.listenerFunction);
    this.el.nativeElement.addEventListener(
      'scroll',
      this.listenerScrollFunction
    );
  }

  private addStyleToSelectedElement() {
    if (this.el) {
      this.applyStylesToNodes();
    }
  }

  private applyStylesToNodes() {
    for (const node of this.el.nativeElement.children) {
      if (this._url.includes(node.dataset.url)) {
        node.classList.add(this.activeCss);
        node.classList.remove(this.notActiveCss);
      } else {
        node.classList.add(this.notActiveCss);
        node.classList.remove(this.activeCss);
      }
    }
  }

  private addStyleToScrollMenu() {
    this.addCssStyleToMenu();
    this.removeCssStyleToMenu();
  }

  private removeStyleAtEndOfScroll(element) {
    if (
      element.target.scrollWidth - element.target.clientWidth ===
      element.target.scrollLeft
    ) {
      this.el.nativeElement.parentNode.classList.remove(this.cssScrollMenu);
      this.minWidthWithoutScroll = null;
    } else {
      this.el.nativeElement.parentNode.classList.add(this.cssScrollMenu);
    }
  }

  private addCssStyleToMenu() {
    if (
      this.el.nativeElement.scrollWidth > this.el.nativeElement.clientWidth &&
      !this.minWidthWithoutScroll
    ) {
      this.el.nativeElement.parentNode.classList.add(this.cssScrollMenu);
      this.minWidthWithoutScroll = this.el.nativeElement.clientWidth;
    }
  }

  private removeCssStyleToMenu() {
    if (this.minWidthWithoutScroll < this.el.nativeElement.clientWidth) {
      this.el.nativeElement.parentNode.classList.remove(this.cssScrollMenu);
      this.minWidthWithoutScroll = null;
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.listenerFunction);
    this.el.nativeElement.removeEventListener(
      'scroll',
      this.listenerScrollFunction
    );
  }
}
