import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

export enum DecisionTypesActionTypes {
  LoadDecisionTypes = '[DecisionTypes] Load Decision Types',
  LoadedDecisionTypesSuccess = '[DecisionTypes] Loaded Decision Types',
  LoadedDecisionTypesFail = '[DecisionTypes] Fail To Load Decision Types',

  CleanDecisionTypesState = '[DecisionTypes] Clean',
}

@ShowLoader()
export class LoadDecisionTypes implements Action {
  readonly type = DecisionTypesActionTypes.LoadDecisionTypes;

  constructor(public payload?: any) {}
}

@HideLoader(DecisionTypesActionTypes.LoadDecisionTypes)
export class LoadedDecisionTypesSuccess implements Action {
  readonly type = DecisionTypesActionTypes.LoadedDecisionTypesSuccess;

  constructor(public payload: DictionaryI[]) {}
}

@HideLoader(DecisionTypesActionTypes.LoadDecisionTypes)
export class LoadedDecisionTypesFail implements Action {
  readonly type = DecisionTypesActionTypes.LoadedDecisionTypesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanDecisionTypesState implements Action {
  readonly type = DecisionTypesActionTypes.CleanDecisionTypesState;

  constructor(public payload?: string) {}
}

export type DecisionTypesAction =
  | LoadDecisionTypes
  | LoadedDecisionTypesSuccess
  | LoadedDecisionTypesFail
  | CleanDecisionTypesState;
