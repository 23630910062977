import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  ErrorExtraTypesAction,
  ErrorExtraTypesActionTypes,
} from 'src/app/dictionaries/store/actions/error-extra-types.action';

const selectId = (dictionary: DictionaryI) => dictionary.name;
const adapter: EntityAdapter<DictionaryI> = createEntityAdapter<DictionaryI>({
  selectId,
});

export interface ErrorExtraTypesReducerState extends EntityState<DictionaryI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialErrorExtraTypesReducerState: ErrorExtraTypesReducerState = adapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function errorExtraTypesReducer(
  state: ErrorExtraTypesReducerState = initialErrorExtraTypesReducerState,
  action: ErrorExtraTypesAction
): ErrorExtraTypesReducerState {
  switch (action.type) {
    case ErrorExtraTypesActionTypes.LoadErrorExtraTypes: {
      return {
        ...state,
        loading: true,
      };
    }

    case ErrorExtraTypesActionTypes.LoadedErrorExtraTypesSuccess: {
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case ErrorExtraTypesActionTypes.LoadedErrorExtraTypesFail: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case ErrorExtraTypesActionTypes.CleanErrorExtraTypesState: {
      return {
        ...initialErrorExtraTypesReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getErrorExtraTypesIds = adapter.getSelectors().selectIds;
export const getErrorExtraTypesEntities = adapter.getSelectors().selectEntities;
export const getAllErrorExtraTypes = adapter.getSelectors().selectAll;
export const getErrorExtraTypesTotal = adapter.getSelectors().selectTotal;

export const getErrorExtraTypesLoading = (state: ErrorExtraTypesReducerState) =>
  state.loading;
export const getErrorExtraTypesLoaded = (state: ErrorExtraTypesReducerState) =>
  state.loaded;
export const getErrorExtraTypesError = (state: ErrorExtraTypesReducerState) =>
  state.error;
