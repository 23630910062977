import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OutputElementEnum } from 'src/app/core/enums/output-element.enum';
import { OpenSupportPanel } from 'src/app/core/store/actions/support.action';
import { SupportReducerState } from 'src/app/core/store/reducers/support.reducer';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent extends StoreDispatcher<any> implements OnInit {
  outputTypes = OutputElementEnum;

  constructor(store: Store<SupportReducerState>) {
    super(store);
  }

  ngOnInit() {}

  createTicket() {
    this.dispatchAction(new OpenSupportPanel());
  }
}
