import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

export enum RealEstateTypesActionTypes {
  LoadRealEstateTypes = '[RealEstateTypes] Load Real Estate Types',
  LoadedRealEstateTypesSuccess = '[RealEstateTypes] Loaded Real Estate',
  LoadedRealEstateTypesFail = '[RealEstateTypes] Fail To Load Real Estate',

  CleanRealEstateTypesState = '[RealEstateTypes] Clean',
}

@ShowLoader()
export class LoadRealEstateTypes implements Action {
  readonly type = RealEstateTypesActionTypes.LoadRealEstateTypes;

  constructor(public payload?: any) {}
}

@HideLoader(RealEstateTypesActionTypes.LoadRealEstateTypes)
export class LoadedRealEstateTypesSuccess implements Action {
  readonly type = RealEstateTypesActionTypes.LoadedRealEstateTypesSuccess;

  constructor(public payload: DictionaryI[]) {}
}

@HideLoader(RealEstateTypesActionTypes.LoadRealEstateTypes)
export class LoadedRealEstateTypesFail implements Action {
  readonly type = RealEstateTypesActionTypes.LoadedRealEstateTypesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanRealEstateTypesState implements Action {
  readonly type = RealEstateTypesActionTypes.CleanRealEstateTypesState;

  constructor(public payload?: string) {}
}

export type RealEstateTypesAction =
  | LoadRealEstateTypes
  | LoadedRealEstateTypesSuccess
  | LoadedRealEstateTypesFail
  | CleanRealEstateTypesState;
