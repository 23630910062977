import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-missing-external-id',
  templateUrl: './missing-external-id.component.html',
  styleUrls: ['./missing-external-id.component.scss'],
})
export class MissingExternalIdComponent implements OnInit {
  @Input()
  errorMessage: string;

  constructor() {}

  ngOnInit() {}
}
