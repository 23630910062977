<div class="header">
  <div class="row mb-3">
    <div class="col-6">
      <span class="h4"
        ><span
          class="text font-weight-light mr-3"
          [translate]="headerLight"
        ></span>
        <span class="text font-weight-bold" [translate]="headerBold"></span
      ></span>
    </div>
    <div class="col-6 align-text-right">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <span class="font-weight-light subtitle" [translate]="subheader"></span>
    </div>
  </div>
</div>
