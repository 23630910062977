import { SelectI } from 'src/app/core/models/select.interface';

export const createSelectObject = (
  id: string | number,
  value: string | number
): SelectI => {
  return {
    id: id,
    value: value,
  };
};
