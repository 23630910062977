import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { ChartResultI } from 'src/app/core/models/log/chart-result.interface';
import {
  AssignmentVolumeChartAction,
  AssignmentVolumeChartActionTypes,
} from 'src/app/core/store/actions/assignment-volume-chart.action';

export interface AssignmentVolumeChartReducerState {
  charts: ChartResultI[];

  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialAssignmentVolumeChartReducerState: AssignmentVolumeChartReducerState = {
  charts: [],

  loading: false,
  loaded: false,
  error: null,
};

export function assignmentVolumeChartReducer(
  state: AssignmentVolumeChartReducerState = initialAssignmentVolumeChartReducerState,
  action: AssignmentVolumeChartAction
): AssignmentVolumeChartReducerState {
  switch (action.type) {
    case AssignmentVolumeChartActionTypes.LoadAssignmentVolumeChart: {
      return {
        ...state,
        loading: true,
      };
    }

    case AssignmentVolumeChartActionTypes.LoadedAssignmentVolumeChartSuccess: {
      return {
        ...state,
        charts: action.payload,
        loading: false,
        loaded: true,
      };
    }

    case AssignmentVolumeChartActionTypes.LoadedAssignmentVolumeChartFail: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case AssignmentVolumeChartActionTypes.CleanAssignmentVolumeChartState: {
      return {
        ...initialAssignmentVolumeChartReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getAssignmentVolumeCharts = (
  state: AssignmentVolumeChartReducerState
) => state.charts;

export const getAssignmentVolumeChartsLoading = (
  state: AssignmentVolumeChartReducerState
) => state.loading;
export const getAssignmentVolumeChartsLoaded = (
  state: AssignmentVolumeChartReducerState
) => state.loaded;
export const getAssignmentVolumeChartsError = (
  state: AssignmentVolumeChartReducerState
) => state.error;
