import { Action } from '@ngrx/store';

export enum PWAActionTypes {
  ChangePWA = '[PWAState] Change PWA',
  GetPWA = '[PWAState] Get PWA',
}

export class ChangePWA implements Action {
  readonly type = PWAActionTypes.ChangePWA;

  constructor(public payload: boolean) {}
}

export class GetPWA implements Action {
  readonly type = PWAActionTypes.GetPWA;
}

export type PWAStateAction = ChangePWA | GetPWA;
