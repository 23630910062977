import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { SetOriginUrl } from 'src/app/auth/store/actions/auth.action';
import {
  CleanUserState,
  LoadedUserResourceFail,
  LoadedUserResourceSuccess,
  RegisterUser,
  RegisterUserFail,
  RegisterUserSuccess,
  ResendEmailToUser,
  ResendEmailToUserFail,
  ResendEmailToUserSuccess,
  UpdateUser,
  UpdateUserFail,
  UpdateUserSuccess,
  UserActionTypes,
} from 'src/app/auth/store/actions/user.action';
import { UserState } from 'src/app/auth/store/reducers/user.reducer';
import { CoreUrlEnums } from 'src/app/core/enums/url-paths.enum';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { MeResourceI } from 'src/app/core/models/user/user-resource.interface';
import { MeService } from 'src/app/core/services/api/me/me.service';
import { UserService } from 'src/app/core/services/api/user/user.service';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage.service';
import { ChangeLanguage } from 'src/app/core/store/actions/language.action';
import { RouterGo } from 'src/app/core/store/actions/router.action';
import { getRouterUrlSelector } from 'src/app/core/store/selectors/router.selectors';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private meService: MeService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private store: Store<UserState>
  ) {}

  public handleUserResourceLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LoadedUserResourceSuccess),
      filter(
        (action: LoadedUserResourceSuccess) =>
          !!action.payload.userPublic.language
      ),
      map((action: LoadedUserResourceSuccess) => {
        const savedLanguage = this.localStorageService.getLanguage();
        return new ChangeLanguage(
          savedLanguage || action.payload.userPublic.language
        );
      })
    )
  );

  public handleLoadedUserDetailsFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserActionTypes.LoadedUserResourceFail,
        UserActionTypes.RegisterUserFail,
        UserActionTypes.UpdateUserFail
      ),
      map(() => new CleanUserState())
    )
  );

  public loadUserResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserActionTypes.LoadUserResource,
        UserActionTypes.RegisterUserSuccess,
        UserActionTypes.UpdateUserSuccess
      ),
      switchMap(() =>
        this.meService.getUserDetailsWithResource().pipe(
          map(
            (userResource: MeResourceI) =>
              new LoadedUserResourceSuccess(userResource)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadedUserResourceFail(error))
          )
        )
      )
    )
  );

  public unregisteredUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.UnregisteredUser),
      withLatestFrom(this.store.pipe(select(getRouterUrlSelector))),
      switchMap(([action, url]) => {
        const actions: Action[] = [
          new RouterGo({
            path: [CoreUrlEnums.REGISTER],
          }),
        ];

        if (!!url && !url.includes(CoreUrlEnums.REGISTER)) {
          actions.push(new SetOriginUrl(url));
        }

        return actions;
      })
    )
  );

  public registerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.RegisterUser),
      switchMap((action: RegisterUser) =>
        this.userService.registerUser(action.payload).pipe(
          map(() => new RegisterUserSuccess()),
          catchError((error: ErrorResponseWithIdI) =>
            of(new RegisterUserFail(error))
          )
        )
      )
    )
  );

  public updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.UpdateUser),
      switchMap((action: UpdateUser) =>
        this.userService.updateUser(action.payload).pipe(
          map(() => new UpdateUserSuccess()),
          catchError((error: ErrorResponseWithIdI) =>
            of(new UpdateUserFail(error))
          )
        )
      )
    )
  );

  public resendEmailToUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.ResendEmailToUser),
      switchMap((action: ResendEmailToUser) =>
        this.userService.resendEmailToUser(action.payload).pipe(
          map(() => new ResendEmailToUserSuccess()),
          catchError((error: ErrorResponseWithIdI) =>
            of(new ResendEmailToUserFail(error))
          )
        )
      )
    )
  );
}
