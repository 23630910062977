import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { TypesService } from 'src/app/core/services/api/types/types.service';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  CleanDecisionTypesState,
  DecisionTypesActionTypes,
  LoadedDecisionTypesFail,
  LoadedDecisionTypesSuccess,
} from 'src/app/dictionaries/store/actions/decision-types.action';

@Injectable()
export class DecisionTypesEffects {
  constructor(private actions$: Actions, private typesService: TypesService) {}

  public loadDecisionTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DecisionTypesActionTypes.LoadDecisionTypes),
      switchMap(() =>
        this.typesService.getDecisionTypes().pipe(
          map(
            (decisionTypes: DictionaryI[]) =>
              new LoadedDecisionTypesSuccess(decisionTypes)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadedDecisionTypesFail(error))
          )
        )
      )
    )
  );

  public handleLoadedDecisionTypesFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DecisionTypesActionTypes.LoadedDecisionTypesFail),
      map(() => new CleanDecisionTypesState())
    )
  );
}
