import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

export enum EstablishmentTypesActionTypes {
  LoadEstablishmentTypes = '[EstablishmentTypes] Load Establishment Types',
  LoadedEstablishmentTypesSuccess = '[EstablishmentTypes] Loaded Establishment',
  LoadedEstablishmentTypesFail = '[EstablishmentTypes] Fail To Load Establishment',

  CleanEstablishmentTypesState = '[EstablishmentTypes] Clean',
}

@ShowLoader()
export class LoadEstablishmentTypes implements Action {
  readonly type = EstablishmentTypesActionTypes.LoadEstablishmentTypes;

  constructor(public payload?: any) {}
}

@HideLoader(EstablishmentTypesActionTypes.LoadEstablishmentTypes)
export class LoadedEstablishmentTypesSuccess implements Action {
  readonly type = EstablishmentTypesActionTypes.LoadedEstablishmentTypesSuccess;

  constructor(public payload: DictionaryI[]) {}
}

@HideLoader(EstablishmentTypesActionTypes.LoadEstablishmentTypes)
export class LoadedEstablishmentTypesFail implements Action {
  readonly type = EstablishmentTypesActionTypes.LoadedEstablishmentTypesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanEstablishmentTypesState implements Action {
  readonly type = EstablishmentTypesActionTypes.CleanEstablishmentTypesState;

  constructor(public payload?: string) {}
}

export type EstablishmentTypesAction =
  | LoadEstablishmentTypes
  | LoadedEstablishmentTypesSuccess
  | LoadedEstablishmentTypesFail
  | CleanEstablishmentTypesState;
