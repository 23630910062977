import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

export enum AssignmentTypesActionTypes {
  LoadAssignmentTypes = '[AssignmentTypes] Load Assignment Types',
  LoadedAssignmentTypesSuccess = '[AssignmentTypes] Loaded Assignment Types',
  LoadedAssignmentTypesFail = '[AssignmentTypes] Fail To Load Assignment Types',

  CleanAssignmentTypesState = '[AssignmentTypes] Clean',
}

@ShowLoader()
export class LoadAssignmentTypes implements Action {
  readonly type = AssignmentTypesActionTypes.LoadAssignmentTypes;

  constructor(public payload?: any) {}
}

@HideLoader(AssignmentTypesActionTypes.LoadAssignmentTypes)
export class LoadedAssignmentTypesSuccess implements Action {
  readonly type = AssignmentTypesActionTypes.LoadedAssignmentTypesSuccess;

  constructor(public payload: DictionaryI[]) {}
}

@HideLoader(AssignmentTypesActionTypes.LoadAssignmentTypes)
export class LoadedAssignmentTypesFail implements Action {
  readonly type = AssignmentTypesActionTypes.LoadedAssignmentTypesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanAssignmentTypesState implements Action {
  readonly type = AssignmentTypesActionTypes.CleanAssignmentTypesState;

  constructor(public payload?: string) {}
}

export type AssignmentTypesAction =
  | LoadAssignmentTypes
  | LoadedAssignmentTypesSuccess
  | LoadedAssignmentTypesFail
  | CleanAssignmentTypesState;
