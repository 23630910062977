import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  AdministrationUrlEnum,
  AssignmentUrlEnum,
  CoreUrlEnums,
  NumbersUrlEnum,
} from 'src/app/core/enums/url-paths.enum';
import { SideBarMenuClickedAnalytics } from 'src/app/core/store/actions/google-analytics.actions';
import { RouterGo } from 'src/app/core/store/actions/router.action';
import { getMakeDecisionCountSelector } from 'src/app/core/store/selectors/assignment-count.selectors';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';
import getAdministrationUrl = AdministrationUrlEnum.getAdministrationUrl;
import getAssignmentPhaseUrl = AssignmentUrlEnum.getAssignmentPhaseUrl;
import getAssignmentUrl = AssignmentUrlEnum.getAssignmentUrl;
import getNumbersDashboardUrl = NumbersUrlEnum.getNumbersDashboardUrl;
import getNumbersSearchUrl = NumbersUrlEnum.getNumbersSearchUrl;

export class BaseBarComponent extends StoreDispatcher<any> {
  makeDecisionNumber$: Observable<number>;
  barType: string;

  constructor(store: Store, barType: string) {
    super(store);
    this.barType = barType;

    this.makeDecisionNumber$ = this.store.pipe(
      select(getMakeDecisionCountSelector)
    );
  }

  goToSupport() {
    const gaAction = new SideBarMenuClickedAnalytics({
      type: this.barType,
      page: 'support page',
    });
    const action = new RouterGo({
      path: [CoreUrlEnums.SUPPORT],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToProjects() {
    const gaAction = new SideBarMenuClickedAnalytics({
      type: this.barType,
      page: 'projects page',
    });
    const action = new RouterGo({
      path: [CoreUrlEnums.PROJECTS],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToAdministration() {
    const gaAction = new SideBarMenuClickedAnalytics({
      type: this.barType,
      page: 'administration page',
    });
    const action = new RouterGo({
      path: [getAdministrationUrl(AdministrationUrlEnum.COMPANY)],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToDashboard() {
    const gaAction = new SideBarMenuClickedAnalytics({
      type: this.barType,
      page: 'dashboard page',
    });
    const action = new RouterGo({
      path: [getNumbersDashboardUrl(NumbersUrlEnum.DASHBOARD_PORTFOLIO)],
      query: { period: '3m' },
    });
    this.dispatchActions([action, gaAction]);
  }

  goToSearch() {
    const gaAction = new SideBarMenuClickedAnalytics({
      type: this.barType,
      page: 'search page',
    });
    const action = new RouterGo({
      path: [getNumbersSearchUrl(NumbersUrlEnum.SEARCH_LIST)],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToNumbers() {
    const gaAction = new SideBarMenuClickedAnalytics({
      type: this.barType,
      page: 'numbers page',
    });
    const action = new RouterGo({
      path: [getNumbersSearchUrl(NumbersUrlEnum.SEARCH_LIST)],
    });
    this.dispatchActions([action, gaAction]);
  }

  onAssignmentClicked() {
    const gaAction = new SideBarMenuClickedAnalytics({
      type: this.barType,
      page: 'assignment page',
    });
    const action = new RouterGo({
      path: [getAssignmentUrl(AssignmentUrlEnum.OVERVIEW)],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToOverview() {
    const gaAction = new SideBarMenuClickedAnalytics({
      type: this.barType,
      page: 'home page',
    });
    const action = new RouterGo({
      path: [getAssignmentUrl(AssignmentUrlEnum.OVERVIEW)],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToMakeDecision() {
    const gaAction = new SideBarMenuClickedAnalytics({
      type: this.barType,
      page: 'make decision page',
    });
    const action = new RouterGo({
      path: [getAssignmentPhaseUrl(AssignmentUrlEnum.PHASES_MAKE_DECISION)],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToWatchlist() {
    const gaAction = new SideBarMenuClickedAnalytics({
      type: this.barType,
      page: 'watch list page',
    });
    const action = new RouterGo({
      path: [getAssignmentPhaseUrl(AssignmentUrlEnum.PHASES_WATCHLIST)],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToPreparation() {
    const gaAction = new SideBarMenuClickedAnalytics({
      type: this.barType,
      page: 'preparation page',
    });
    const action = new RouterGo({
      path: [getAssignmentPhaseUrl(AssignmentUrlEnum.PHASES_PREPARATION)],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToOrdering() {
    const gaAction = new SideBarMenuClickedAnalytics({
      type: this.barType,
      page: 'ordering page',
    });
    const action = new RouterGo({
      path: [getAssignmentPhaseUrl(AssignmentUrlEnum.PHASES_ORDERING)],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToProduction() {
    const gaAction = new SideBarMenuClickedAnalytics({
      type: this.barType,
      page: 'production page',
    });
    const action = new RouterGo({
      path: [getAssignmentPhaseUrl(AssignmentUrlEnum.PHASES_PRODUCTION)],
    });
    this.dispatchActions([action, gaAction]);
  }

  goToClosing() {
    const gaAction = new SideBarMenuClickedAnalytics({
      type: this.barType,
      page: 'closing page',
    });
    const action = new RouterGo({
      path: [getAssignmentPhaseUrl(AssignmentUrlEnum.PHASES_CLOSING)],
    });
    this.dispatchActions([action, gaAction]);
  }
}
