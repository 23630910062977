import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ExcelI } from 'src/app/core/models/excel.interface';

export enum ExcelActionTypes {
  ExportToExcel = '[Excel] Export To Excel',
  ExportToExcelSuccess = '[Excel] Export To Excel Success',
  ExportToExcelError = '[Excel] Export To Excel Error',

  ExportToSimpleExcel = '[Excel] Export To Simple Excel',
  ExportToSimpleExcelSuccess = '[Excel] Export To Simple Excel Success',
  ExportToSimpleExcelError = '[Excel] Export To Simple Excel Error',

  ExportToAssignmentEventsExcel = '[Excel] Export To Assignment Events Excel',
  ExportToAssignmentEventsExcelSuccess = '[Excel] Export To Assignment Events Excel Success',
  ExportToAssignmentEventsExcelError = '[Excel] Export To Assignment Events Excel Error',

  ExportToProjectSimpleExcel = '[Excel] Export To Project Simple Excel',
  ExportToProjectSimpleExcelSuccess = '[Excel] Export To Project Simple Excel Success',
  ExportToProjectSimpleExcelError = '[Excel] Export To Project Simple Excel Error',
}

@ShowLoader()
export class ExportToExcel implements Action {
  readonly type = ExcelActionTypes.ExportToExcel;

  constructor(public payload: ExcelI) {}
}

@HideLoader(ExcelActionTypes.ExportToExcel)
export class ExportToExcelSuccess implements Action {
  readonly type = ExcelActionTypes.ExportToExcelSuccess;
}

@HideLoader(ExcelActionTypes.ExportToExcel)
export class ExportToExcelError implements Action {
  readonly type = ExcelActionTypes.ExportToExcelError;

  constructor(public payload: any) {}
}

@ShowLoader()
export class ExportToSimpleExcel implements Action {
  readonly type = ExcelActionTypes.ExportToSimpleExcel;

  constructor(public payload: ExcelI) {}
}

@HideLoader(ExcelActionTypes.ExportToSimpleExcel)
export class ExportToSimpleExcelSuccess implements Action {
  readonly type = ExcelActionTypes.ExportToSimpleExcelSuccess;
}

@HideLoader(ExcelActionTypes.ExportToSimpleExcel)
export class ExportToSimpleExcelError implements Action {
  readonly type = ExcelActionTypes.ExportToSimpleExcelError;

  constructor(public payload: any) {}
}

@ShowLoader()
export class ExportToAssignmentEventsExcel implements Action {
  readonly type = ExcelActionTypes.ExportToAssignmentEventsExcel;

  constructor(public payload: ExcelI) {}
}

@HideLoader(ExcelActionTypes.ExportToAssignmentEventsExcel)
export class ExportToAssignmentEventsExcelSuccess implements Action {
  readonly type = ExcelActionTypes.ExportToAssignmentEventsExcelSuccess;
}

@HideLoader(ExcelActionTypes.ExportToAssignmentEventsExcel)
export class ExportToAssignmentEventsExcelError implements Action {
  readonly type = ExcelActionTypes.ExportToAssignmentEventsExcelError;

  constructor(public payload: any) {}
}

@ShowLoader()
export class ExportToProjectSimpleExcel implements Action {
  readonly type = ExcelActionTypes.ExportToProjectSimpleExcel;

  constructor(public payload: number) {}
}

@HideLoader(ExcelActionTypes.ExportToProjectSimpleExcel)
export class ExportToProjectSimpleExcelSuccess implements Action {
  readonly type = ExcelActionTypes.ExportToProjectSimpleExcelSuccess;
}

@HideLoader(ExcelActionTypes.ExportToProjectSimpleExcel)
export class ExportToProjectSimpleExcelError implements Action {
  readonly type = ExcelActionTypes.ExportToProjectSimpleExcelError;

  constructor(public payload: any) {}
}

export type ExcelActions =
  | ExportToExcel
  | ExportToExcelSuccess
  | ExportToExcelError
  | ExportToSimpleExcel
  | ExportToSimpleExcelSuccess
  | ExportToSimpleExcelError
  | ExportToAssignmentEventsExcel
  | ExportToAssignmentEventsExcelSuccess
  | ExportToAssignmentEventsExcelError
  | ExportToProjectSimpleExcel
  | ExportToProjectSimpleExcelSuccess
  | ExportToProjectSimpleExcelError;
