import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { AuthorizationService } from 'src/app/auth/services/authorization/authorization.service';
import { getAuthUserStateSelector } from 'src/app/auth/store';
import { LoadUserResource } from 'src/app/auth/store/actions/user.action';
import { UserState } from 'src/app/auth/store/reducers/user.reducer';

@Injectable()
export class UserGuard implements CanActivate {
  constructor(
    private store: Store<UserState>,
    private authorizationService: AuthorizationService
  ) {}

  canActivate() {
    return this.authorizationService.isTokenValid()
      ? this.waitForUserToLoad().pipe(
          switchMap(() => of(true)),
          catchError(() => of(false))
        )
      : of(true);
  }

  waitForUserToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getAuthUserStateSelector),
      tap((userState: UserState) => {
        if (!userState.loaded && !userState.loading && !userState.error) {
          this.store.dispatch(new LoadUserResource());
        }
      }),
      filter((userState: UserState) => userState.loaded || !!userState.error),
      map((userState: UserState) => {
        if (!!userState.error) {
          throw new Error(userState.error.error.message);
        } else {
          return userState.loaded;
        }
      }),
      take(1)
    );
  }
}
