import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessagesTypes } from 'src/app/core/enums/alert-messages-types.enum';
import {
  AlertMessagesActions,
  ClearAllMessages,
  SetFilter,
} from 'src/app/core/store/actions/alert-messages.action';

@Component({
  selector: 'app-message-panel-header',
  templateUrl: './message-panel-header.component.html',
  styleUrls: ['./message-panel-header.component.scss'],
})
export class MessagePanelHeaderComponent implements OnInit {
  @Input()
  currentFilter: AlertMessagesTypes;

  @Input()
  backUrl: string;

  @Output()
  filter: EventEmitter<AlertMessagesActions> = new EventEmitter<
    AlertMessagesActions
  >();

  @Output()
  clearAll: EventEmitter<AlertMessagesActions> = new EventEmitter<
    AlertMessagesActions
  >();

  alertMessagesTypes = AlertMessagesTypes;

  constructor() {}

  ngOnInit() {}

  updateFilter(newFilter: AlertMessagesTypes): void {
    this.filter.emit(new SetFilter(newFilter));
  }

  onClearAll(): void {
    this.clearAll.emit(new ClearAllMessages());
  }
}
