import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
  LoadZenDesk,
  ShowLauncher,
} from 'src/app/core/store/actions/support.action';
import { SupportReducerState } from 'src/app/core/store/reducers/support.reducer';
import {
  isLauncherVisibleSelector,
  isZenDeskLoadedSelector,
} from 'src/app/core/store/selectors/support.selectors';

@Injectable()
export class SupportGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<SupportReducerState>) {}

  canActivate(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  canActivateChild(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  checkIfCanActivate(): Observable<boolean> {
    return this.waitForZenDeskToLoad().pipe(
      switchMap(() =>
        this.showLauncherIfNeeded().pipe(
          switchMap(() => of(true)),
          catchError(() => of(false))
        )
      ),
      catchError(() => of(false))
    );
  }

  waitForZenDeskToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(isZenDeskLoadedSelector),
      tap((isZenDeskLoaded: boolean) => {
        if (!isZenDeskLoaded) {
          this.store.dispatch(new LoadZenDesk());
        }
      }),
      filter((isZenDeskLoaded: boolean) => isZenDeskLoaded),
      map((isZenDeskLoaded: boolean) => isZenDeskLoaded),
      take(1)
    );
  }

  showLauncherIfNeeded(): Observable<boolean> {
    return this.store.pipe(
      select(isLauncherVisibleSelector),
      tap((isLauncherVisible: boolean) => {
        if (!isLauncherVisible) {
          this.store.dispatch(new ShowLauncher());
        }
      }),
      filter((isLauncherVisible: boolean) => isLauncherVisible),
      map((isLauncherVisible: boolean) => isLauncherVisible),
      take(1)
    );
  }
}
