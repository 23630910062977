import {
  GoogleAnalyticsActions,
  GoogleAnalyticsActionTypes,
} from 'src/app/core/store/actions/google-analytics.actions';
import {
  SpinnerActions,
  SpinnerActionTypes,
} from 'src/app/core/store/actions/spinner.action';

export interface GoogleAnalyticsReducerState {
  spinner: {
    loadingStarted: number;
    actions: string[];
  };
}

export const initialGoogleAnalyticsReducerState: GoogleAnalyticsReducerState = {
  spinner: {
    loadingStarted: null,
    actions: [],
  },
};

export function googleAnalyticsReducer(
  state: GoogleAnalyticsReducerState = initialGoogleAnalyticsReducerState,
  action: SpinnerActions | GoogleAnalyticsActions
) {
  switch (action.type) {
    case GoogleAnalyticsActionTypes.CleanGoogleAnalytics:
      return {
        ...initialGoogleAnalyticsReducerState,
      };

    case SpinnerActionTypes.ShowSpinner:
      return {
        ...state,
        spinner: {
          loadingStarted: !!state.spinner.loadingStarted
            ? state.spinner.loadingStarted
            : new Date(),
          actions: [...state.spinner.actions, action.payload.type],
        },
      };

    default:
      return state;
  }
}

export const googleAnalyticsLoaderTime = (state: GoogleAnalyticsReducerState) =>
  state.spinner.loadingStarted;
export const googleAnalyticsLoaderActions = (
  state: GoogleAnalyticsReducerState
) => state.spinner.actions;
