import {
  AuthActions,
  AuthActionTypes,
} from 'src/app/auth/store/actions/auth.action';

export interface AuthInfoState {
  originUrl: string;
}

export const initialAuthInfoState: AuthInfoState = {
  originUrl: '',
};

export function authInfoStateReducer(
  state: AuthInfoState = initialAuthInfoState,
  action: AuthActions
): AuthInfoState {
  switch (action.type) {
    case AuthActionTypes.SetOriginUrl: {
      return {
        ...state,
        originUrl: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getOriginUrl = (state: AuthInfoState) => state.originUrl;
