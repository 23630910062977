import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { TypesService } from 'src/app/core/services/api/types/types.service';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  CleanEstablishmentTypesState,
  EstablishmentTypesActionTypes,
  LoadedEstablishmentTypesFail,
  LoadedEstablishmentTypesSuccess,
} from 'src/app/dictionaries/store/actions/establishment-types.action';

@Injectable()
export class EstablishmentTypesEffects {
  constructor(private actions$: Actions, private typesService: TypesService) {}

  public loadEstablishmentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EstablishmentTypesActionTypes.LoadEstablishmentTypes),
      switchMap(() =>
        this.typesService.getEstablishmentTypes().pipe(
          map(
            (EstablishmentTypes: DictionaryI[]) =>
              new LoadedEstablishmentTypesSuccess(EstablishmentTypes)
          ),
          catchError((error: ErrorResponseWithIdI) =>
            of(new LoadedEstablishmentTypesFail(error))
          )
        )
      )
    )
  );

  public handleLoadedEstablishmentTypesFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EstablishmentTypesActionTypes.LoadedEstablishmentTypesFail),
      map(() => new CleanEstablishmentTypesState())
    )
  );
}
