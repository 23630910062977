import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { AuthorizationService } from 'src/app/auth/services/authorization/authorization.service';

@Injectable()
export class BlockedGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthorizationService) {}

  canActivate() {
    return this.checkIfTokenIsValid();
  }

  canActivateChild() {
    return this.checkIfTokenIsValid();
  }

  private checkIfTokenIsValid(): boolean {
    return !this.authService.isTokenValid();
  }
}
