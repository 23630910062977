import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';

import { GoElementComponent } from 'src/app/core/components/go-element/go-element.component';
import { MessagePanelHeaderComponent } from 'src/app/core/components/message-panel-header/message-panel-header.component';
import { MessagePanelListComponent } from 'src/app/core/components/message-panel-list/message-panel-list.component';
import { MissingExternalIdComponent } from 'src/app/core/components/missing-external-id/missing-external-id.component';
import { SideBarItemComponent } from 'src/app/core/components/side-bar-item/side-bar-item.component';
import { AppComponent } from 'src/app/core/containers/app/app.component';
import { HeaderComponent } from 'src/app/core/containers/header/header.component';
import { MessagesPanelComponent } from 'src/app/core/containers/messages-panel/messages-panel.component';
import { SideBarComponent } from 'src/app/core/containers/side-bar/side-bar.component';
import { SupportComponent } from 'src/app/core/containers/support/support.component';
import { AppRoutingModule } from 'src/app/core/core-routing.module';
import { AgreementsGuard } from 'src/app/core/guards/agreements.guard';
import { AlertMessagesGuard } from 'src/app/core/guards/alert-messeges.guard';
import { AssignmentCountGuard } from 'src/app/core/guards/assignment-count.guard';
import { AssignmentGuard } from 'src/app/core/guards/assignment-guard.service';
import { AssignmentPhaseChartGuard } from 'src/app/core/guards/assignment-phase-chart.guard';
import { AssignmentVolumeChartGuard } from 'src/app/core/guards/assignment-volume-chart.guard';
import { CompanyUsersGuard } from 'src/app/core/guards/company-users-guard.service';
import { SupportDeactivateGuard } from 'src/app/core/guards/support-deactivate.guard';
import { SupportGuard } from 'src/app/core/guards/support.guard';
import { AgreementsService } from 'src/app/core/services/api/agreements/agreements.service';
import { AssignmentEventService } from 'src/app/core/services/api/assignment-event/assignment-event.service';
import { AssignmentsService } from 'src/app/core/services/api/assignments/assignments.service';
import { RetrieveAssignmentsFacadeService } from 'src/app/core/services/api/assignments/retrieve-assignments-facade.service';
import { RetrieveAssignmentsService } from 'src/app/core/services/api/assignments/retrieve-assignments.service';
import { ChartService } from 'src/app/core/services/api/chart/chart.service';
import { ExcelService } from 'src/app/core/services/api/excel/excel.service';
import { MeService } from 'src/app/core/services/api/me/me.service';
import { ProjectsService } from 'src/app/core/services/api/projects/projects.service';
import { UserService } from 'src/app/core/services/api/user/user.service';
import { AssetService } from 'src/app/core/services/asset/asset.service';
import { ExternalRedirectService } from 'src/app/core/services/external-redirect/external-redirect.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { A2hsComponent } from './containers/a2hs/a2hs.component';
import { BottomBarComponent } from './containers/bottom-bar/bottom-bar.component';

const COMPONENTS = [
  AppComponent,
  GoElementComponent,
  MessagesPanelComponent,
  MessagePanelHeaderComponent,
  MessagePanelListComponent,
  SupportComponent,
  HeaderComponent,
  MissingExternalIdComponent,
  SideBarComponent,
  SideBarItemComponent,
  BottomBarComponent,
  A2hsComponent,
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [SharedModule, AppRoutingModule, HttpClientModule, NgxSpinnerModule],
  providers: [DatePipe],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AgreementsService,
        AssetService,
        AssignmentsService,
        RetrieveAssignmentsService,
        AssignmentEventService,
        AgreementsGuard,
        ExternalRedirectService,
        ExcelService,
        ChartService,
        AssignmentGuard,
        AlertMessagesGuard,
        RetrieveAssignmentsFacadeService,
        CompanyUsersGuard,
        MeService,
        UserService,
        SupportGuard,
        SupportDeactivateGuard,
        AssignmentVolumeChartGuard,
        AssignmentPhaseChartGuard,
        AssignmentCountGuard,
        ProjectsService,
      ],
    };
  }
}
