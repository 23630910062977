import { Action } from '@ngrx/store';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';

export enum PingActionTypes {
  Ping = '[Auth] Ping Backend',
  PingSuccess = '[Auth] Ping Backend Success',
  PingFailed = '[Auth] Ping Backend Failed',
}

export class Ping implements Action {
  readonly type = PingActionTypes.Ping;
}

export class PingSuccess implements Action {
  readonly type = PingActionTypes.PingSuccess;
}

export class PingFailed implements Action {
  readonly type = PingActionTypes.PingFailed;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export type PingActions = Ping | PingSuccess | PingFailed;
