import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

declare function zE(x: string, y: string, z?: any);

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  constructor() {}

  load(): Observable<boolean> {
    return this.addScript();
  }

  private addScript(): Observable<boolean> {
    return new Observable(observer => {
      const head = document.getElementsByTagName('head')[0];

      const zeScript = document.createElement('script');
      zeScript.id = 'ze-snippet';
      zeScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${environment.zendesk.support}`;

      zeScript['onreadystatechange'] = () => {
        observer.next(true);
        observer.complete();
      };
      zeScript['onload'] = () => {
        observer.next(true);
        observer.complete();
      };

      head.insertBefore(zeScript, head.firstChild);
    });
  }

  private setLauncherVisibility(visibility: boolean) {
    zE('webWidget', visibility ? 'show' : 'hide');
  }

  hideLauncher() {
    this.setLauncherVisibility(false);
  }

  showLauncher() {
    this.setLauncherVisibility(true);
  }

  openSupportPanel() {
    zE('webWidget', 'open');
  }
}
