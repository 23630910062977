<div class="row">
  <div class="col-12">
    <h1 class="text-center" [translate]="'common.blocked'"></h1>
  </div>
  <div class="col-12 text-center mt-4">
    <p [translate]="'blocked.message'"></p>
    <p class="mt-2" [translate]="'blocked.footer'"></p>
  </div>
  <div class="col-12 mt-5 text-center">
    <span
      class="pointer"
      (click)="goToLoginPage()"
      [translate]="'blocked.button'"
    ></span>
  </div>
</div>
