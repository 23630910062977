<nav
  class="navbar sticky-top navbar-expand-md navbar-light justify-content-between shadow-sm pb-3 pt-3"
  (clickOutside)="hide()"
>
  <a class="navbar-brand h1 mb-0" (click)="goToMainPage()">
    <p [translate]="'common.main-page'"></p>
  </a>

  <span class="onlyOnBig ml-auto">
    <ng-container *ngTemplateOutlet="addAssignment"> </ng-container>
  </span>

  <button
    type="button"
    class="navbar-toggler ml-4"
    (click)="isCollapsed = !isCollapsed"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="icon-menu ml-2 mr-2"
    >
      <path
        class="primary"
        fill-rule="evenodd"
        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
      />
    </svg>
  </button>

  <div [collapse]="isCollapsed" class="collapse navbar-collapse">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item mr-5 onlyOnSmall">
        <ng-container *ngTemplateOutlet="addAssignment"> </ng-container>
      </li>
      <ng-template [ngIf]="doesTokenExists$ | async" [ngIfElse]="notLoggedIn">
        <li class="nav-item mr-2 mt-4 mt-md-0">
          <span class="pointer" (click)="goToMessages()">
            <button class="btn btm-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 26 26"
                class="w-8 icon-notification"
              >
                <circle
                  cx="12"
                  cy="19"
                  r="3"
                  [ngClass]="
                    (alerts$ | async).length > 0 ? 'primary-warning' : 'primary'
                  "
                />
                <path
                  [ngClass]="
                    (alerts$ | async).length > 0
                      ? 'secondary-warning'
                      : 'secondary'
                  "
                  d="M10.02 4.28L10 4a2 2 0 1 1 3.98.28A7 7 0 0 1 19 11v5a1 1 0 0 0 1 1 1 1 0 0 1 0 2H4a1 1 0 0 1 0-2 1 1 0 0 0 1-1v-5a7 7 0 0 1 5.02-6.72z"
                />
              </svg>
            </button>
            <span class="onlyOnBig ml-3" [translate]="'header.messages'"></span>
          </span>
        </li>

        <ng-container *ngTemplateOutlet="language"> </ng-container>

        <li class="nav-item mr-2 mt-4 mt-md-0">
          <span class="pointer" (click)="logout()">
            <button class="btn btm-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                class="icon-door-exit"
              >
                <path
                  class="secondary"
                  d="M11 4h3a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V6h-2v12h2v-2a1 1 0 0 1 2 0v3a1 1 0 0 1-1 1h-3v1a1 1 0 0 1-1.27.96l-6.98-2A1 1 0 0 1 2 19V5a1 1 0 0 1 .75-.97l6.98-2A1 1 0 0 1 11 3v1z"
                />
                <path
                  class="primary"
                  d="M18.59 11l-1.3-1.3c-.94-.94.47-2.35 1.42-1.4l3 3a1 1 0 0 1 0 1.4l-3 3c-.95.95-2.36-.46-1.42-1.4l1.3-1.3H14a1 1 0 0 1 0-2h4.59z"
                />
              </svg>
            </button>
            <span class="onlyOnBig ml-3" [translate]="'header.logout'"></span>
          </span>
        </li>
      </ng-template>

      <ng-template #notLoggedIn>
        <ng-container *ngTemplateOutlet="language"> </ng-container>
      </ng-template>
    </ul>
  </div>
</nav>

<ng-template #addAssignment>
  <button
    class="btn btm-sm shadow-sm"
    (click)="goToStartAssignment()"
    *ngIf="doesTokenExists$ | async"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="mr-2 ml-2 mr-md-2 ml-md-0 icon-add"
    >
      <path
        class="primary"
        fill-rule="evenodd"
        d="M17 11a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4h4z"
      ></path>
    </svg>
    <span
      class="onlyOnSmall mr-1"
      [translate]="'header.startAssignment'"
    ></span>
  </button>
</ng-template>

<ng-template #language>
  <li class="nav-item mr-2">
    <app-language-select
      [selectedLanguage]="selectedLanguage$ | async"
      [languages]="languages$ | async"
      (select)="dispatchLanguageAction($event)"
    >
    </app-language-select>
  </li>
</ng-template>
