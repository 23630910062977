import { Params } from '@angular/router';
import { createSelector } from '@ngrx/store';

import { getRouterStateSelector } from 'src/app/core/store/index';
import {
  getRouterParams,
  getRouterPreviousUrl,
  getRouterQueryParams,
  getRouterUrl,
} from 'src/app/core/store/reducers/router.reducer';

export const getRouterParamsSelector = createSelector(
  getRouterStateSelector,
  getRouterParams
);
export const getRouterQueryParamsSelector = createSelector(
  getRouterStateSelector,
  getRouterQueryParams
);
export const getRouterUrlSelector = createSelector(
  getRouterStateSelector,
  getRouterUrl
);

export const getRouterUrlWithSlashAtBeginningAndQueryParamsSelector = createSelector(
  getRouterUrlSelector,
  (url: string) => {
    if (!!url) {
      url = url[0] === '/' ? url.substring(1) : url;
      url = url.replace(/\?.*/, '');
    }
    return url;
  }
);

export const getRouterPreviousUrlSelector = createSelector(
  getRouterStateSelector,
  getRouterPreviousUrl
);

export const getPeriodFilterFromQueryParams = createSelector(
  getRouterQueryParamsSelector,
  (p: Params) => p && p.period
);
