import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssignmentLittleI } from 'src/app/core/models/assignment/assignment-little.interface';
import { PullingRequestI } from 'src/app/core/models/polling/pulling-request.interface';
import { PullingResponseI } from 'src/app/core/models/polling/pulling-response.interface';
import { AssignmentSearchRequest } from 'src/app/core/models/search/assignment-search-request.interface';
import { AssignmentSearchResponse } from 'src/app/core/models/search/assignment-search-response.interface';
import { AssignmentStartModelI } from 'src/app/core/models/start/assignment-start-model.interface';
import { environment } from 'src/environments/environment';
import { AssignmentLogResourceI } from '../../../models/log/assignment-log-resource.interface';

@Injectable()
export class RetrieveAssignmentsService {
  assignmentsUrl = environment.url + 'api/v1/assignment/';

  searchAssignmentsUrl = this.assignmentsUrl + 'search/';
  singleAssignmentsUrl = this.assignmentsUrl + 'as-lite/';

  pullUrl = this.assignmentsUrl + 'poll/as-object/';

  startUrl = this.assignmentsUrl + 'start/';
  logUrl = this.assignmentsUrl + 'log/';

  constructor(private http: HttpClient) {}

  getAssignment(assignmentId: string | number): Observable<AssignmentLittleI> {
    return this.http.get<AssignmentLittleI>(
      this.singleAssignmentsUrl + assignmentId
    );
  }

  searchForAssignments(
    assignmentSearchRequest: AssignmentSearchRequest
  ): Observable<AssignmentSearchResponse> {
    return this.http.post<AssignmentSearchResponse>(
      this.searchAssignmentsUrl,
      assignmentSearchRequest
    );
  }

  pull(pullingRequest: PullingRequestI): Observable<PullingResponseI[]> {
    return this.http.post<PullingResponseI[]>(this.pullUrl, pullingRequest);
  }

  getAssignmentStart(assignmentId: number): Observable<AssignmentStartModelI> {
    return this.http.get<any>(this.startUrl + assignmentId);
  }

  getAssignmentLog(assignmentId: number): Observable<AssignmentLogResourceI> {
    return this.http.get<any>(this.logUrl + assignmentId);
  }
}
