import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AssignmentStartModelI } from 'src/app/core/models/start/assignment-start-model.interface';

export enum StartActionTypes {
  LoadStart = '[Start] Load Start',
  LoadedStartSuccess = '[Start] Loaded Start',
  LoadedStartFail = '[Start] Fail To Load Start',

  CleanStartState = '[Start] Clean Start',
}

@ShowLoader()
export class LoadStart implements Action {
  readonly type = StartActionTypes.LoadStart;

  constructor(public payload: number) {}
}

@HideLoader(StartActionTypes.LoadStart)
export class LoadedStartSuccess implements Action {
  readonly type = StartActionTypes.LoadedStartSuccess;

  constructor(public payload: AssignmentStartModelI) {}
}

@HideLoader(StartActionTypes.LoadStart)
export class LoadedStartFail implements Action {
  readonly type = StartActionTypes.LoadedStartFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanStartState implements Action {
  readonly type = StartActionTypes.CleanStartState;
}

export type StartActions =
  | LoadStart
  | LoadedStartSuccess
  | LoadedStartFail
  | CleanStartState;
