<div class="container">
  <div class="row pt-3 mb-4">
    <div class="col-12 text-center">
      <span class="h2" translate>common.register</span>
    </div>
  </div>

  <form
    class="mb-4"
    [formGroup]="userForm"
    [connectForm]="user$ | async"
    novalidate
    (ngSubmit)="submit()"
  >
    <div formGroupName="contact">
      <div class="form-group">
        <label for="register-name" translate>common.name</label>
        <input
          type="text"
          class="form-control "
          id="register-name"
          aria-describedby="nameHelp"
          formControlName="name"
          [ngClass]="{
            'is-invalid':
              nameControl.invalid && (nameControl.dirty || attemptedToSubmit)
          }"
        />
        <div class="invalid-feedback" *ngIf="nameControl.invalid" translate>
          register.error-messages.name
        </div>
      </div>

      <div class="form-group">
        <label for="register-email" translate>common.email</label>
        <input
          type="email"
          class="form-control"
          id="register-email"
          aria-describedby="emailHelp"
          formControlName="email"
          [ngClass]="{
            'is-invalid':
              emailControl.invalid && (emailControl.dirty || attemptedToSubmit)
          }"
        />
        <div class="invalid-feedback" *ngIf="emailControl.invalid" translate>
          register.error-messages.email
        </div>
      </div>

      <div class="form-group">
        <label for="register-mobilePhone" translate>common.mobilePhone</label>
        <input
          type="tel"
          class="form-control"
          id="register-mobilePhone"
          aria-describedby="mobilePhoneHelp"
          formControlName="mobilePhone"
        />
      </div>

      <div class="form-group">
        <label for="register-phone" translate>common.phone</label>
        <input
          type="tel"
          class="form-control"
          id="register-phone"
          aria-describedby="phoneHelp"
          formControlName="phone"
        />
      </div>
    </div>

    <div class="form-group">
      <label for="register-language" [translate]="'common.language'"></label>
      <select
        class="custom-select"
        id="register-language"
        formControlName="language"
      >
        <option selected disabled translate>common.select</option>
        <option
          *ngFor="let language of languages$ | async"
          [translate]="language"
          [value]="language"
        >
        </option>
      </select>
    </div>

    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        id="register-confirmProfile"
        formControlName="confirmedProfile"
        [ngClass]="{
          'is-invalid':
            confirmedProfileControl.invalid &&
            (confirmedProfileControl.dirty || attemptedToSubmit)
        }"
      />
      <label class="form-check-label" for="register-confirmProfile" translate
        >register.confirm</label
      >
      <div
        class="invalid-feedback"
        *ngIf="confirmedProfileControl.invalid"
        translate
      >
        register.error-messages.confirm
      </div>
    </div>

    <button type="submit" class="btn btn-block btn-outline-dark mt-3">
      <span [translate]="'common.buttons.submit'"></span>
    </button>
  </form>
</div>
