import { Action } from '@ngrx/store';
import {
  HideLoader,
  ShowLoader,
} from 'src/app/core/decorators/loader.decorator';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

export enum ClientAssignmentTypesActionTypes {
  LoadClientAssignmentTypes = '[ClientAssignmentTypes] Load Client Assignment Types',
  LoadedClientAssignmentTypesSuccess = '[ClientAssignmentTypes] Loaded Client Assignment Types',
  LoadedClientAssignmentTypesFail = '[ClientAssignmentTypes] Fail To Load Client Assignment Types',

  CleanClientAssignmentTypesState = '[ClientAssignmentTypes] Clean',
}

@ShowLoader()
export class LoadClientAssignmentTypes implements Action {
  readonly type = ClientAssignmentTypesActionTypes.LoadClientAssignmentTypes;

  constructor(public payload?: any) {}
}

@HideLoader(ClientAssignmentTypesActionTypes.LoadClientAssignmentTypes)
export class LoadedClientAssignmentTypesSuccess implements Action {
  readonly type =
    ClientAssignmentTypesActionTypes.LoadedClientAssignmentTypesSuccess;

  constructor(public payload: DictionaryI[]) {}
}

@HideLoader(ClientAssignmentTypesActionTypes.LoadClientAssignmentTypes)
export class LoadedClientAssignmentTypesFail implements Action {
  readonly type =
    ClientAssignmentTypesActionTypes.LoadedClientAssignmentTypesFail;

  constructor(public payload: ErrorResponseWithIdI) {}
}

export class CleanClientAssignmentTypesState implements Action {
  readonly type =
    ClientAssignmentTypesActionTypes.CleanClientAssignmentTypesState;

  constructor(public payload?: string) {}
}

export type ClientAssignmentTypesAction =
  | LoadClientAssignmentTypes
  | LoadedClientAssignmentTypesSuccess
  | LoadedClientAssignmentTypesFail
  | CleanClientAssignmentTypesState;
