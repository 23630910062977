import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';
import { AppPhaseChartInterface } from 'src/app/core/models/app-phase-chart.interface';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AssignmentAppApiService } from 'src/app/core/services/api/assignment-app-api/assignment-app-api.service';
import {
  AssignmentPhaseChartActionTypes,
  CleanAssignmentPhaseChartState,
  LoadedAssignmentPhaseChartsFail,
  LoadedAssignmentPhaseChartsSuccess,
} from 'src/app/core/store/actions/assignment-phase-chart.action';

@Injectable()
export class AssignmentPhaseChartEffects {
  constructor(
    private actions$: Actions,
    private assignmentAppService: AssignmentAppApiService
  ) {}

  
  chart$ = createEffect(() => this.actions$.pipe(
    ofType(AssignmentPhaseChartActionTypes.LoadAssignmentPhaseChart),
    switchMap((any) =>
      this.assignmentAppService.getPhaseChart().pipe(
        map(
          (count: AppPhaseChartInterface) =>
            new LoadedAssignmentPhaseChartsSuccess(count)
        ),
        catchError((error: ErrorResponseWithIdI) =>
          of(new LoadedAssignmentPhaseChartsFail(error))
        )
      )
    )
  ));

  
  handleLoadedPhaseChartsFail$ = createEffect(() => this.actions$.pipe(
    ofType(AssignmentPhaseChartActionTypes.LoadedAssignmentPhaseChartFail),
    map(() => new CleanAssignmentPhaseChartState())
  ));
}
