const mepsUrl = 'https://meps.net';

export const environment = {
  production: true,
  mepsUrl: mepsUrl,
  mepsAssignmentUrl: mepsUrl + '/assignments/external/',
  oldNumbersUrl: 'https://go.unityworks.se/apps/',
  url: 'https://pips-markets.appspot.com/',
  whiteListedDomains: ['pips-markets.appspot.com'],
  pullingIntervalInSeconds: 60,
  googleAnalyticsCode: 'UA-96172573-1',
  zendesk: {
    support: '629a4770-cd61-4986-8dee-b581378326d2',
  },
  auth0: {
    clientID: 'wELL2A6cXUWDHCUFAFALVXYFiAKFHSx7',
    domain: 'pipsmarketsapp.eu.auth0.com',
    responseType: 'token',
    audience: 'https://pips-markets-test.appspot.com',
  },
};
