import { Action } from '@ngrx/store';

export enum SpinnerActionTypes {
  ShowSpinner = '[Spinner] Show Spinner',
  HideSpinner = '[Spinner] Hide Spinner',
}

export class ShowSpinner implements Action {
  readonly type = SpinnerActionTypes.ShowSpinner;

  constructor(public payload?: any) {}
}

export class HideSpinner implements Action {
  readonly type = SpinnerActionTypes.HideSpinner;

  constructor(public payload?: any) {}
}

export type SpinnerActions = ShowSpinner | HideSpinner;
