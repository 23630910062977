import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PingService {
  private pingUrl = environment.url + 'api/v1/util/ping ';

  constructor(private httpClient: HttpClient) {}

  ping(): Observable<any> {
    return this.httpClient.get(this.pingUrl, { responseType: 'text' });
  }
}
