import { Action } from '@ngrx/store';

export enum ExternalRedirectActionTypes {
  GoToAssignmentAnalysis = '[External Redirect] Go to Assignment Analysis',
  GoToNumbersSearch = '[External Redirect] Go To Numbers Search',

  GoToMeps = '[External Redirect] Go to MEPS',
  GoToMepsConversation = '[External Redirect] Go to MEPS Conversation',

  GoToNewWindow = '[External Redirect] Go to New Window',
  GoToEmail = '[External Redirect] Go to Email',
  GoToPhoneNumber = '[External Redirect] Go to Phone Number',
}

export class GoToAssignmentAnalysis implements Action {
  readonly type = ExternalRedirectActionTypes.GoToAssignmentAnalysis;

  constructor(public payload: number) {}
}

export class GoToNumbersSearch implements Action {
  readonly type = ExternalRedirectActionTypes.GoToNumbersSearch;

  constructor(public payload: string) {}
}

export class GoToMeps implements Action {
  readonly type = ExternalRedirectActionTypes.GoToMeps;

  constructor(public payload: string) {}
}

export class GoToMepsConversation implements Action {
  readonly type = ExternalRedirectActionTypes.GoToMepsConversation;

  constructor(public payload: string) {}
}

export class GoToNewWindow implements Action {
  readonly type = ExternalRedirectActionTypes.GoToNewWindow;

  constructor(public payload: string) {}
}

export class GoToEmail implements Action {
  readonly type = ExternalRedirectActionTypes.GoToEmail;

  constructor(public payload: string) {}
}

export class GoToPhoneNumber implements Action {
  readonly type = ExternalRedirectActionTypes.GoToPhoneNumber;

  constructor(public payload: string) {}
}

export type ExternalRedirectActions =
  | GoToAssignmentAnalysis
  | GoToNumbersSearch
  | GoToMeps
  | GoToMepsConversation
  | GoToNewWindow
  | GoToEmail
  | GoToPhoneNumber;
