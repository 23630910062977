import { Pipe, PipeTransform } from '@angular/core';
import { AssignmentActionsEnum } from 'src/app/core/enums/assignment-actions.enum';

@Pipe({
  name: 'actionsLabel',
})
export class ActionsLabelPipe implements PipeTransform {
  actionEnum = AssignmentActionsEnum;
  commonButtonsKey = 'common.buttons.';

  transform(
    value: AssignmentActionsEnum[],
    returnDefault: boolean = true,
    ...args: any[]
  ): any {
    if (!!value && value.includes(this.actionEnum.INVITE)) {
      return this.commonButtonsKey + 'invite-contractor';
    }

    if (!!value && value.includes(this.actionEnum.DECISION)) {
      return this.commonButtonsKey + 'make-decision';
    }

    return returnDefault ? this.commonButtonsKey + 'analyze' : undefined;
  }
}
