import { createSelector } from '@ngrx/store';
import {
  AssignmentLittleDetailsI,
  AssignmentLittleI,
} from 'src/app/core/models/assignment/assignment-little.interface';

import { getAssignmentsStateSelector } from 'src/app/core/store/index';
import {
  adapter,
  getAllAssignmentsTotal,
  getAssignmentQuery,
  getAssignmentsError,
  getAssignmentsLoaded,
  getAssignmentsLoading,
  getAssignmentSorting,
  getAssignmentSortOptions,
  getAssignmentsPagination,
  getAssignmentsRequestedTime,
  getAssignmentsSorting,
  getAssignmentsTableType,
  getSelectedAssignments,
  isNewVersionIsAvailable,
} from 'src/app/core/store/reducers/assignments.reducer';
import { getRouterParamsSelector } from 'src/app/core/store/selectors/router.selectors';
import {
  mapOptionsToSelectsWithTranslationKey,
  mapOptionToSelectWithTranslationKey,
} from 'src/app/core/utils/translate-utils';
import { getAllEventTypesAsMapForCurrentLanguageSelector } from 'src/app/dictionaries/store/selectors/event-types.selectors';

export const getSelectedAssignmentSelector = createSelector(
  getAssignmentsStateSelector,
  getSelectedAssignments
);

export const isNewVersionIsAvailableSelector = createSelector(
  getAssignmentsStateSelector,
  isNewVersionIsAvailable
);

export const getAssignmentsLoadingSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsLoading
);

export const getAssignmentsLoadedSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsLoaded
);

export const getAssignmentsErrorSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsError
);

export const getAssignmentsTableTypeSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsTableType
);

export const getAssignmentsSortingSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsSorting
);

export const getAssignmentsPaginationSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsPagination
);

export const getAssignmentsRequestedTimeSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentsRequestedTime
);

export const getAllAssignmentsTotalSelector = createSelector(
  getAssignmentsStateSelector,
  getAllAssignmentsTotal
);

export const getAssignmentSortingSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentSorting
);

export const getAssignmentSortOptionsSelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentSortOptions
);

export const getAssignmentQuerySelector = createSelector(
  getAssignmentsStateSelector,
  getAssignmentQuery
);

export const getAssignmentsEntitiesSelector = adapter.getSelectors(
  getAssignmentsStateSelector
).selectEntities;
export const getAssignmentsTotalSelector = adapter.getSelectors(
  getAssignmentsStateSelector
).selectTotal;
export const getAssignmentsIdsSelector = adapter.getSelectors(
  getAssignmentsStateSelector
).selectIds;
export const getAllAssignmentsSelector = adapter.getSelectors(
  getAssignmentsStateSelector
).selectAll;

export const getAssignmentResourceForGivenUrlIdSelector = createSelector(
  getAssignmentsEntitiesSelector,
  getRouterParamsSelector,
  (entities, params) =>
    !!entities[params && params.assignmentId] &&
    entities[params && params.assignmentId]
);

export const getAssignmentPartsWithoutOwnerForGivenUrlIdSelector = createSelector(
  getAssignmentResourceForGivenUrlIdSelector,
  (assignmentResource) =>
    !!assignmentResource &&
    !!assignmentResource.calculation &&
    !!assignmentResource.calculation.assignmentParts &&
    assignmentResource.calculation.assignmentParts.filter(
      (part) => part.assignmentRoleId !== '1'
    )
);

export const getAssignmentDetailsForGivenUrlIdSelector = createSelector(
  getAssignmentResourceForGivenUrlIdSelector,
  (assignment: AssignmentLittleI) => !!assignment && assignment.details
);

export const getAssignmentActivitiesForGivenUrlIdSelector = createSelector(
  getAssignmentDetailsForGivenUrlIdSelector,
  (details: AssignmentLittleDetailsI) => !!details && details.activitesDone
);

export const getAssignmentResourceByIdSelector = createSelector(
  getAssignmentsEntitiesSelector,
  (entities, assignmentId) => (!!assignmentId ? entities[assignmentId] : [])
);

export const getTableSizeLimit = createSelector(
  getAssignmentsPaginationSelector,
  (pagination) => pagination.limit
);

export const getTablePaginationPage = createSelector(
  getAssignmentsPaginationSelector,
  (pagination) => pagination.page
);

export const getTranslatedActivitiesSelector = createSelector(
  getAllEventTypesAsMapForCurrentLanguageSelector,
  getAssignmentActivitiesForGivenUrlIdSelector,
  (translations, activatesDone) => {
    activatesDone = activatesDone || [];
    return activatesDone
      .map((activity) => translations.get(activity))
      .filter((translation) => !!translation)
      .map((translation) => translation.toString());
  }
);

export const getAssignmentSortTranslatedOptionsSelector = createSelector(
  getAssignmentSortOptionsSelector,
  mapOptionsToSelectsWithTranslationKey
);
export const getCurrentAssignmentSortTranslatedOptionsSelector = createSelector(
  getAssignmentSortingSelector,
  mapOptionToSelectWithTranslationKey
);
