import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { getAssignmentVolumeChartStateSelector } from 'src/app/core/store';
import { LoadAssignmentVolumeChart } from 'src/app/core/store/actions/assignment-volume-chart.action';
import { AgreementsReducerState } from 'src/app/core/store/reducers/agreements.reducer';
import { AssignmentVolumeChartReducerState } from 'src/app/core/store/reducers/assignment-volume-chart.reducer';

@Injectable()
export class AssignmentVolumeChartGuard implements CanActivate {
  constructor(private store: Store<AgreementsReducerState>) {}

  canActivate(): Observable<boolean> {
    return this.checkIfCanActivate();
  }

  checkIfCanActivate(): Observable<boolean> {
    return this.waitForChartToToLoad().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  waitForChartToToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(getAssignmentVolumeChartStateSelector),
      tap((chartState: AssignmentVolumeChartReducerState) => {
        if (!chartState.loaded && !chartState.loading && !chartState.error) {
          this.store.dispatch(new LoadAssignmentVolumeChart());
        }
      }),
      filter(
        (chartState: AssignmentVolumeChartReducerState) =>
          chartState.loaded || !!chartState.error
      ),
      map((chartState: AssignmentVolumeChartReducerState) => {
        if (!!chartState.error) {
          throw new Error(chartState.error.error.message);
        } else {
          return chartState.loaded;
        }
      }),
      take(1)
    );
  }
}
