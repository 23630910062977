import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  ErrorReasonTypesAction,
  ErrorReasonTypesActionTypes,
} from 'src/app/dictionaries/store/actions/error-reason-types.action';

const selectId = (dictionary: DictionaryI) => dictionary.name;
const adapter: EntityAdapter<DictionaryI> = createEntityAdapter<DictionaryI>({
  selectId,
});

export interface ErrorReasonTypesReducerState extends EntityState<DictionaryI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialErrorReasonTypesReducerState: ErrorReasonTypesReducerState = adapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function errorReasonTypesReducer(
  state: ErrorReasonTypesReducerState = initialErrorReasonTypesReducerState,
  action: ErrorReasonTypesAction
): ErrorReasonTypesReducerState {
  switch (action.type) {
    case ErrorReasonTypesActionTypes.LoadErrorReasonTypes: {
      return {
        ...state,
        loading: true,
      };
    }

    case ErrorReasonTypesActionTypes.LoadedErrorReasonTypesSuccess: {
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case ErrorReasonTypesActionTypes.LoadedErrorReasonTypesFail: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case ErrorReasonTypesActionTypes.CleanErrorReasonTypesState: {
      return {
        ...initialErrorReasonTypesReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getErrorReasonTypesIds = adapter.getSelectors().selectIds;
export const getErrorReasonTypesEntities = adapter.getSelectors()
  .selectEntities;
export const getAllErrorReasonTypes = adapter.getSelectors().selectAll;
export const getErrorReasonTypesTotal = adapter.getSelectors().selectTotal;

export const getErrorReasonTypesLoading = (
  state: ErrorReasonTypesReducerState
) => state.loading;
export const getErrorReasonTypesLoaded = (
  state: ErrorReasonTypesReducerState
) => state.loaded;
export const getErrorReasonTypesError = (state: ErrorReasonTypesReducerState) =>
  state.error;
