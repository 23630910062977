import { createSelector } from '@ngrx/store';
import { getSupportStateSelector } from 'src/app/core/store/index';
import {
  isLauncherVisible,
  isZenDeskLoaded,
} from 'src/app/core/store/reducers/support.reducer';

export const isZenDeskLoadedSelector = createSelector(
  getSupportStateSelector,
  isZenDeskLoaded
);

export const isLauncherVisibleSelector = createSelector(
  getSupportStateSelector,
  isLauncherVisible
);
