import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage.service';
import {
  ChangePWA,
  PWAActionTypes,
} from 'src/app/core/store/actions/pwa.action';

@Injectable()
export class PWAEffects {
  constructor(
    private localStorageService: LocalStorageService,
    private actions$: Actions
  ) {}

   getPWA$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(PWAActionTypes.GetPWA),
    map(() => this.localStorageService.getPWA()),
    map((pwa) => {
      return new ChangePWA(pwa);
    })
  ));

   changePWA$ = createEffect(() => this.actions$.pipe(
    ofType(PWAActionTypes.ChangePWA),
    tap((action: ChangePWA) =>
      this.localStorageService.updatePWA(action.payload)
    )
  ), { dispatch: false });
}
