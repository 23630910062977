<div class="mb-5" *ngIf="unreadMessagesExists()">
  <div *ngFor="let alert of alerts">
    <alert
      *ngIf="alert.status === alertMessageStatus.UNREADED"
      [type]="alert?.type"
      [dismissible]="true"
      (onClosed)="onDismissed(alert)"
    >
      <span [translate]="alert.title"></span>
      <strong
        class="pointer"
        (click)="goToDetails(alert.assignmentId, alert.assignmentStatus)"
        >{{ alert.assignmentName }}</strong
      >
      <br />
      <span [translate]="alert.message"></span>

      <strong>
        {{
          alert.errorReason
            ? (alert.errorReason | reasonTranslate | async)
            : ('assignmentStatus.' + alert.assignmentStatus | translate)
        }}
      </strong>
    </alert>
  </div>
</div>
