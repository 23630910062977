import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { AlertMessagesTypes } from 'src/app/core/enums/alert-messages-types.enum';
import { AlertMessage } from 'src/app/core/models/alert-message.interface';
import { ClearAllMessages } from 'src/app/core/store/actions/alert-messages.action';
import { AlertMessagesReducerState } from 'src/app/core/store/reducers/alert-messages.reducer';
import {
  getAlertMessagesByFilterSelector,
  getAlertMessagesFilterSelector,
} from 'src/app/core/store/selectors/alert-messages.selectors';
import { getRouterPreviousUrlSelector } from 'src/app/core/store/selectors/router.selectors';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';

@Component({
  selector: 'app-messages-panel',
  templateUrl: './messages-panel.component.html',
  styleUrls: ['./messages-panel.component.scss'],
})
export class MessagesPanelComponent extends StoreDispatcher<any>
  implements OnInit {
  assignmentDeletedMessageKey = 'alert.messageDeleted';

  alertMessages$: Observable<AlertMessage[]>;
  alertMessagesFilter$: Observable<AlertMessagesTypes>;

  backUrl$: Observable<string>;
  assignmentDeletedMessage$: Observable<string>;

  constructor(
    store: Store<AlertMessagesReducerState>,
    private translateService: TranslateService
  ) {
    super(store);
  }

  ngOnInit() {
    this.alertMessages$ = this.store.pipe(
      select(getAlertMessagesByFilterSelector)
    );

    this.alertMessagesFilter$ = this.store.pipe(
      select(getAlertMessagesFilterSelector)
    );

    this.backUrl$ = this.store.pipe(select(getRouterPreviousUrlSelector));

    this.assignmentDeletedMessage$ = this.translateService.stream(
      this.assignmentDeletedMessageKey
    );
  }

  onClearAll() {
    this.dispatchAction(new ClearAllMessages());
  }
}
