// TODO: Add integration with our backend to dynamically load
// common types
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';

import {
  SpaceTypesAction,
  SpaceTypesActionTypes,
} from 'src/app/dictionaries/store/actions/space-types.action';

const selectId = (dictionary: DictionaryI) => dictionary.name;
const adapter: EntityAdapter<DictionaryI> = createEntityAdapter<DictionaryI>({
  selectId,
});

export interface SpaceTypesReducerState extends EntityState<DictionaryI> {
  loading: boolean;
  loaded: boolean;
  error: ErrorResponseWithIdI;
}

export const initialSpaceTypesReducerState: SpaceTypesReducerState = adapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null,
  }
);

export function spaceTypesReducer(
  state: SpaceTypesReducerState = initialSpaceTypesReducerState,
  action: SpaceTypesAction
): SpaceTypesReducerState {
  switch (action.type) {
    case SpaceTypesActionTypes.LoadSpaceTypes: {
      return {
        ...state,
        loading: true,
      };
    }

    case SpaceTypesActionTypes.LoadedSpaceTypesSuccess: {
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case SpaceTypesActionTypes.LoadedSpaceTypesFail: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case SpaceTypesActionTypes.CleanSpaceTypesState: {
      return {
        ...initialSpaceTypesReducerState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getSpaceTypesIds = adapter.getSelectors().selectIds;
export const getSpaceTypesEntities = adapter.getSelectors().selectEntities;
export const getAllSpaceTypes = adapter.getSelectors().selectAll;
export const getSpaceTypesTotal = adapter.getSelectors().selectTotal;

export const getSpaceTypesLoading = (state: SpaceTypesReducerState) =>
  state.loading;
export const getSpaceTypesLoaded = (state: SpaceTypesReducerState) =>
  state.loaded;
export const getSpaceTypesError = (state: SpaceTypesReducerState) =>
  state.error;
