import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponseWithIdI } from 'src/app/core/models/error-response-with-id.interface';
import { AssetService } from 'src/app/core/services/asset/asset.service';
import { DictionaryI } from 'src/app/dictionaries/models/dictionary.interface';
import {
  CleanErrorExtraTypesState,
  ErrorExtraTypesActionTypes,
  LoadedErrorExtraTypesFail,
  LoadedErrorExtraTypesSuccess,
} from 'src/app/dictionaries/store/actions/error-extra-types.action';

@Injectable()
export class ErrorExtraTypesEffects {
  
  public loadErrorExtraTypes$ = createEffect(() => this.actions$.pipe(
    ofType(ErrorExtraTypesActionTypes.LoadErrorExtraTypes),
    switchMap(() =>
      this.assetService.getErrorExtraTypes().pipe(
        map(
          (ErrorExtraTypes: DictionaryI[]) =>
            new LoadedErrorExtraTypesSuccess(ErrorExtraTypes)
        ),
        catchError((error: ErrorResponseWithIdI) =>
          of(new LoadedErrorExtraTypesFail(error))
        )
      )
    )
  ));

  
  public handleLoadedErrorExtraTypesFail$ = createEffect(() => this.actions$.pipe(
    ofType(ErrorExtraTypesActionTypes.LoadedErrorExtraTypesFail),
    map(() => new CleanErrorExtraTypesState())
  ));

  constructor(private actions$: Actions, private assetService: AssetService) {}
}
