import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ActionsDropdownComponent } from 'src/app/shared/components/actions-dropdown/actions-dropdown.component';

import { AlertMessagesComponent } from 'src/app/shared/components/alert-messages/alert-messages.component';
import { DropdownSearchableComponent } from 'src/app/shared/components/dropdown-searchable/dropdown-searchable.component';
import { InfoItemComponent } from 'src/app/shared/components/info-item/info-item.component';
import { LanguageSelectComponent } from 'src/app/shared/components/language-select/language-select.component';
import { MenuListComponent } from 'src/app/shared/components/menu-list/menu-list.component';
import { PageHeaderComponent } from 'src/app/shared/components/page-header/page-header.component';
import { SectionHeaderComponent } from 'src/app/shared/components/section-header/section-header.component';
import { ClickOutsideDirective } from 'src/app/shared/directives/click-outside.directive';
import { ConnectFormDirective } from 'src/app/shared/directives/connect-form.directive';
import { DisableControlDirective } from 'src/app/shared/directives/disable-control.directive';
import { ExtraTranslatePipe } from 'src/app/shared/pipes/extra-translate.pipe';
import { ReasonTranslatePipe } from 'src/app/shared/pipes/reason-translate.pipe';
import { UwCurrencyPipe } from 'src/app/shared/pipes/uw-currency.pipe';
import { ActionsDropdownMultipleAssignmentsComponent } from './components/actions-dropdown-multiple-assignments/actions-dropdown-multiple-assignments.component';
import { ActionsDropdownSingleAssignmentComponent } from './components/actions-dropdown-single-assignment/actions-dropdown-single-assignment.component';
import { CardComponent } from './components/card/card.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ActionsLabelPipe } from './pipes/action-button-label.pipe';
import { AgoPipe } from './pipes/ago.pipe';
import { BaseActionComponent } from './components/base-action/base-action.component';

const EXPORT_COMPONENTS = [
  ActionsDropdownComponent,
  AlertMessagesComponent,
  LanguageSelectComponent,
  CardComponent,
  SearchBarComponent,
  DropdownSearchableComponent,
  MenuListComponent,
  PageHeaderComponent,
  InfoItemComponent,
  SectionHeaderComponent,
];

const COMPONENTS = [
  ...EXPORT_COMPONENTS,
  ActionsDropdownSingleAssignmentComponent,
  ActionsDropdownMultipleAssignmentsComponent,
  BaseActionComponent,
];

const PIPES = [
  UwCurrencyPipe,
  ExtraTranslatePipe,
  ReasonTranslatePipe,
  ActionsLabelPipe,
  AgoPipe,
];

const DIRECTIVES = [
  ConnectFormDirective,
  DisableControlDirective,
  ClickOutsideDirective,
];

const EXPORT_MODULES = [
  NgSelectModule,
  AlertModule,
  PaginationModule,
  NgxDatatableModule,
  TranslateModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  CollapseModule,
  BsDropdownModule,
  BsDatepickerModule,
  TimepickerModule,
  AccordionModule,
  TabsModule,
  TooltipModule,
];

const MODULES = [...EXPORT_MODULES, CollapseModule, BsDropdownModule];

@NgModule({
  declarations: [...DIRECTIVES, ...COMPONENTS, ...PIPES],
  imports: [...MODULES],
  exports: [...EXPORT_COMPONENTS, ...DIRECTIVES, ...PIPES, ...EXPORT_MODULES],
})
export class SharedModule {}
