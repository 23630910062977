import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectDetailsI } from 'src/app/core/models/projects/project-details.interface';
import { ProjectRequestI } from 'src/app/core/models/projects/project-request.interface';
import { ProjectI } from 'src/app/core/models/projects/project.interface';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProjectsService {
  private baseProjectUrl = environment.url + 'api/v1/project/';

  constructor(private http: HttpClient) {}

  createProject(project: ProjectRequestI) {
    return this.http.post(this.baseProjectUrl, project);
  }

  updateProject(project: ProjectRequestI) {
    return this.http.put(this.baseProjectUrl, project);
  }

  getProjectList(): Observable<ProjectI[]> {
    return this.http.get<ProjectI[]>(this.baseProjectUrl);
  }

  getProjectDetails(id: number): Observable<ProjectDetailsI> {
    return this.http.get<ProjectDetailsI>(this.baseProjectUrl + id);
  }
}
