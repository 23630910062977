<div [ngClass]="{ 'ng-placeholder-sm': small }">
  <ng-select
    [items]="options"
    [bindLabel]="'value'"
    [searchable]="searchable"
    [disabled]="disabled"
    [clearable]="clearable"
    [hideSelected]="true"
    [placeholder]="placeHolder | translate"
    [multiple]="multiple"
    [(ngModel)]="selectedOption"
    (change)="onChange($event)"
    (clear)="onClear()"
  >
    <ng-template ng-label-tmp ng-option-tmp let-item="item">
      {{ item.value | translate }}
    </ng-template>
  </ng-select>
</div>
