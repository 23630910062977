import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseBarComponent } from 'src/app/core/containers/base-bar/base-bar.component';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
})
export class BottomBarComponent extends BaseBarComponent {
  isExpanded = false;

  constructor(store: Store) {
    super(store, 'bottom bar');
  }
}
