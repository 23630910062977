import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthorizationService } from 'src/app/auth/services/authorization/authorization.service';

import { InvalidToken } from 'src/app/auth/store/actions/auth.action';
import { UserState } from 'src/app/auth/store/reducers/user.reducer';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthorizationService,
    private store: Store<UserState>
  ) {}

  canActivate() {
    return this.checkIfTokenIsValid();
  }

  canActivateChild() {
    return this.checkIfTokenIsValid();
  }

  private checkIfTokenIsValid(): boolean {
    if (this.authService.isTokenValid()) {
      return true;
    } else {
      this.store.dispatch(new InvalidToken());
      return false;
    }
  }
}
