import { Injectable } from '@angular/core';
import { RouterState } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { zip } from 'rxjs';
import {
  filter,
  flatMap,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AuthorizationService } from 'src/app/auth/services/authorization/authorization.service';
import {
  AuthActions,
  AuthActionTypes,
  SetOriginUrl,
} from 'src/app/auth/store/actions/auth.action';
import { UserActionTypes } from 'src/app/auth/store/actions/user.action';
import { getOriginUrlSelector } from 'src/app/auth/store/selectors/info.selectors';
import { CoreUrlEnums } from 'src/app/core/enums/url-paths.enum';
import { RouterGo } from 'src/app/core/store/actions/router.action';
import { getRouterUrlSelector } from 'src/app/core/store/selectors/router.selectors';

@Injectable()
export class AuthorizationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RouterState>,
    private authorizationService: AuthorizationService
  ) {}

  public showLoginPanel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.ShowLoginPanel),
        tap((action: AuthActions) =>
          this.authorizationService.showLoginPanel(action.payload)
        )
      ),
    { dispatch: false }
  );

  public hideLoginPanel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.HideLoginPanel),
        tap(() => this.authorizationService.hideLoginPanel())
      ),
    { dispatch: false }
  );

  public logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.Logout),
      tap(() => this.authorizationService.logout()),
      map(() => {
        return new RouterGo({
          path: [CoreUrlEnums.LOGIN],
        });
      })
    )
  );

  public invalidToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.InvalidToken),
      tap(() => this.authorizationService.logout()),
      withLatestFrom(this.store.pipe(select(getRouterUrlSelector))),
      switchMap(([action, url]) => {
        return [
          new SetOriginUrl(url),
          new RouterGo({
            path: [CoreUrlEnums.LOGIN],
          }),
        ];
      })
    )
  );

  public unauthorized$ = createEffect(() =>
    this.actions$.pipe(
      filter(
        (action: any) =>
          !!action.payload && !!action.payload.error && !!action.payload.id
      ),
      flatMap((action: any) =>
        zip(
          this.actions$.pipe(
            filter(
              (t: any) =>
                !!t.payload &&
                !!t.payload.error &&
                t.payload.id === action.payload.id
            )
          )
        )
      ),
      tap(() => this.authorizationService.logout()),
      map(() => {
        return new RouterGo({
          path: [CoreUrlEnums.LOGIN],
        });
      })
    )
  );

  public blocked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.Blocked),
      tap(() => this.authorizationService.logout()),
      map((_) => {
        return new RouterGo({
          path: [CoreUrlEnums.BLOCKED],
        });
      })
    )
  );

  public refreshToken = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.RefreshToken),
        tap(() => this.authorizationService.renewToken())
      ),
    { dispatch: false }
  );

  public handleUserConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.RegisterUserSuccess, AuthActionTypes.LoggedIn),
      withLatestFrom(this.store.pipe(select(getOriginUrlSelector))),
      switchMap(([action, url]) => [
        new SetOriginUrl(''),
        new RouterGo({
          path: [url || CoreUrlEnums.ASSIGNMENTS],
        }),
      ])
    )
  );
}
