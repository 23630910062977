import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageEnum } from 'src/app/core/enums/language.enum';

import {
  ChangeLanguage,
  LanguageStateAction,
} from 'src/app/core/store/actions/language.action';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent implements OnInit {
  @Input()
  languages: LanguageEnum[];

  @Input()
  selectedLanguage: LanguageEnum;

  @Output()
  select: EventEmitter<LanguageStateAction> = new EventEmitter<
    LanguageStateAction
  >();

  constructor() {}

  ngOnInit() {}

  onSelect(language: LanguageEnum) {
    this.select.emit(new ChangeLanguage(language));
  }
}
