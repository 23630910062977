import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserRegisterI } from 'src/app/core/models/user/user-register.interface';
import { UserPublicI } from 'src/app/core/models/user/user-resource.interface';
import { RegisterUser } from 'src/app/auth/store/actions/user.action';
import { UserState } from 'src/app/auth/store/reducers/user.reducer';
import { getUserPublicSelector } from 'src/app/auth/store/selectors/user.selectors';
import { getAvailableLanguagesSelector } from 'src/app/core/store/selectors/language.selectors';
import { StoreDispatcher } from 'src/app/shared/base-classes/store-dispatcher/store-dispatcher';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends StoreDispatcher<any> implements OnInit {
  userForm: FormGroup;
  attemptedToSubmit: boolean;

  userId: FormControl;
  languageControl: FormControl;
  nameControl: FormControl;
  emailControl: FormControl;
  mobilePhoneControl: FormControl;
  phoneControl: FormControl;
  confirmedProfileControl: FormControl;

  languages$: Observable<string[]>;
  user$: Observable<UserPublicI>;

  constructor(private fb: FormBuilder, store: Store<UserState>) {
    super(store);
  }

  ngOnInit() {
    this.languages$ = this.store.pipe(select(getAvailableLanguagesSelector));
    this.user$ = this.store.pipe(select(getUserPublicSelector));

    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.userId = this.fb.control('', Validators.required);
    this.languageControl = this.fb.control('', Validators.required);
    this.nameControl = this.fb.control('', Validators.required);
    this.emailControl = this.fb.control('', Validators.email);
    this.mobilePhoneControl = this.fb.control('');
    this.phoneControl = this.fb.control('');
    this.confirmedProfileControl = this.fb.control('', Validators.requiredTrue);
  }

  createForm() {
    this.userForm = this.fb.group({
      userId: this.userId,
      language: this.languageControl,
      confirmedProfile: this.confirmedProfileControl,
      contact: this.fb.group({
        name: this.nameControl,
        email: this.emailControl,
        mobilePhone: this.mobilePhoneControl,
        phone: this.phoneControl,
      }),
    });
  }

  submit(): void {
    this.attemptedToSubmit = true;

    if (this.userForm.valid) {
      const formValue: UserRegisterI = this.userForm.value;

      this.dispatchAction(new RegisterUser(formValue));
    }
  }
}
