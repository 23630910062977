import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SupportService } from 'src/app/core/services/zendesk/support/support.service';
import {
  LoadZenDeskSuccess,
  SupportActionTypes,
} from 'src/app/core/store/actions/support.action';

@Injectable()
export class SupportEffects {
  constructor(
    private actions$: Actions,
    private supportService: SupportService
  ) {}

  
  public loadZenDesk$ = createEffect(() => this.actions$.pipe(
    ofType(SupportActionTypes.LoadZenDesk),
    switchMap(() =>
      this.supportService.load().pipe(map(() => new LoadZenDeskSuccess()))
    )
  ));

  
  public hideLauncher$ = createEffect(() => this.actions$.pipe(
    ofType(SupportActionTypes.HideLauncher),
    map(() => this.supportService.hideLauncher())
  ), { dispatch: false });

  
  public showLauncher$ = createEffect(() => this.actions$.pipe(
    ofType(SupportActionTypes.ShowLauncher),
    map(() => this.supportService.showLauncher())
  ), { dispatch: false });

  
  public openSupportPanel$ = createEffect(() => this.actions$.pipe(
    ofType(SupportActionTypes.OpenSupportPanel),
    map(() => this.supportService.openSupportPanel())
  ), { dispatch: false });
}
